import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const FinanceIcon = ({ color = "#828282", size = 22, href, onClick }: Props) => {
  const Ratio_SVG = 23 / 22;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 23 22"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule={"evenodd"}
        clipRule={color}
        d="M11.1529 2.22941C6.3098 2.22941 2.3837 6.15551 2.3837 10.9986C2.3837 15.8417 6.3098 19.7678 11.1529 19.7678C15.996 19.7678 19.9221 15.8417 19.9221 10.9986C19.9221 6.15551 15.996 2.22941 11.1529 2.22941ZM0.191406 10.9986C0.191406 4.94473 5.09903 0.0371094 11.1529 0.0371094C17.2068 0.0371094 22.1144 4.94473 22.1144 10.9986C22.1144 17.0525 17.2068 21.9601 11.1529 21.9601C5.09903 21.9601 0.191406 17.0525 0.191406 10.9986ZM11.1529 4.4217C11.7583 4.4217 12.249 4.91247 12.249 5.51785V5.61909C13.2799 5.81288 14.2128 6.28117 14.8293 6.99176C15.2261 7.44901 15.177 8.14132 14.7198 8.53807C14.2625 8.93483 13.5702 8.88579 13.1735 8.42854C12.9954 8.22325 12.6776 8.01456 12.249 7.8754L12.2491 10.0033C12.9304 10.1312 13.5606 10.3783 14.0862 10.7287C14.8773 11.256 15.5375 12.105 15.5375 13.1909C15.5375 14.2768 14.8773 15.1258 14.0862 15.6531C13.5606 16.0035 12.9304 16.2506 12.249 16.3785L12.249 16.4794C12.249 17.0848 11.7583 17.5755 11.1529 17.5755C10.5475 17.5755 10.0567 17.0847 10.0567 16.4793L10.0567 16.3781C9.02588 16.1843 8.09305 15.716 7.47648 15.0054C7.07973 14.5482 7.12877 13.8559 7.58602 13.4591C8.04326 13.0624 8.73557 13.1114 9.13233 13.5687C9.31046 13.7739 9.62817 13.9826 10.0568 14.1218L10.0568 11.9939C9.37539 11.866 8.74518 11.6189 8.21958 11.2685C7.42851 10.7412 6.7683 9.89222 6.7683 8.8063C6.7683 7.72038 7.42851 6.87144 8.21958 6.34406C8.74518 5.99366 9.37538 5.74665 10.0567 5.61869V5.51785C10.0567 4.91247 10.5475 4.4217 11.1529 4.4217ZM10.0567 7.87568C9.81493 7.95393 9.60533 8.05504 9.43565 8.16816C9.03653 8.43424 8.9606 8.68145 8.9606 8.8063C8.9606 8.93115 9.03653 9.17836 9.43565 9.44444C9.60533 9.55756 9.81493 9.65867 10.0568 9.73692L10.0567 7.87568ZM12.2491 12.2603L12.2491 14.1215C12.4909 14.0433 12.7005 13.9422 12.8702 13.829C13.2693 13.563 13.3452 13.3157 13.3452 13.1909C13.3452 13.066 13.2693 12.8188 12.8702 12.5528C12.7005 12.4396 12.4909 12.3385 12.2491 12.2603Z"
        fill={color}
      />
    </svg>
  );
};

export default FinanceIcon;
