export function generateSlug(input: string) {
  // Convertir le texte en minuscules
  let slug = input.toLowerCase();

  // Remplacer les caractères spéciaux et les accents
  slug = slug
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9]/g, "-");

  // Remplacer les espaces par des tirets
  slug = slug.replace(/\s+/g, "-");

  // Supprimer les tirets consécutifs
  slug = slug.replace(/-+/g, "-");

  // Supprimer les tirets en début et fin de chaîne
  slug = slug.replace(/^-|-$/g, "");

  return slug;
}
