import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllMember(id: string) {
  return getUrl("/member/all?id=" + id);
}

export function newMember(data: any = {}) {
  return postUrl("/member/new", data);
}

export function updateMember(id: string = "", data: any = {}) {
  return postUrl("/member/update?id=" + id, data);
}

export function updateMemberPassword(id: string = "", data: any = {}) {
  return postUrl("/member/update/password?id=" + id, data);
}

export function removeMember(id: string = "") {
  return deleteUrl("/member/remove?id=" + id);
}
