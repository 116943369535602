import React from "react";
import { NavLink } from "react-router-dom";

const SidebarItem = ({ item }) => {
  return (
    <NavLink
      to={item.path ?? ""}
      end={item.path === ""}
      className={({ isActive }) => {
        const def =
          "flex w-full gap-3 transition-all py-[15px] px-4 pl-10 cursor-pointer select-none items-center ";
        if (isActive) {
          return def + "text-white bg-primary duration-0";
        }
        return def + "hover:bg-slate-200/70 duration-100";
      }}
    >
      {({ isActive }) => (
        <>
          {!!item.icon && (
            <div className="flex">
              {item.icon({
                color: isActive ? "white" : "black",
              })}
            </div>
          )}
          <span
            className={`flex text-[17px] font-medium ${
              isActive ? " text-white" : ""
            }`}
          >
            {item.name}
          </span>
        </>
      )}
    </NavLink>
  );
};

export default SidebarItem;
