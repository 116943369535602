import React from "react";
import ModalCustom from "../..";
import TextInput from "../../../inputs/input";
import { useStationStore } from "../../../../configs/stores/station";
import { getPropValue } from "../../../../functions/getPropValue";
import { generateSlug } from "../../../../functions/generateSlug";
import { StationModel } from "../../../../models/models";

type Props = {
  handleClose: () => void;
  open: boolean;
  station: StationModel;
  closeOnFinsh?: boolean;
};

const StationUpdate = ({
  open = false,
  station,
  handleClose = () => {},
  closeOnFinsh = true,
}: Props) => {
  const [closable] = React.useState(true);
  const { updateStation } = useStationStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    station_name: station.station_name,
  });
  const [errorState, setErrorState] = React.useState({
    station_name: { state: false, msg: "" },
    generalMsg: "",
  });
  function checkFormData() {
    let errorOccured = false;
    // station_name
    if (
      formData.station_name.length === 0 ||
      !/[a-zA-Z0-9]+/.test(formData.station_name)
    ) {
      errorOccured = true;
      let msg = "Renseigner correctement le nom de la gare !";
      setErrorState((e) => ({ ...e, station_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, station_name: { state: false, msg: "" } }));
    }
    return errorOccured;
  }
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      return;
    }
    setIsLoading(true);
    updateStation(
      station.station_id,
      station.station_company?.company_id ?? station.station_company,
      {
        station_name: formData.station_name,
        station_slug: generateSlug(formData.station_name),
      }
    ).then((res) => {
      setIsLoading(false);
      if (res.success) {
        handleClose();
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col max-h-[80vh] items-center pb-5 px-5 gap-3 rounded-md w-[500px] ">
        <span className="flex text-hover font-semibold text-[20px]">
          Ajouter une nouvelle gare
        </span>
        <div className="flex gap-4 gap-x-1 flex-col w-full px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
          <div className="flex flex-col w-full">
            <TextInput
              label={"Nom de la gare"}
              onChange={onValueChange("station_name")}
              errorState={errorState.station_name}
              defaultValue={station.station_name}
            />
          </div>
          <span className="flex text-[15px]">
            Exemple: "Gare de (la circonscription correspondante)"
          </span>
          <span className="flex text-[15px]">
            Veuillez noter qu’il est impératif de renseigner correctement le nom
            de la gare lors de l’enregistrement des données. De plus, après
            avoir effectué l’enregistrement, assurez-vous de géolocaliser la
            gare afin qu’elle soit visible dans les résultats. Cette étape est
            cruciale pour garantir l’exactitude et la visibilité de vos
            informations.
          </span>
          <button
            onClick={!isLoading ? submitForm : undefined}
            className="flex px-12 py-4 text-white justify-center bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
          >
            {!isLoading ? (
              <span className="flex text-[18px]">Modifier</span>
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};

export default StationUpdate;
