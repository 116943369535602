import React from "react";

type Props = {
  error: { status: boolean; msg: string };
  onTextChange?: (value: string) => void;
  placeholder?: string;
};

const SignupPasswordInput = ({
  error,
  placeholder = "Mot de passe",
  onTextChange = (str) => {},
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = React.useState(false);
  function setVisibility(has: boolean) {
    setIsVisible(has);
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row flex-1 relative items-center transition-all duration-150">
        <div className="flex transition-all duration-150 justify-center absolute right-2 p-[6px] hover:bg-black/15 active:bg-black/10 rounded-full">
          {isVisible && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill={error.status ? "rgb(239 68 68 )" : "rgb(0 0 0 / 0.55)"}
              className="w-[22px] h-[22px]"
              onClick={() => setVisibility(false)}
            >
              <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
              <path
                fillRule="evenodd"
                d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {!isVisible && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill={error.status ? "rgb(239 68 68 )" : "rgb(0 0 0 / 0.55)"}
              className="w-[22px] h-[22px]"
              onClick={() => setVisibility(true)}
            >
              <path
                fillRule="evenodd"
                d="M3.28 2.22a.75.75 0 0 0-1.06 1.06l14.5 14.5a.75.75 0 1 0 1.06-1.06l-1.745-1.745a10.029 10.029 0 0 0 3.3-4.38 1.651 1.651 0 0 0 0-1.185A10.004 10.004 0 0 0 9.999 3a9.956 9.956 0 0 0-4.744 1.194L3.28 2.22ZM7.752 6.69l1.092 1.092a2.5 2.5 0 0 1 3.374 3.373l1.091 1.092a4 4 0 0 0-5.557-5.557Z"
                clipRule="evenodd"
              />
              <path d="m10.748 13.93 2.523 2.523a9.987 9.987 0 0 1-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 0 1 0-1.186A10.007 10.007 0 0 1 2.839 6.02L6.07 9.252a4 4 0 0 0 4.678 4.678Z" />
            </svg>
          )}
        </div>
        <input
          ref={inputRef}
          type={isVisible ? "text" : "password"}
          name="passinput"
          maxLength={30}
          placeholder={placeholder}
          onChange={(e) => onTextChange(e.target.value ?? "")}
          className={`flex flex-1 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${
            error.status
              ? "caret-red-500 border-red-500 outline-none placeholder:text-red-500"
              : "border-slate-200 outline-none focus:border-primary"
          } font-medium text-[18px] py-3 bg-slate-50`}
        />
      </div>
      {error.msg.length !== 0 && (
        <span className="flex select-none pl-[6px] text-[14px] text-red-500">
          {error.msg}
        </span>
      )}
    </div>
  );
};

export default SignupPasswordInput;
