import React from "react";
import ModalCustom from "../..";
import { StationModel, TravelModel } from "../../../../models/models";
import NumberInput from "../../../inputs/number";
import { Switch } from "@mui/material";
import { isNumber, primaryColor } from "../../../../constants";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import clsx from "clsx";
import SelectDropDown from "../travel-new/select";
import {
  TravelFrequencyLabel,
  TravelVehiculeLabel,
  TravelZoneLabel,
  useTravelStore,
} from "../../../../configs/stores/travel";
import EveryDay from "./frequencyComponents/everyDay";
import PerWeek from "./frequencyComponents/perWeek";
import PerMonth from "./frequencyComponents/perMonth";
import { getPropValue } from "../../../../functions/getPropValue";
import moment from "moment";
import { generateId } from "../../../../functions/generateId";
import { useAuthStore } from "../../../../configs/stores/auth";

type Props = {
  handleClose: () => void;
  open: boolean;
  closeOnFinsh?: boolean;
  travel: TravelModel;
  travelTrip: {
    travel_from: StationModel;
    travel_to: StationModel;
    travel_vehicule: string;
    travel_cost: string;
    travel_duration: string;
    travel_zone: string;
    travel_active: boolean;
    travel_from_index: number;
    travel_to_index: number;
    travel_frequency: {
      type: string;
      data: Array<{ id: string; number: number; day: string; hour: string }>;
    };
  };
};

const TravelChildrenUpdate = ({
  open = false,
  handleClose = () => {},
  closeOnFinsh = true,
  travel,
  travelTrip,
}: Props) => {
  const { member } = useAuthStore();
  const company = member?.member_company;
  function calculateSellingPrice(cost: string) {
    const a = company?.company_contrat.royality ?? 0;
    const x = parseInt(cost);
    const b = company?.company_contrat.charge ?? 0;
    const y = a * x + x + b;
    return y.toFixed(0).toString();
  }
  const { updateTravelTrip } = useTravelStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [defaultChecked] = React.useState(travelTrip.travel_active);
  const [closable] = React.useState(true);
  const [travelForm, setTravelForm] = React.useState<{
    travel_vehicule: string;
    travel_cost: string;
    travel_duration: string;
    travel_zone: string;
    travel_active: boolean;
    travel_from_index: number;
    travel_to_index: number;
    travel_frequency: {
      type: string;
      data: Array<{ id: string; number: number; day: string; hour: string }>;
    };
  }>({
    ...travelTrip,
  });
  const [errorTravelState, setErrorTravelState] = React.useState<{
    [x: string]: { state: boolean; msg: string };
  }>({
    travel_zone: { state: false, msg: "" },
    travel_cost: { state: false, msg: "" },
    travel_duration: { state: false, msg: "" },
    travel_vehicule: { state: false, msg: "" },
    travel_frequency: { state: false, msg: "" },
  });
  const [errorTravelFrequencyState, setErrorTravelFrequencyState] =
    React.useState<{
      [x: string]: { state: boolean; msg: string };
    }>(
      travelTrip.travel_frequency.data.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.id]: { state: false, msg: "" },
        };
      }, {})
    );
  function onTravelValueChange(slug: string) {
    return (value: any) => {
      setTravelForm((l) => ({
        ...l,
        [slug]: value,
      }));
      if (getPropValue(errorTravelState, slug)) {
        setErrorTravelState((e) => ({
          ...e,
          [slug]: { state: false, msg: "" },
        }));
      }
    };
  }
  function addNewStart() {
    const id = generateId();
    const data = travelForm.travel_frequency.data;
    onTravelValueChange("travel_frequency")({
      type: travelForm.travel_frequency.type,
      data: [
        ...data,
        {
          id,
          number: data.length + 1,
          day: null,
          hour: null,
        },
      ],
    });
    manageTravelFrequencyError("add", id);
  }
  function manageTravelFrequencyError(action: string = "add", id: string) {
    if (action === "add") {
      setErrorTravelFrequencyState((e) => ({
        ...e,
        [id]: { state: false, msg: "" },
      }));
      return;
    } else if (action === "remove") {
      const errors = {
        ...Object.entries(errorTravelFrequencyState)
          .filter((e) => e[0] !== id)
          .reduce((prev, curr, ind, arr) => {
            return { ...prev, [curr[0]]: curr[1] };
          }, {}),
      };
      setErrorTravelFrequencyState(errors);
      return;
    } else if (action === "reinit") {
      setErrorTravelFrequencyState((e) => ({
        ...e,
        [id]: { state: false, msg: "" },
      }));
      return;
    }
    return;
  }
  function checkFormData() {
    let errorOccured = false;
    // travel_cost
    if (
      travelForm.travel_cost.length === 0 ||
      !isNumber(travelForm.travel_cost)
    ) {
      errorOccured = true;
      let msg = "";
      setErrorTravelState((e) => ({ ...e, travel_cost: { state: true, msg } }));
    } else {
      setErrorTravelState((e) => ({
        ...e,
        travel_cost: { state: false, msg: "" },
      }));
    }
    // travel_duration
    if (travelForm.travel_duration.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorTravelState((e) => ({
        ...e,
        travel_duration: { state: true, msg },
      }));
    } else {
      setErrorTravelState((e) => ({
        ...e,
        travel_duration: { state: false, msg: "" },
      }));
    }
    // travel_frequency
    if (checkFrequencyFormData()) {
      errorOccured = true;
    }
    return errorOccured;
  }
  function checkFrequencyFormData() {
    let errorOccured = false;
    // travel_frequency
    if (travelForm.travel_frequency.data.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorTravelState((e) => ({
        ...e,
        travel_frequency: { state: true, msg },
      }));
    } else {
      setErrorTravelState((e) => ({
        ...e,
        travel_frequency: { state: false, msg: "" },
      }));
    }
    // travel_frequency.data every_day
    if (travelForm.travel_frequency.data.length !== 0) {
      errorOccured =
        travelForm.travel_frequency.data
          .filter((e) =>
            travelForm.travel_frequency.type === "every_day"
              ? !e.hour
              : !e.day || !e.hour
          )
          .map((depart) => {
            setErrorTravelFrequencyState((e) => ({
              ...e,
              [depart.id]: { state: true, msg: "" },
            }));
            return depart;
          }).length !== 0;
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      return;
    }
    setIsLoading(true);
    updateTravelTrip(
      travel.travel_id,
      member?.member_company.company_id ?? "",
      {
        ...travelForm,
        travel_from: travelTrip.travel_from.station_id,
        travel_to: travelTrip.travel_to.station_id,
      }
    ).then((res) => {
      setIsLoading(false);
      if (res.success) {
        close();
      }
    });
  }
  function close() {
    handleClose();
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="flex flex-col w-[500px] pb-3 px-5 gap-4 rounded-md">
          <span className="flex text-hover font-semibold text-[20px]">
            Modifier un voyage
          </span>
          <div className="flex flex-col gap-4 pr-1 py-1 max-h-[70vh] overflow-y-auto">
            <div
              className={clsx(
                "flex flex-row px-2 py-2 gap-2 items-center rounded-md border border-black/20 text-black",
                travelForm.travel_active ? "hover:bg-black/5" : "bg-red-500/10"
              )}
            >
              <Switch
                size="small"
                disableRipple
                sx={{
                  m: 0,
                  "& .MuiSwitch-switchBase": {
                    "&.Mui-checked": {
                      "& .MuiSwitch-thumb": {
                        backgroundColor: primaryColor,
                      },
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "red",
                    },
                  },
                }}
                defaultChecked={defaultChecked}
                onChange={(e, checked) => {
                  onTravelValueChange("travel_active")(checked);
                }}
              />
              <div className="flex select-none text-[16px] pt-[3px] pb-[4px] leading-none justify-center">
                {travelForm.travel_active
                  ? "Ce voyage est: Actif"
                  : "Ce voyage est: Inactif"}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="flex text-[16px]">
                Depart: {travelTrip.travel_from.station_name}
              </span>
              <span className="flex text-[16px]">
                Arrivee: {travelTrip.travel_to.station_name}
              </span>
            </div>
            <div className="flex flex-col gap-[6px] mb-1">
              <NumberInput
                label={"Cout du voyage"}
                placeholder={"Exemple: 2000"}
                defaultValue={travelTrip.travel_cost}
                errorState={errorTravelState.travel_cost}
                onChange={onTravelValueChange("travel_cost")}
                size={"small"}
                InputProps={{
                  endAdornment: <span>Frcfa</span>,
                }}
              />
              {travelForm.travel_cost && (
                <span className="flex ml-3 text-[14px] text-primary">
                  Prix du ticket: {calculateSellingPrice(travelForm.travel_cost)}{" "}
                  Frcfa
                </span>
              )}
            </div>
            <DateTimeField
              label={"Durée Moyenne du voyage"}
              value={
                travelForm.travel_duration
                  ? moment(
                      "2000-01-01T" + travelForm.travel_duration + ":00+00:00"
                    )
                  : undefined
              }
              color={"warning"}
              format="HH:mm"
              size={"small"}
              InputProps={{
                endAdornment: <span>Heure:Minutes</span>,
              }}
              {...{ error: errorTravelState.travel_duration.state }}
              onChange={(newValue: any) => {
                const hour = moment(newValue).format("HH:mm");
                if (hour !== "Invalid date") {
                  onTravelValueChange("travel_duration")(hour);
                } else {
                  onTravelValueChange("travel_duration")(null);
                }
              }}
            />
            <div className="flex flex-row gap-3">
              <div className="flex flex-col flex-1">
                <SelectDropDown
                  label={"Zone"}
                  defaultValue={travelTrip.travel_zone}
                  error={errorTravelState.travel_zone.state}
                  options={TravelZoneLabel}
                  onChange={onTravelValueChange("travel_zone")}
                />
              </div>
              <div className="flex flex-col flex-1">
                <SelectDropDown
                  label={"Vehicule"}
                  defaultValue={travelTrip.travel_vehicule}
                  error={errorTravelState.travel_vehicule.state}
                  options={TravelVehiculeLabel}
                  onChange={onTravelValueChange("travel_vehicule")}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col flex-1">
                  <SelectDropDown
                    label={"Fréquence de departs"}
                    defaultValue={travelTrip.travel_frequency.type}
                    error={errorTravelState.travel_frequency.state}
                    options={TravelFrequencyLabel.map((e) => ({
                      label: e.label,
                      value: e.type,
                    }))}
                    onChange={function (value) {
                      onTravelValueChange("travel_frequency")({
                        type: value,
                        data: [],
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col w-[35%]">
                  <button
                    onClick={addNewStart}
                    className={`flex w-full h-full ${
                      errorTravelState.travel_frequency.state
                        ? "border-red-500 hover:border-red-500 active:border-red-500 text-red-500"
                        : "border-black/20 hover:border-black/90 active:border-active text-black"
                    } rounded-[4px] flex-row gap-1 items-center justify-center hover:bg-gainsboro/20 border `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-[14px] h-[14px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    <span className="flex text-[15px] mb-[2px] text-center">
                      Ajouter départ
                    </span>
                  </button>
                </div>
              </div>
              {travelForm.travel_frequency.data.length !== 0 && (
                <div className="flex flex-col w-full">
                  <SwichFrequency
                    type={travelForm.travel_frequency.type}
                    formData={travelForm}
                    onValueChange={(slug) => {
                      return onTravelValueChange(slug);
                    }}
                    manageError={manageTravelFrequencyError}
                    errorState={errorTravelFrequencyState}
                  />
                </div>
              )}
            </div>
          </div>
          <button
            onClick={!isLoading ? submitForm : undefined}
            className="flex px-12 py-3 text-white justify-center bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
          >
            {!isLoading ? (
              <span className="flex text-[18px]">Enregister</span>
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </LocalizationProvider>
    </ModalCustom>
  );
};

type SwichFrequencyProps = {
  type: string;
  formData: any;
  onValueChange: (slug: string) => (value: any) => void;
  errorState: {
    [x: string]: { state: boolean; msg: string };
  };
  manageError: (action: string | undefined, id: string) => void;
};
function SwichFrequency({
  type,
  formData,
  onValueChange,
  errorState,
  manageError,
}: SwichFrequencyProps) {
  switch (type) {
    case "every_day":
      return (
        <EveryDay
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );
    case "per_week":
      return (
        <PerWeek
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );
    case "per_month":
      return (
        <PerMonth
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );

    default:
      return (
        <EveryDay
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );
  }
}

export default TravelChildrenUpdate;
