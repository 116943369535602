import React from "react";
import { CreateVehiculeContext } from "./context";
import { useAuthHook } from "../auth/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useAuthDataHook } from "../authData/hooks";
import { getPropValue } from "../../functions/getPropValue";
import { sendValidData } from "./functions";

const CreateVehiculeProvider = ({ children = <div /> }) => {
  const { company } = useAuthHook();
  const { refreshVehicules } = useAuthDataHook();
  const { closeModal } = useModalHook();
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    vehicule_registration: "",
    vehicule_brand: "",
    vehicule_model: "",
    vehicule_type: "",
    vehicule_places_length:"",
  });
  const [errorState, setErrorState] = React.useState({
    vehicule_registration: { state: false, msg: "" },
    vehicule_brand: { state: false, msg: "" },
    vehicule_model: { state: false, msg: "" },
    vehicule_type: { state: false, msg: "" },
    vehicule_places_length: { state: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // vehicule_brand
    if (formData.vehicule_brand.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        vehicule_brand: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        vehicule_brand: { state: false, msg: "" },
      }));
    }
    // vehicule_model
    if (formData.vehicule_places_length.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        vehicule_places_length: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        vehicule_places_length: { state: false, msg: "" },
      }));
    }
    // vehicule_registration
    if (formData.vehicule_registration.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        vehicule_registration: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        vehicule_registration: { state: false, msg: "" },
      }));
    }
    // vehicule_type
    if (formData.vehicule_type.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, vehicule_type: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        vehicule_type: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }

  function submitForm() {
    console.log(formData);
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    setisLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendValidData(
      {
        company_id: company.company_id,
        ...formData,
      },
      function (reponse) {
        setisLoading(false);
        if (reponse.success) {
          refreshVehicules();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
        }
      }
    );
  }

  return (
    <CreateVehiculeContext.Provider
      value={{
        isLoading,
        formData,
        submitForm,
        errorState,
        onValueChange,
      }}
    >
      {children}
    </CreateVehiculeContext.Provider>
  );
};

export default CreateVehiculeProvider;
