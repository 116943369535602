import React from "react";
import { generateId } from "../../../../../functions/generateId";
import WeekMomentItem from "./weekMomentItem";
import { useCreateTrajetHook } from "../../../../createTrajet/hooks";

const PerWeek = () => {
  const {
    formData: { reccurence_data },
    addNewDepart,
    removeDepart,
  } = useCreateTrajetHook();
  return (
    <div className="flex flex-1 flex-col w-full gap-3">
      <div className="flex flex-1 flex-col w-full gap-3">
        {reccurence_data.map((depart, index) => {
          return (
            <WeekMomentItem
              key={depart.id}
              item={depart}
              numeroDepart={index + 1}
              remove={() => {
                removeDepart(depart.id);
              }}
            />
          );
        })}
      </div>
      <button
        onClick={() => {
           addNewDepart({
            id: generateId(),
            data: {}
          });
        }}
        className="flex w-full py-[8px] rounded-sm flex-row gap-2 items-center justify-center hover:bg-gainsboro/20 border border-gainsboro hover:border-black/50 active:border-active "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span className="flex text-[15px] mb-[1px] text-center text-black">
          Ajouter un depart
        </span>
      </button>
    </div>
  );
};

export default PerWeek;
