import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import EmailValidation from "./pages/emailValidation";
import CompteDesactiver from "./pages/compteDesactiver";
import ForgotPassword from "./pages/forgotPassword";
import EmailVerificationProvider from "./contexts/emailValidation/provider";
import Dashboard from "./pages/dashboard";
import LayoutPage from "./pages/layout";
import RequireAuth from "./middlewares/requireAuth";
import MissingPage from "./pages/missing";
import GenerateRoutes from "./components/routes/generateRoutes";
import { DashboardRoutes } from "./components/routes/dashboard";

function StartRoutingApp() {
  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        <Route path="/">
          <Route element={<Home />}>
            <Route path="/" element={<div />} />
          </Route>
        </Route>
        {RoutesRequiredAuth()}
        {RoutesWithoutAuth()}
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
}

export function RoutesRequiredAuth() {
  return (
    <Route
      element={
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      }
    >
      <Route path="/dashboard" element={<Dashboard />}>
        {GenerateRoutes(DashboardRoutes)}
      </Route>
    </Route>
  );
}

export function RoutesWithoutAuth() {
  return (
    <Route element={<Outlet />}>
      <Route path="/login" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/help" element={<SignUp />} />
      <Route path="/disable-account" element={<CompteDesactiver />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/email-validation"
        element={
          <EmailVerificationProvider>
            <EmailValidation />
          </EmailVerificationProvider>
        }
      />
    </Route>
  );
}

export default StartRoutingApp;
