import AutocompleteSelectInput from "../../../../../components/inputs/autoCompleteSelect";
import { useCreateTrajetHook } from "../../../../createTrajet/hooks";
import EveryDay from "../reccurenceRowList/everyDay";
import PerMonth from "../reccurenceRowList/perMonth";
import PerWeek from "../reccurenceRowList/perWeek";
import React from "react";

const ReccurenceRow = () => {
  const { onValueChange, errorState } = useCreateTrajetHook();
  const [selectedReccurence, setSelectedReccurence] = React.useState({
    label: "Chaque jour",
    slug: "everyday",
    component: <EveryDay />,
  });
  const opts = [
    { label: "Chaque jour", slug: "everyday", component: <EveryDay /> },
    { label: "Par semaine", slug: "perweek", component: <PerWeek /> },
    { label: "Par mois", slug: "permonth", component: <PerMonth /> },
  ];
  return (
    <div className="flex flex-col w-full gap-3">
      <span
        className="flex text-[17px] flex-col"
        style={{
          color: errorState.reccurence_data.state ? "red" : "black",
        }}
      >
        Gestion de la reccurence du trajet
        {errorState.reccurence_data.state && (
          <span
            className="flex text-[10px]"
            style={{
              color: "red",
            }}
          >
            {errorState.reccurence_data.msg}
          </span>
        )}
      </span>
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-1 flex-col w-full">
          <AutocompleteSelectInput
            label="Recurrence"
            defaultOption={{
              label: "Chaque jour",
              value: opts[0],
            }}
            selectedOption={{
              label: selectedReccurence.label,
              value: selectedReccurence,
            }}
            options={opts.map((e) => ({ label: e.label, value: e }))}
            errorState={errorState.reccurence_type}
            onChange={function (val) {
              onValueChange("reccurence_type")(val.slug);
              setSelectedReccurence(val);
            }}
          />
        </div>
        <div className="flex flex-1 flex-col w-full">
          {selectedReccurence.component}
        </div>
      </div>
    </div>
  );
};

export default ReccurenceRow;
