import React from "react";
import GaresTable from "./table";
import StationNew from "../../../../components/modals/models/station-new";
import RefreshButton from "../../../../components/buttons/refeshButton";
import { useStationStore } from "../../../../configs/stores/station";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useAuthStore } from "../../../../configs/stores/auth";

const StationPage = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  const { member } = useAuthStore();
  const isLoading = useStationStore((e) => e.isLoading);
  const getAll = useStationStore((e) => e.getAll);
  function refreshStations() {
    getAll(member?.member_company.company_id ?? "");
  }
  useOneEffect(() => {
    if (!isLoading) {
      refreshStations();
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-5 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">Liste des gares</span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refreshStations} isLoading={isLoading} />
            <button
              onClick={() => setisOpen(true)}
              className="flex px-12 py-2 items-center bg-primary hover:bg-hover active:bg-active rounded-md"
            >
              <span className="flex text-white">Nouvelle gare</span>
            </button>
          </div>
        </div>
        <GaresTable />
      </div>
      <StationNew open={isOpen} handleClose={close} />
    </React.Fragment>
  );
};

export default StationPage;
