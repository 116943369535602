import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { getAllStats } from "./functions/stats";

const LOCAL_DATASTORE_NAME = "lesvoyageursbusiness-stats";

type ContextProps = {
  isLoading: boolean;
  stats: {
    dashboard: {
      sell_day: string;
      sell_month: string;
      sell_month_length: string;
    };
    finance: {
      net: string;
      disponible: string;
      history: Array<any>;
    };
  };
  getAll: (id: string) => Promise<any>;
};

export const useStatsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      stats: {
        dashboard: {
          sell_day: "0",
          sell_month: "0",
          sell_month_length: "0",
        },
        finance: {
          net: "0",
          disponible: "0",
          history: [],
        },
      },
      getAll: async (id: string) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllStats(id);
        const data = res?.data ?? {};
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.stats = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
