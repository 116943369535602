import React from "react";
import { getPropValue } from "../../../../functions/getPropValue";
import { uploadFiles } from "../../../../functions/uploadFiles";
import { useAuthStore } from "../../../../configs/stores/auth";

const UpdateFiles = () => {
  const member = useAuthStore((e) => e.member);
  const company = member?.member_company;
  const updateCompany = useAuthStore((e) => e.updateCompany);
  const [isLoading, setisLoading] = React.useState(false);

  const [files, setFiles] = React.useState<any[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  function pickFiles() {
    document.getElementById("fileType")?.click();
  }
  const handleFileChange = (event: any) => {
    const selectedFiles = event.target.files;
    const allowedTypes = ["application/pdf"];
    let tempFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (!allowedTypes.includes(file.type)) {
        setError("Seuls les fichiers PDF sont autorisés.");
        return;
      }
      tempFiles.push(file);
    }

    if (tempFiles.length > 3) {
      setError("Vous ne pouvez importer que jusqu'à trois fichiers PDF.");
      return;
    }

    setError(null);
    setFiles(tempFiles);
  };
  const renderPDFs = () => {
    return (
      <div className="flex flex-col gap-3">
        {files.map((file, index) => (
          <div key={index}>
            <h2>{file.name}</h2>
            {/* Vous pouvez utiliser une bibliothèque PDF ou un lecteur PDF intégré ici */}
            {/* Par exemple, vous pouvez utiliser un <embed> ou un <iframe> pour afficher le PDF */}
            {/* <embed src={URL.createObjectURL(file)} type="application/pdf" width="500" height="600" /> */}
          </div>
        ))}
      </div>
    );
  };
  return (
    <React.Fragment>
      {files.length !== 0 && renderPDFs()}
      <button
        onClick={pickFiles}
        className="flex py-4 px-6 w-fit bg-primary active:bg-active hover:bg-hover duration-150 transition-all rounded-lg"
      >
        <span className="flex text-[16px] text-white font-semibold">
          Téléversez vos documents
        </span>
        <input
          id="fileType"
          type="file"
          accept="application/pdf"
          multiple
          className="hidden"
          onChange={handleFileChange}
        />
      </button>
    </React.Fragment>
  );
};

export default UpdateFiles;
