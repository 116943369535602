import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

type Props = {
  label?: string;
  defaultValue?: string;
  selectedValue?: string;
  error?: boolean;
  options?: Array<{
    label: string;
    value: string | number | readonly string[];
  }>;
  onChange?: (value: string) => void;
};

const SelectDropDown = ({
  options = [],
  label,
  defaultValue,
  selectedValue,
  error = false,
  onChange = (value: string) => {},
}: Props) => {
  const [defaultLocalValue] = React.useState(defaultValue);
  return (
    <div className="flex flex-col">
      <FormControl error={error} size="small">
        {label && <InputLabel id="demo-select-small-label">{label}</InputLabel>}
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          defaultValue={defaultLocalValue}
          value={selectedValue}
          label={label ?? undefined}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          {options.map(({ label, value }) => {
            return (
              <MenuItem key={value.toString()} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectDropDown;
