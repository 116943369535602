import React from "react";
import { useStationStore } from "../../../../configs/stores/station";
import { ConvenienceModel, StationModel } from "../../../../models/models";
import ConvenienceAddModal from "../../../../components/modals/models/convenienve-add";
import Icon from "../../../../components/convenience-icons/icon";
import { primaryColor } from "../../../../constants";
import clsx from "clsx";
import ConvenienceShowModal from "../../../../components/modals/models/convenienve-show";

const ConvenienceList = () => {
  const { stations } = useStationStore();
  return (
    <div className="flex flex-1 flex-col pb-[50px] gap-4 min-w-[400px] max-w-full min_pc:max-w-[650px]">
      {stations.map((station, index) => {
        return (
          <StationConveniences
            station={station}
            key={station.station_id + index.toString()}
          />
        );
      })}
    </div>
  );
};

type StationConveniencesProps = {
  station: StationModel;
};

function StationConveniences({ station }: StationConveniencesProps) {
  const [isOpen, setisOpen] = React.useState(false);
  function openModal() {
    setisOpen(true);
  }
  function closeModal() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex p-4 bg-white rounded-lg flex-col gap-4">
        <div className="flex flex-row items-center justify-between">
          <span className="line-clamp-1 text-[22px] font-semibold">
            {station.station_name}
          </span>
          <button
            onClick={openModal}
            className="flex bg-primary active:bg-active hover:bg-hover select-none p-2 px-3 rounded-md font-medium text-white text-[15px]"
          >
            Ajouter
          </button>
        </div>
        <div className="flex flex-row flex-wrap px-2 gap-2 border-[4px] border-slate-400/30 rounded-xl bg-slate-400/10 p-2">
          {!!station?.station_conveniences &&
            station.station_conveniences.map((e, index) => {
              return <Convenience key={index} item={e} station={station} />;
            })}
          {station?.station_conveniences.length === 0 && (
            <span className="flex text-[15px]">
              {"Aucune Commodités Ajouter !"}
            </span>
          )}
        </div>
      </div>
      <ConvenienceAddModal
        station={station}
        handleClose={closeModal}
        open={isOpen}
      />
    </React.Fragment>
  );
}

type ConvenienceProps = {
  item: ConvenienceModel;
  station: StationModel;
};

function Convenience({ item, station }: ConvenienceProps) {
  const updateStationConveniences = useStationStore(
    (e) => e.updateStationConveniences
  );
  const [isUpdating, setisUpdating] = React.useState(false);
  const [isOpen, setisOpen] = React.useState(false);
  function openModal() {
    setisOpen(true);
  }
  function closeModal() {
    setisOpen(false);
  }
  function Update() {
    setisUpdating(true);
    updateStationConveniences(
      station.station_id,
      station.station_company.company_id,
      {
        action: "remove",
        convenience: item.convenience_id,
      }
    ).then((res) => {
      setisUpdating(false);
    });
  }
  return (
    <React.Fragment>
      <div
        onClick={openModal}
        className={clsx(
          "flex flex-col items-center gap-3 cursor-pointer hover:bg-black/10 bg-black/5 transition-all duration-150 p-2 px-2 py-2 h-fit w-fit rounded-lg"
        )}
      >
        <Icon name={item.convenience_name} color={primaryColor} size={27} />
        <span
          onClick={!isUpdating ? Update : undefined}
          className="line-clamp-1 leading-none text-[12px] px-[6px] max-w-[100px] py-[4px] rounded-[3px] select-none bg-slate-500/15 hover:bg-slate-500/20 active:bg-primary active:text-white transition-all duration-150 font-medium"
        >
          {!isUpdating ? "Supprimer" : "Patientez.."}
        </span>
      </div>
      <ConvenienceShowModal
        convenience={item}
        handleClose={closeModal}
        open={isOpen}
      />
    </React.Fragment>
  );
}

export default ConvenienceList;
