import React from "react";
import AutocompleteSelectInput from "../../../../../components/inputs/autoCompleteSelect";
import { GareObject, TronconObject } from "../../../../../types/models";
import { useAuthDataHook } from "../../../../authData/hooks";
import { useCreateTrajetHook } from "../../../../createTrajet/hooks";
import NumberInput from "../../../../../components/inputs/number";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const GareRow = () => {
  const { gares, troncons } = useAuthDataHook();
  const { onValueChange, errorState, formData } = useCreateTrajetHook();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-1 flex-col w-full">
            <AutocompleteSelectInput
              label="Sélectionner la gare de depart"
              defaultOption={{
                label: GareObject.gare_name,
                value: GareObject,
              }}
              selectedOption={{
                label: formData.gare_start.gare_name,
                value: formData.gare_start,
              }}
              options={gares.map((e) => ({ label: e.gare_name, value: e }))}
              errorState={errorState.gare_start}
              onChange={onValueChange("gare_start")}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-1 flex-col w-full">
            <AutocompleteSelectInput
              label="Sélectionner la gare d'arriver"
              defaultOption={{
                label: GareObject.gare_name,
                value: GareObject,
              }}
              selectedOption={{
                label: formData.gare_end.gare_name,
                value: formData.gare_end,
              }}
              options={gares
                .filter((gare) => gare.gare_id !== formData.gare_start.gare_id)
                .map((e) => ({ label: e.gare_name, value: e }))}
              errorState={errorState.gare_end}
              onChange={onValueChange("gare_end")}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-1 flex-col w-full">
            <NumberInput
              label={"Prix du voyage"}
              errorState={errorState.trajet_price}
              onChange={onValueChange("trajet_price")}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-1 flex-col w-full">
            <AutocompleteSelectInput
              label="Sélectionner le tronçon"
              defaultOption={{
                label: TronconObject.troncon_name,
                value: TronconObject.troncon_id,
              }}
              selectedOption={{
                label:
                  troncons.filter((e) => e.troncon_id === formData.troncon)[0]
                    ?.troncon_name ?? "",
                value: formData.troncon,
              }}
              options={troncons.map((e) => ({
                label: e.troncon_name,
                value: e.troncon_id,
              }))}
              errorState={errorState.troncon}
              onChange={onValueChange("troncon")}
            />
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default GareRow;
