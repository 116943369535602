import React from "react";
import Header from "./header";
import BlocsRow from "./bocsrow";
import TableDataAutoList from "../../../../components/array";
import { useTicketStore } from "../../../../configs/stores/ticket";
import { TravelDepartNumberLabel } from "../../../../configs/stores/travel";
import moment from "moment";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useStatsStore } from "../../../../configs/stores/stats";

const DashboardHome = () => {
  const { member } = useAuthStore();
  const isLoading = useStatsStore((e) => e.isLoading);
  const getAll = useStatsStore((e) => e.getAll);
  function refreshStats() {
    getAll(member?.member_company.company_id ?? "");
  }
  const { tickets } = useTicketStore();
  const columns = [
    {
      label: "Client",
      slug: "user",
      maxWidth: "250px",
    },
    {
      label: "Départ",
      slug: "from",
      maxWidth: "250px",
    },
    {
      label: "Arrivée",
      slug: "to",
      maxWidth: "250px",
    },
    {
      label: "Date du départ",
      slug: "date",
      maxWidth: "280px",
    },
    {
      label: "Numero de départ",
      slug: "number",
      maxWidth: "170px",
    },
    {
      label: "Validation",
      slug: "code",
      maxWidth: "200px",
    },
    {
      label: "Cout",
      slug: "cost",
      maxWidth: "150px",
    },
    {
      label: "Validé",
      slug: "validate_state",
      maxWidth: "100px",
    },
    {
      label: "Etat",
      slug: "state",
      maxWidth: "100px",
    },
  ];
  useOneEffect(() => {
    if (!isLoading) {
      refreshStats();
    }
  }, []);
  return (
    <div className="flex flex-1 flex-col gap-5">
      <span className="flex font-medium text-[18px] mt-4">
        Bienvenue sur le Tableau de bord
      </span>
      <Header />
      <BlocsRow />
      <div className="flex flex-col mt-3 gap-3">
        <span className="flex text-[17px] font-normal">
          Liste des 10 récentes réservations
        </span>
        <TableDataAutoList
          columns={columns}
          rows={tickets.slice(0, 10).map(function (ticket) {
            return {
              ticket,
              code: ticket.ticket_validation_code,
              user: ticket.ticket_user_fullname,
              from: ticket.ticket_from_station_name,
              to: ticket.ticket_to_station_name,
              number:
                `(${ticket.ticket_start_number.toString()}) ` +
                TravelDepartNumberLabel.find(
                  (e) => e.value === ticket.ticket_start_number.toString()
                )?.label +
                " Départ",
              date:
                moment(ticket.ticket_start_date, "YYYY-MM-DD").format("LL") +
                " à " +
                ticket.ticket_start_hour,
              cost: (ticket?.ticket_travel_cost?.toString() ?? "0") + " FRCFA",
              validate_state: ticket.ticket_validate.state ? "Oui" : "Non",
              state:
                ticket.ticket_payment_state === "paied" ? "Payé" : "Impayé",
            };
          })}
        />
      </div>
    </div>
  );
};

export default DashboardHome;
