import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { TravelModel } from "../../models/models";
import {
  getAllTravel,
  newTravel,
  removeTravel,
  updateTravel,
  updateTravelTrip,
} from "./functions/travel";
import { ToastWarnNotifier } from "../../constants";

const LOCAL_DATASTORE_NAME = "lesvoyageursbusiness-travel";

type ContextProps = {
  isLoading: boolean;
  travels: Array<TravelModel>;
  getAll: (id: string) => Promise<any>;
  newTravel: (data: any) => Promise<any>;
  updateTravel: (id: string, companyId: string, update: any) => Promise<any>;
  updateTravelTrip: (id: string, companyId: string, update: any) => Promise<any>;
  removeTravel: (id: string, companyId: string) => Promise<any>;
};

export const TravelFrequencyLabel = [
  {
    type: "every_day",
    label: "Chaque jour",
  },
  {
    type: "per_week",
    label: "Par Semaine",
  },
  {
    type: "per_month",
    label: "Par Mois",
  },
];

export const TravelVehiculeLabel = [
  {
    value: "bus",
    label: "Bus",
  },
  {
    value: "train-subway",
    label: "Train/Metro",
  },
  {
    value: "boat",
    label: "Bateau",
  },
];

export const TravelZoneLabel = [
  {
    value: "local",
    label: "Locale",
  },
  {
    value: "international",
    label: "International",
  },
];

export const TravelTypeLabel = [
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "express",
    label: "Express",
  },
];

export const TravelFrequencyWeekDay = [
  { label: "Lundi", value: "monday" },
  { label: "Mardi", value: "tuesday" },
  { label: "Mercredi", value: "wednesday" },
  { label: "Jeudi", value: "thirsday" },
  { label: "Vendredi", value: "friday" },
  { label: "Samedi", value: "saturday" },
  { label: "Dimanche", value: "sunday" },
];

export const TravelDepartNumberLabel = [
  {
    value: "1",
    label: "Premier",
  },
  {
    value: "2",
    label: "Deuxième",
  },
  {
    value: "3",
    label: "Troisième",
  },
  {
    value: "4",
    label: "Quatrième",
  },
  {
    value: "5",
    label: "Cinquième",
  },
  {
    value: "6",
    label: "Sixième",
  },
  {
    value: "7",
    label: "Septième",
  },
  {
    value: "8",
    label: "Huitième",
  },
  {
    value: "9",
    label: "Neuvième",
  },
  {
    value: "10",
    label: "Dixième",
  },
  {
    value: "11",
    label: "Onzième",
  },
  {
    value: "12",
    label: "Douzième",
  },
  {
    value: "13",
    label: "Treizième",
  },
  {
    value: "14",
    label: "Quatorzième",
  },
  {
    value: "15",
    label: "Quinzième",
  },
  {
    value: "16",
    label: "Seizième",
  },
  {
    value: "17",
    label: "Dix-septième",
  },
  {
    value: "18",
    label: "Dix-huitième",
  },
  {
    value: "19",
    label: "Dix-neuvième",
  },
  {
    value: "20",
    label: "Vingtième",
  },
  {
    value: "21",
    label: "Vingt et unième",
  },
  {
    value: "22",
    label: "Vingt-deuxième",
  },
  {
    value: "23",
    label: "Vingt-troisième",
  },
  {
    value: "24",
    label: "Vingt-quatrième",
  },
  {
    value: "25",
    label: "Vingt-cinquième",
  },
  {
    value: "26",
    label: "Vingt-sixième",
  },
  {
    value: "27",
    label: "Vingt-septième",
  },
  {
    value: "28",
    label: "Vingt-huitième",
  },
  {
    value: "29",
    label: "Vingt-neuvième",
  },
  {
    value: "30",
    label: "Trentième",
  },
];

export const useTravelStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      travels: [],
      getAll: async (id: string) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllTravel(id);
        const data = (res?.data ?? []) as Array<TravelModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.travels = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      newTravel: async (data) => {
        const state = get();
        const res = await newTravel(data);
        if (res.success) {
          await state.getAll(data.travel_company);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateTravel: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateTravel(id, update);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateTravelTrip: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateTravelTrip(id, update);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      removeTravel: async (id: string, companyId) => {
        const state = get();
        const res = await removeTravel(id);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
