import React from "react";
import { ModalContainer } from "../../provider";
import Form from "./form";
import CreateGareProvider from "../../../createGare/provider";
import { useCreateGareHook } from "../../../createGare/hooks";

const CreateGare = () => {
  const { submitForm, formData, isLoading } = useCreateGareHook();
  return (
    <div className="flex flex-col max-h-[80vh] items-center pb-6 pt-3 px-10 gap-3 bg-white rounded-md w-[600px] shadow-md max-sm:w-full ">
      <span className="flex text-hover font-semibold text-[20px]">
        Ajouter une nouvelle gare
      </span>
      <div className="flex flex-row w-full items-center">
        <Form />
      </div>
      {formData.radioButtonValue === "current_position" && (
        <button
          onClick={!isLoading ? submitForm : undefined}
          disabled={formData.gare_id.length === 0}
          className="flex px-12 py-2 text-white bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
        >
          {!isLoading ? (
            <span className="flex ">Enregister la gare</span>
          ) : (
            <svg
              className="animate-spin h-5 w-5 text-black/50"
              xmlns="http://www.w3.org/2000/svg"
              fill={"transparent"}
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={"white"}
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill={"white"}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

const CreateGareFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <CreateGareProvider>
        <CreateGare />
      </CreateGareProvider>
    </ModalContainer>
  );
};

export default CreateGareFromModel;
