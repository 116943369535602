import { createContext } from "react";
import {
  CompanyObject,
  MemberObject,
  RoleObject,
  UserObject,
} from "../../types/models";

export const AuthContext = createContext({
  connected: false,
  company: CompanyObject,
  member: MemberObject,
  user: UserObject,
  role: RoleObject,
  connectionType: "user",
  setConnectionData: (data: any) => {},
});
