import React from "react";
import { ModalContainer } from "../../provider";
import CreateTrajetProvider from "../../../createTrajet/provider";
import { useCreateTrajetHook } from "../../../createTrajet/hooks";
import CheckBoxForm from "./checkBoxForm";
import Form from "./form";

const CreateTrajet = () => {
  const { submitForm, isLoading } = useCreateTrajetHook();
  return (
    <div className="flex flex-col max-h-[80vh] items-center pb-6 pt-3 px-10 pr-5 gap-3 bg-white rounded-md w-[800px] shadow-md max-sm:w-full ">
      <span className="flex text-hover font-semibold text-[20px]">
        Ajouter un nouveau trajet
      </span>
      <div className="flex flex-row h-[500px] w-full items-center">
        <CheckBoxForm />
        <div className="flex h-[70%] w-1 bg-coral" />
        <Form />
      </div>
      <button
        onClick={submitForm}
        className="flex px-12 py-2 text-white bg-coral hover:bg-hover active:bg-active rounded-md"
      >
        {!isLoading ? (
          <span className="flex ">Enregister le trajet</span>
        ) : (
          <svg
            className="animate-spin h-5 w-5 text-black/50"
            xmlns="http://www.w3.org/2000/svg"
            fill={"transparent"}
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke={"white"}
              strokeWidth="2"
            ></circle>
            <path
              className="opacity-75"
              fill={"white"}
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button>
    </div>
  );
};

const CreateTrajetFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <CreateTrajetProvider>
        <CreateTrajet />
      </CreateTrajetProvider>
    </ModalContainer>
  );
};

export default CreateTrajetFromModel;
