import { createContext } from "react";

export const CreateVehiculeContext = createContext({
  isLoading: false,
  formData: {
    vehicule_registration: "",
    vehicule_brand: "",
    vehicule_model: "",
    vehicule_type: "",
    vehicule_places_length:"",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    vehicule_registration: { state: false, msg: "" },
    vehicule_brand: { state: false, msg: "" },
    vehicule_model: { state: false, msg: "" },
    vehicule_type: { state: false, msg: "" },
    vehicule_places_length: { state: false, msg: "" },
    generalMsg: "",
  },
});
