import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@mui/material";
import { primaryColor } from "../../../../constants";
import { useCreateTrajetHook } from "../../../createTrajet/hooks";

const CheckBoxForm = () => {
  const { onValueChange, formData } = useCreateTrajetHook();
  const RadioComp = (
    <Radio
      sx={{
        color: primaryColor,
        "&.Mui-checked": {
          color: primaryColor,
        },
      }}
    />
  );
  return (
    <div className="flex flex-1 flex-col gap-1">
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label" color={"warning"}>
          Tajet
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="local"
          name="radio-buttons-group"
          value={formData.trajet_type}
          onChange={function (_, value) {
            onValueChange("trajet_type")(value);
          }}
        >
          <FormControlLabel value="local" control={RadioComp} label="Local" />
          <FormControlLabel
            value="international"
            control={RadioComp}
            label="International"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label" color={"warning"}>
          Voyage
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="normal"
          name="radio-buttons-group"
          value={formData.voyage_type}
          onChange={function (_, value) {
            onValueChange("voyage_type")(value);
          }}
        >
          <FormControlLabel value="normal" control={RadioComp} label="Normal" />
          <FormControlLabel
            value="express"
            control={RadioComp}
            label="Express"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label" color={"warning"}>
          Vehicule
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="bus"
          name="radio-buttons-group"
          value={formData.vehicule_type}
          onChange={function (_, value) {
            onValueChange("vehicule_type")(value);
          }}
        >
          <FormControlLabel value="bus" control={RadioComp} label="Bus" />
          <FormControlLabel
            value="train-metro"
            control={RadioComp}
            label="Train/Metro"
          />
          <FormControlLabel value="bateau" control={RadioComp} label="Bateau" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default CheckBoxForm;
