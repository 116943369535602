import React from "react";
import StationUpdate from "../../../../components/modals/models/station-update";
import ConfirmActionModal from "../../../../components/modals/models/confirmAction";
import { useStationStore } from "../../../../configs/stores/station";

type StationActionsProps = {
  row: any;
};

const StationActions = ({ row }: StationActionsProps) => {
  const { removeStation } = useStationStore();
  const [isOpenUpdate, setisOpenUpdate] = React.useState(false);
  function closeUpdate() {
    setisOpenUpdate(false);
  }
  const [isOpenDelete, setisOpenDelete] = React.useState(false);
  function closeDelete() {
    setisOpenDelete(false);
  }
  async function Delete() {
    await removeStation(
      row.station.station_id,
      row.station.station_company?.company_id ?? row.station.station_company
    );
  }
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-1">
        <StationAction
          title={"Modifier"}
          onClick={() => setisOpenUpdate(true)}
        />
        <StationAction
          title={"Supprimer"}
          onClick={() => setisOpenDelete(true)}
        />
      </div>
      <StationUpdate
        station={row.station}
        open={isOpenUpdate}
        handleClose={closeUpdate}
      />
      <ConfirmActionModal
        open={isOpenDelete}
        message="Voulez-vous vraiment supprimer ?"
        handleClose={closeDelete}
        confirm={Delete}
      />
    </React.Fragment>
  );
};

type StationActionProps = {
  title: string;
  onClick: () => void;
};

const StationAction = ({ title, onClick }: StationActionProps) => {
  return (
    <button
      onClick={onClick}
      className="flex pl-3 pr-1 py-2 hover:bg-slate-200/40 active:text-primary"
    >
      <span className="flex font-medium">{title}</span>
    </button>
  );
};

export default StationActions;
