import React from "react";
import ConfirmActionModal from "../../../../components/modals/models/confirmAction";
import { useMembersStore } from "../../../../configs/stores/member";
import MemberUpdate from "../../../../components/modals/models/member-update";
import MemberUpdatePassword from "../../../../components/modals/models/member-update-password";

type MemberActionsProps = {
  row: any;
};

const MemberActions = ({ row }: MemberActionsProps) => {
  const { removeMember } = useMembersStore();
  const [isOpenUpdate, setisOpenUpdate] = React.useState(false);
  function closeUpdate() {
    setisOpenUpdate(false);
  }
  const [isOpenUpdatePassword, setisOpenUpdatePassword] = React.useState(false);
  function closeUpdatePassword() {
    setisOpenUpdatePassword(false);
  }
  const [isOpenDelete, setisOpenDelete] = React.useState(false);
  function closeDelete() {
    setisOpenDelete(false);
  }
  async function Delete() {
    await removeMember(
      row.member.member_id,
      row.member.member_company?.company_id ?? row.member.member_company
    );
  }
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-1">
        <MemberAction
          title={"Modifier"}
          onClick={() => setisOpenUpdate(true)}
        />
        <MemberAction
          title={"Modifier le mot de passe"}
          onClick={() => setisOpenUpdatePassword(true)}
        />
        <MemberAction
          title={"Supprimer"}
          onClick={() => setisOpenDelete(true)}
        />
      </div>
      <MemberUpdate
        member={row.member}
        open={isOpenUpdate}
        handleClose={closeUpdate}
      />
      <MemberUpdatePassword
        member={row.member}
        open={isOpenUpdatePassword}
        handleClose={closeUpdatePassword}
      />
      <ConfirmActionModal
        open={isOpenDelete}
        message="Voulez-vous vraiment supprimer ?"
        handleClose={closeDelete}
        confirm={Delete}
      />
    </React.Fragment>
  );
};

type MemberActionProps = {
  title: string;
  onClick: () => void;
};

const MemberAction = ({ title, onClick }: MemberActionProps) => {
  return (
    <button
      onClick={onClick}
      className="flex pl-3 pr-1 py-2 hover:bg-slate-200/40 active:text-primary"
    >
      <span className="flex font-medium text-left">{title}</span>
    </button>
  );
};

export default MemberActions;
