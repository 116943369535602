import React from "react";
import ModalCustom from "../..";
import TextInput from "../../../inputs/input";
import { getPropValue } from "../../../../functions/getPropValue";
import {
  getRoleSub,
  MemberRoles,
  useMembersStore,
} from "../../../../configs/stores/member";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuthStore } from "../../../../configs/stores/auth";
import { ToastWarnNotifier } from "../../../../constants";
import { MemberModel } from "../../../../models/models";

type Props = {
  handleClose: () => void;
  open: boolean;
  member: MemberModel;
  closeOnFinsh?: boolean;
};

const MemberUpdate = ({
  open = false,
  member,
  handleClose = () => {},
  closeOnFinsh = true,
}: Props) => {
  const { member: localMember } = useAuthStore();
  const sub = getRoleSub(localMember?.member_role ?? "");
  const [closable] = React.useState(true);
  const { updateMember } = useMembersStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    member_fullname: member.member_fullname,
    member_login: member.member_login,
    member_role: member.member_role,
  });
  const [errorState, setErrorState] = React.useState({
    member_fullname: { state: false, msg: "" },
    member_login: { state: false, msg: "" },
    member_role: { state: false, msg: "" },
  });
  function checkFormData() {
    let errorOccured = false;
    // member_fullname
    if (
      formData.member_fullname.length === 0 ||
      !/[a-zA-Z0-9]+/.test(formData.member_fullname)
    ) {
      errorOccured = true;
      let msg = "Renseigner correctement le nom du membre";
      setErrorState((e) => ({ ...e, member_fullname: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        member_fullname: { state: false, msg: "" },
      }));
    }
    // member_role
    if (formData.member_role.length === 0) {
      errorOccured = true;
      let msg = "Entrer un role";
      setErrorState((e) => ({ ...e, member_role: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        member_role: { state: false, msg: "" },
      }));
    }
    // member_login
    if (
      formData.member_login.trim().length === 0 ||
      !!formData.member_login.trim().includes(" ")
    ) {
      errorOccured = true;
      let msg = "Entrer un identifant";
      if (formData.member_login.trim().includes(" ")) {
        msg = "L'identifant ne doit pas contenir d'espace";
      }
      setErrorState((e) => ({ ...e, member_login: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        member_login: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function close() {
    setFormData({
      member_fullname: member.member_fullname,
      member_login: member.member_login,
      member_role: member.member_role,
    });
    setErrorState({
      member_fullname: { state: false, msg: "" },
      member_login: { state: false, msg: "" },
      member_role: { state: false, msg: "" },
    });
    handleClose();
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      return;
    }
    setIsLoading(true);
    updateMember(
      member?.member_id ?? "",
      member?.member_company.company_id ?? "",
      {
        member_fullname: formData.member_fullname,
        member_role: formData.member_role,
        member_login: formData.member_login.trim().toLowerCase(),
      }
    ).then((res) => {
      setIsLoading(false);
      if (res.success) {
        closeOnFinsh && close();
      } else {
        ToastWarnNotifier({
          message: res.message,
        });
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col max-h-[80vh] items-center pb-5 px-5 gap-3 rounded-md w-[500px] ">
        <span className="flex text-hover font-semibold text-[20px]">
          Modifier le membre
        </span>
        <div className="flex gap-4 gap-x-1 flex-col w-full px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
          <TextInput
            label={"Nom du membre"}
            value={formData.member_fullname}
            errorState={errorState.member_fullname}
            onChange={onValueChange("member_fullname")}
          />
          <Box>
            <FormControl error={errorState.member_role.state} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sélectionner le role
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.member_role}
                label="Sélectionner le role"
                onChange={(event) => {
                  if (errorState.member_role.state) {
                    setErrorState((e) => ({
                      ...e,
                      member_role: { state: false, msg: "" },
                    }));
                  }
                  if (event.target.value) {
                    setFormData((e) => ({
                      ...e,
                      member_role: event.target.value,
                    }));
                  } else {
                    setFormData((e) => ({ ...e, member_role: "" }));
                  }
                }}
              >
                {MemberRoles.reduce((prev, curr, index, arr) => {
                  if (sub) {
                    if (sub.includes("*")) {
                      return prev.concat(curr);
                    } else if (sub.includes(curr.value)) {
                      return prev.concat(curr);
                    }
                    return prev.concat([]);
                  }
                  return [];
                }, Array<any>()).map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.value}>
                      {e.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <TextInput
            label={"Identifiant du membre"}
            errorState={errorState.member_login}
            value={formData.member_login}
            onChange={onValueChange("member_login")}
          />
          <button
            onClick={!isLoading ? submitForm : undefined}
            className="flex px-12 py-4 text-white justify-center bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
          >
            {!isLoading ? (
              <span className="flex text-[18px]">Modifier</span>
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};

export default MemberUpdate;
