import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { getAll } from "./functions/convenience";
import { ConvenienceModel } from "../../models/models";

const LOCAL_DATASTORE_NAME = "lesvoyageursbusiness-conveniences";

type ContextProps = {
  isLoading: boolean;
  conveniences: Array<ConvenienceModel>;
  getAll: () => Promise<any>;
};

export const useConveniencesStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      conveniences: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAll();
        const data = res?.data ?? {};
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.conveniences = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
