import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import { BrowserRouter } from "react-router-dom";
import { themeMui } from "./themes/mui";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from "./contexts/auth/provider";
import ModalsProvider from "./contexts/modals/provider";
import BasicsProvider from "./contexts/basics/provider";
import StartRoutingApp from "./App";
import "mapbox-gl/dist/mapbox-gl.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={themeMui}>
        <BasicsProvider>
          <AuthProvider>
            <ModalsProvider>
              <StartRoutingApp />
            </ModalsProvider>
          </AuthProvider>
        </BasicsProvider>
        <ToastContainer />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
