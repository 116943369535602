import { ANY } from "../constants";
import { RoleAllProperties } from "./roleProperty";

export const CountryObject = {
  country: "",
  code: "",
  capitals: Array<string>(),
  prefix: Array<string>(),
  lang: Array<string>(),
  villes: Array<{
    nom: "";
    code: "";
  }>(),
};

export const GareObject = {
  gare_id: "",
  company_id: "",
  gare_name: "",
  gare_geocoords: { lat: 0, lon: 0 },
  gare_locality: "",
  gare_description: "",
};

export const TrajetObject = {
  trajet_id: "",
  company_id: "",
  trajet_type: "",
  trajet_voyage_type: "",
  trajet_vehicule_type: "",
  gare_start: "",
  gare_end: "",
  trajet_price: "",
  trajet_reccurence_type: "",
  trajet_reccurence_data: "",
  trajet_geolocation: ANY,
};

export const TronconObject = {
  troncon_id: "",
  company_id: "",
  troncon_name: "",
  troncon_slug: "",
  troncon_start: "",
  troncon_locality_from: "",
  troncon_locality_to: "",
  troncon_vehicule: "",
};

export const VehiculeObject = {
  vehicule_id: "",
  company_id: "",
  vehicule_registration: "",
  vehicule_brand: "",
  vehicule_model: "",
  vehicule_type: "",
  vehicule_places_length: "",
};

export const LocalityObject = {
  locality_id: "",
  locality_parent: "",
  locality_type: "",
  locality_name: "",
  locality_slug: "",
  locality_geolocation: {},
  locality_created_date: "",
};
export const LocalityTypeObject = { label: "", slug: "" };

export const LangObject = {
  lang: "",
  slug: "",
  flag: "",
};

export const RoleObject = {
  role_id: "",
  role_name: "",
  role_slug: "",
  role_properties: RoleAllProperties,
  role_created_date: "",
};

export const CompanyObject = {
  company_id: "",
  company_name: "",
  company_country: "",
};

export const UserObject = {};

export const MemberObject = {};
