import React from "react";
import BlocItem from "./bloc";
import AddTrajet from "./addTrajet";
import moment from "moment";
import { useStatsStore } from "../../../../../configs/stores/stats";

const BlocsRow = () => {
  const { stats } = useStatsStore();
  // Timestamp actuel
  const now = moment();
  return (
    <div className="grid w-fit flex-row sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-5">
      <BlocItem
        title={`Total des ventes du jour (${now.format("DD")} ${now.format(
          "MMMM"
        )} ${now.format("YYYY")}) en FCFA`}
        number={stats.dashboard.sell_day}
      />
      <BlocItem
        title={`Total de revenue du mois (${now.format("MMMM")}) en FCFA`}
        number={stats.dashboard.sell_month}
      />
      <BlocItem
        title={`Total des voyages cumulés du mois (${now.format("MMMM")})`}
        number={stats.dashboard.sell_month_length}
      />
      <AddTrajet />
    </div>
  );
};

export default BlocsRow;
