import React from "react";
import UpdateCompanyForm from "./form";
import UpdateCompanyLogo from "./logo";

const UpdateCompanyData = () => {
  return (
    <div className="flex flex-col bg-white rounded-md gap-4 p-4">
      <span className="flex text-[17px] font-medium">
        Modifier les paramètres de l'etablissement
      </span>
      <UpdateCompanyLogo />
      <UpdateCompanyForm />
    </div>
  );
};

export default UpdateCompanyData;
