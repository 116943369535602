import React from "react";
import LoginInput from "./login-input";
import LoginPasswordInput from "./password-input";
import { getPropValue } from "../../functions/getPropValue";
import { useAuthStore } from "../../configs/stores/auth";

const LoginForm = () => {
  const isLoading = useAuthStore((e) => e.isLoadingLogin);
  const login = useAuthStore((e) => e.login);
  const [formData, setFormData] = React.useState({
    login: "",
    password: "",
  });
  const [errorState, setErrorState] = React.useState({
    login: { status: false, msg: "" },
    password: { status: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { status: false, msg: "" } }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // login
    // if (formData.login.length === 0 || !isEmail(formData.login)) {
    if (formData.login.length < 8) {
      errorOccured = true;
      let msg = "Login incorrecte";
      setErrorState((e) => ({ ...e, login: { status: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, login: { status: false, msg: "" } }));
    }
    // password
    if (formData.password.length === 0) {
      errorOccured = true;
      let msg = "Mot de passe vide";
      setErrorState((e) => ({ ...e, password: { status: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, password: { status: false, msg: "" } }));
    }
    return errorOccured;
  }

  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    login({
      login: formData.login,
      password: formData.password,
    }).then((res) => {
      if (!res.success && res.message.length !== 0) {
        setErrorState((e) => ({ ...e, generalMsg: res.message.toString() }));
      }
    });
  }
  return (
    <form className="flex flex-col gap-4" onSubmit={submitForm}>
      <LoginInput
        error={errorState.login}
        onTextChange={onValueChange("login")}
      />
      <LoginPasswordInput
        error={errorState.password}
        onTextChange={onValueChange("password")}
      />
      {!!errorState.generalMsg && (
        <span className="flex text-[15px] font-medium text-red-600">
          {errorState.generalMsg}
        </span>
      )}
      <a href="/forgot-password" className="group flex">
        <span className="flex text-coral group-hover:text-hover font-medium text-[13px]">
          Mot de passe oublie ?
        </span>
      </a>
      <button
        type="submit"
        className={`flex rounded-[10px] ${
          !isLoading ? "bg-primary hover:bg-hover" : "bg-hover"
        }  items-center justify-center py-[16px]`}
      >
        <span className="flex text-white font-semibold text-[18px]">
          {isLoading ? "Connexion...." : "Connexion"}
        </span>
      </button>
    </form>
  );
};

export default LoginForm;
