import React from "react";
import { ModalContainer } from "../../provider";
import { useCreateVehiculeHook } from "../../../createVehicule/hooks";
import Form from "./form";
import CreateVehiculeProvider from "../../../createVehicule/provider";

const CreateVehicule = () => {
  const { submitForm, isLoading } = useCreateVehiculeHook();
  return (
    <div className="flex flex-col max-h-[80vh] items-center pb-6 pt-3 px-10 gap-3 bg-white rounded-md w-[500px] shadow-md max-sm:w-full ">
      <span className="flex text-hover font-semibold text-[20px]">
        Ajouter un nouveau vehicule
      </span>
      <div className="flex flex-row h-fit w-full items-center">
        <Form />
      </div>
      <button
        onClick={submitForm}
        className="flex px-12 py-2 text-white bg-coral hover:bg-hover active:bg-active rounded-md"
      >
        {!isLoading ? (
          <span className="flex ">Enregister la vehicule</span>
        ) : (
          <svg
            className="animate-spin h-5 w-5 text-black/50"
            xmlns="http://www.w3.org/2000/svg"
            fill={"transparent"}
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke={"white"}
              strokeWidth="2"
            ></circle>
            <path
              className="opacity-75"
              fill={"white"}
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button>
    </div>
  );
};

const CreateVehiculeFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <CreateVehiculeProvider>
        <CreateVehicule />
      </CreateVehiculeProvider>
    </ModalContainer>
  );
};

export default CreateVehiculeFromModel;
