import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  stroke?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const LangIcon = ({
  color = "#828282",
  stroke = 2,
  size = 20,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 20 / 20;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 20 20" //Ratio_SVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
      stroke={color}
      strokeWidth={stroke.toString()}
    >
      <path
        d="M19 10C19 14.9706 14.9706 19 10 19M19 10C19 5.02944 14.9706 1 10 1M19 10H1M10 19C5.02944 19 1 14.9706 1 10M10 19C11.6569 19 13 14.9706 13 10C13 5.02944 11.6569 1 10 1M10 19C8.34315 19 7 14.9706 7 10C7 5.02944 8.34315 1 10 1M1 10C1 5.02944 5.02944 1 10 1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LangIcon;
