import React from "react";
import { LocalizationProvider, DateTimeField } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutocompleteSelectInput from "../../../../components/inputs/autoCompleteSelect";
import { VehiculeObject } from "../../../../types/models";
import { useAuthDataHook } from "../../../authData/hooks";
import { useCreateTronconHook } from "../../../createTroncon/hooks";
import TextInput from "../../../../components/inputs/input";

const Form = () => {
  const { vehicules } = useAuthDataHook();
  const { onValueChange, errorState, formData } = useCreateTronconHook();
  const [dateFieldDateTimePickerValue, setDateFieldDateTimePickerValue] =
    React.useState<string | null>(null);
  return (
    <div className="flex gap-4 gap-x-1 flex-col w-full px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="flex flex-col w-full gap-3">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-col w-full">
              <TextInput
                label={"Nom de la ville de depart"}
                onChange={onValueChange("troncon_locality_from")}
                errorState={errorState.troncon_locality_from}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-col w-full">
              <TextInput
                label={"Nom de la ville de fin"}
                onChange={onValueChange("troncon_locality_to")}
                errorState={errorState.troncon_locality_to}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-1 flex-col w-full">
              <AutocompleteSelectInput
                label="Sélectionner le matricule du vehicule"
                defaultOption={{
                  label: VehiculeObject.vehicule_registration,
                  value: VehiculeObject.company_id,
                }}
                selectedOption={{
                  label:
                    vehicules.filter(
                      (e) => e.vehicule_id === formData.troncon_vehicule
                    )[0]?.vehicule_registration ?? "",
                  value: formData.troncon_vehicule,
                }}
                options={vehicules.map((e) => ({
                  label: e.vehicule_registration,
                  value: e.vehicule_id,
                }))}
                errorState={errorState.troncon_vehicule}
                onChange={onValueChange("troncon_vehicule")}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-2">
            <div className="flex w-full flex-col ">
              <DateTimeField
                label={"Heure du premier depart"}
                value={dateFieldDateTimePickerValue}
                color={"warning"}
                format="HH:mm"
                {...{ error: errorState.troncon_start.state }}
                onChange={(newValue) => {
                  setDateFieldDateTimePickerValue(newValue);
                  try {
                    if (typeof newValue !== "undefined" && !!newValue) {
                      onValueChange("troncon_start")(
                        newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":")
                      );
                    }
                  } catch (error) {
                    onValueChange("troncon_start")("");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default Form;
