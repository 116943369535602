import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/sidebar";
import { useStationStore } from "../../configs/stores/station";
import { useAuthStore } from "../../configs/stores/auth";
import { useOneEffect } from "../../hooks/useOnEffect";
import { useConveniencesStore } from "../../configs/stores/conveniences";

const Dashboard = () => {
  const { member } = useAuthStore();
  const isLoadingStation = useStationStore((e) => e.isLoading);
  const getAllStation = useStationStore((e) => e.getAll);
  const isLoadingConveniences = useConveniencesStore((e) => e.isLoading);
  const getAllConveniences = useConveniencesStore((e) => e.getAll);
  function refresh() {
    getAllStation(member?.member_company.company_id ?? "");
    getAllConveniences();
  }
  const isLoading = isLoadingStation || isLoadingConveniences;
  useOneEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        refresh();
      }, 3000);
    }
  }, []);
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col ">
        <div className="flex flex-1 flex-row h-full max-h-[calc(100vh-85px)]">
          <SideBar />
          <div className="flex flex-1 p-7 py-6 pb-6 pr-10 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/40 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
