import { Outlet, Route } from "react-router-dom";
import { RouteModel } from "./model";

function GenerateRoutes(routes = Array<RouteModel>()) {
  return routes.map((route, id) => {
    return (
      <Route key={id} path={route.path} element={route.element ?? <Outlet />}>
        {route.children && GenerateRoutes(route.children)}
      </Route>
    );
  });
}

export default GenerateRoutes;
