import React from "react";
import { uploadFiles } from "../../../../functions/uploadFiles";
import { getPropValue } from "../../../../functions/getPropValue";
import { useAuthStore } from "../../../../configs/stores/auth";

const UpdateCompanyLogo = () => {
  const member = useAuthStore((e) => e.member);
  const company = member?.member_company;
  const updateCompany = useAuthStore((e) => e.updateCompany);
  const [isLoading, setisLoading] = React.useState(false);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    setisLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      if (!!reader.result) {
        let files: File[] = [];
        files.push(image);
        const reponses = await uploadFiles(
          files,
          `LesVoyageurs/uploads/company/logo/${company?.company_id}`
        );
        const dts = reponses.filter((r: any) => !!r.file_id);
        if (dts.length !== 0) {
          const imageData = {
            link: getPropValue(dts[0], "download_url"),
            key: getPropValue(dts[0], "file_id"),
          };
          await updateCompany({ company_logo: imageData });
          setisLoading(false);
        }
      }
    };
    reader.onerror = () => {
      setisLoading(false);
      console.error("something went wrong!");
    };
  }
  return (
    <div className="flex flex-row gap-3 items-center">
      <div className="flex min-w-[70px] max-w-[200px] h-[70px] overflow-hidden select-none">
        {!!company?.company_logo?.link ? (
          <div className="flex flex-1">
            <img
              className="flex flex-1 object-contain"
              src={company.company_logo?.link}
              alt="company_logo"
            />
          </div>
        ) : (
          <div className="flex flex-1 rounded-full border bg-[rgba(150,150,150,.8)] justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="w-[50px] h-[50px]"
            >
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
        )}
      </div>
      <button
        onClick={!isLoading ? pickImage : undefined}
        disabled={isLoading}
        className="flex border rounded-[14px] items-center justify-center px-4 py-2 duration-150 transition-all text-primary border-primary active:text-active hover:text-hover hover:border-hover select-none"
      >
        <span className="flex text-[15px] font-medium">
          {!isLoading ? "Modifier le logo" : "Modification..."}
        </span>
      </button>
      <input
        id="fileType"
        type="file"
        accept="image/*" //accept="image/*"
        className="hidden"
        onChange={handleChange}
      />
    </div>
  );
};

export default UpdateCompanyLogo;
