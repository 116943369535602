import { InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import React from "react";
import { isNumber } from "../../constants";

const PhoneInput = ({
  onChange = (e: string) => {},
  errorState = { state: false, msg: "" },
  ...args
}) => {
  const [inputVal, setinputVal] = React.useState("");
  const handleInputChange = (e: any) => {
    const inputValue = e.currentTarget.value;
    if (isNumber(inputValue) || inputValue.length === 0) {
      setinputVal(inputValue);
      onChange(inputValue);
    }
  };

  return (
    <TextField
      color="warning"
      variant="outlined"
      className="flex bg-white border-none outline-none"
      value={inputVal}
      error={errorState.state}
      helperText={errorState.msg.length !== 0 ? errorState.msg : null}
      onChange={handleInputChange}
      InputProps={
        !!args.countryprefix
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <span className={errorState.state ? `text-red-700` : ""}>
                    {args.countryprefix}
                  </span>
                </InputAdornment>
              ),
              inputMode: "numeric",
            }
          : { inputMode: "numeric" }
      }
      {...args}
    />
  );
};

export default PhoneInput;
