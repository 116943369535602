import React from "react";

const DownloadPolicyContrat = () => {
  const generateTextFileContent = () => {
    return "This is the content of the text file.";
  };
  // Function to handle button click event
  const handleDownload = () => {
    const textContent = generateTextFileContent();
    const element = document.createElement("a");
    const file = new Blob([textContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "text.txt";
    document.body.appendChild(element); // Required for this to work in Firefox
    element.click();
  };
  return (
    <React.Fragment>
      <button
        onClick={handleDownload}
        className="flex py-4 px-6 w-fit border border-primary active:border-active hover:border-hover duration-150 transition-all rounded-lg"
      >
        <span className="flex text-[16px] text-primary font-semibold">
          Téléchargez le model du contrat
        </span>
      </button>
    </React.Fragment>
  );
};

export default DownloadPolicyContrat;
