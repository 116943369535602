import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { isEmail, isPassword, primaryColor } from "../../constants";
import SignupTextInput from "./text-input";
import SignupEmailInput from "./email-input";
import SignupPasswordInput from "./password-input";
import { useAuthStore } from "../../configs/stores/auth";
import { getPropValue } from "../../functions/getPropValue";

const SignupForm = () => {
  const { isLoadingSignUp: isLoading, signup } = useAuthStore();
  const [formData, setFormData] = React.useState({
    company_name: "",
    company_email: "",
    company_password: "",
    company_passwordConfirmation: "",
    signupconditionchecked: false,
  });
  const [errorState, setErrorState] = React.useState({
    company_name: { status: false, msg: "" },
    company_email: { status: false, msg: "" },
    company_password: { status: false, msg: "" },
    company_passwordConfirmation: { status: false, msg: "" },
    signupconditionchecked: { status: false, msg: "" },
    generalMsg: "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { status: false, msg: "" } }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // company_name
    if (formData.company_name.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, company_name: { status: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_name: { status: false, msg: "" },
      }));
    }
    // company_email
    if (
      formData.company_email.length === 0 ||
      !isEmail(formData.company_email)||
      !!formData.company_email.trim().includes(" ")
    ) {
      errorOccured = true;
      let msg = "Adresse email incorrecte";
      setErrorState((e) => ({ ...e, company_email: { status: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_email: { status: false, msg: "" },
      }));
    }
    // company_password
    if (
      formData.company_password.length === 0 ||
      !isPassword(formData.company_password)
    ) {
      errorOccured = true;
      let msg = "";
      if (formData.company_password.length <= 10) {
        msg = "Minimum 10 caracteres";
      } else {
        msg = "Minimum un chiffre, une majuscule, une minuscule";
      }
      setErrorState((e) => ({ ...e, company_password: { status: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_password: { status: false, msg: "" },
      }));
    }
    // company_password_confirmation
    if (
      formData.company_passwordConfirmation.length === 0 ||
      formData.company_password !== formData.company_passwordConfirmation
    ) {
      errorOccured = true;
      let msg = "";
      if (
        formData.company_password.length === 0 &&
        formData.company_passwordConfirmation.length === 0
      ) {
        msg = "";
      } else {
        msg = "Confirmation incorrecte";
      }
      setErrorState((e) => ({
        ...e,
        company_passwordConfirmation: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_passwordConfirmation: { status: false, msg: "" },
      }));
    }
    // SIGNUP CONDITION CHECKED
    if (!formData.signupconditionchecked) {
      errorOccured = true;
      let msg = "** Veuillez accepter les conditions générales !";
      setErrorState((e) => ({
        ...e,
        generalMsg: msg,
      }));
      setTimeout(() => {
        setErrorState((e) => ({
          ...e,
          generalMsg: "",
        }));
      }, 7000);
    } else {
      setErrorState((e) => ({
        ...e,
        generalMsg: "",
      }));
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    signup({
      company: {
        company_name: formData.company_name,
        company_email: formData.company_email.trim().toLowerCase(),
      },
      member: {
        member_login: formData.company_email.trim().toLowerCase(),
        member_password: formData.company_password,
      },
    }).then((res) => {
      if (!res.success && res.message.length !== 0) {
        setErrorState((e) => ({ ...e, generalMsg: res.message.toString() }));
      }
    });
  }
  return (
    <div className="flex flex-col w-[350px] gap-3 text-left">
      <div className="flex flex-row flex-wrap text-[16px] gap-1 text-black">
        <span className="flex">Bienvenue sur</span>
        <span className="flex font-medium">LesVoyageurs Business</span>
      </div>
      <span className="flex flex-col text-[20px] text-black font-semibold">
        Ajoutez votre compagnie de transport
      </span>
      <form
        onSubmit={!isLoading ? submitForm : undefined}
        className="flex flex-col gap-4 pt-3"
      >
        <SignupTextInput
          placeholder="Nom de la compagnie"
          error={errorState.company_name}
          onTextChange={onValueChange("company_name")}
        />
        <SignupEmailInput
          placeholder="Email professionnel"
          error={errorState.company_email}
          onTextChange={onValueChange("company_email")}
        />
        <SignupPasswordInput
          placeholder="Mot de passe"
          error={errorState.company_password}
          onTextChange={onValueChange("company_password")}
        />
        <SignupPasswordInput
          placeholder="Confirmer mot de passe"
          error={errorState.company_passwordConfirmation}
          onTextChange={onValueChange("company_passwordConfirmation")}
        />
        <FormControlLabel
          style={{ paddingLeft: 4 }}
          control={
            <Checkbox
              checked={formData.signupconditionchecked}
              onChange={function (e, checked) {
                onValueChange("signupconditionchecked")(checked);
              }}
              sx={{
                color: primaryColor,
                "&.Mui-checked": {
                  color: primaryColor,
                },
              }}
            />
          }
          label={
            <div className="flex flex-row gap-1">
              <span>J'accepte les </span>
              <a
                href="https://www.lesvoyageurs.ci/cgv"
                target="_blank"
                className="flex text-coral underline"
                rel="noreferrer"
              >
                conditions générales
              </a>
            </div>
          }
        />
        {!!errorState.generalMsg && (
          <span className="flex select-none text-[14px] text-red-500">
            {errorState.generalMsg}
          </span>
        )}
        <button
          type="submit"
          className={`flex items-center rounded-[10px] justify-center py-[14px] ${
            formData.signupconditionchecked
              ? !isLoading
                ? "bg-coral hover:bg-hover"
                : "bg-hover"
              : "bg-[#cccc] hover:bg-[#cccc]"
          }`}
        >
          <span className="flex text-white font-semibold text-[18px]">
            {isLoading ? "Inscription...." : "Démarrer l'inscription"}
          </span>
        </button>
      </form>
    </div>
  );
};

export default SignupForm;
