import React from "react";
import TravelNew from "../../../../../components/modals/models/travel-new";
import { useAuthStore } from "../../../../../configs/stores/auth";
import { ToastWarnNotifier } from "../../../../../constants";

const AddTrajet = () => {
  const { member } = useAuthStore();
  const company = member?.member_company;
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  function openModal() {
    const contrat = company?.company_contrat.state;
    if (contrat === "inactif") {
      ToastWarnNotifier({
        message: "Impossible, votre contrat est inactif !",
      });
      return null;
    }
    setisOpen(true);
  }
  return (
    <>
      <button
        onClick={openModal}
        className="flex flex-col py-7 bg-coral hover:bg-hover active:bg-active text-white rounded-md max-w-[300px] justify-center items-center"
      >
        <span className="flex text-[17px] font-medium w-[40%]">
          Ajouter un nouveau trajet
        </span>
      </button>
      <TravelNew open={isOpen} handleClose={close} />
    </>
  );
};

export default AddTrajet;
