import React from "react";
import ConfirmActionModal from "../../../../components/modals/models/confirmAction";
import { useTravelStore } from "../../../../configs/stores/travel";
import TravelUpdate from "../../../../components/modals/models/travel-update";

type TravelsActionsProps = {
  row: any;
};

const TravelsActions = ({ row }: TravelsActionsProps) => {
  const { removeTravel } = useTravelStore();
  const [isOpenUpdate, setisOpenUpdate] = React.useState(false);
  function closeUpdate() {
    setisOpenUpdate(false);
  }
  const [isOpenDelete, setisOpenDelete] = React.useState(false);
  function closeDelete() {
    setisOpenDelete(false);
  }
  async function Delete() {
    await removeTravel(
      row.travel.travel_id,
      row.travel.travel_company?.company_id ?? row.travel.travel_company
    );
  }
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-1">
        <TravelAction
          title={"Modifier"}
          onClick={() => setisOpenUpdate(true)}
        />
        <TravelAction
          title={"Supprimer"}
          onClick={() => setisOpenDelete(true)}
        />
      </div>
      <TravelUpdate
        travel={row.travel}
        open={isOpenUpdate}
        handleClose={closeUpdate}
      />
      <ConfirmActionModal
        open={isOpenDelete}
        message="Voulez-vous vraiment supprimer ?"
        handleClose={closeDelete}
        confirm={Delete}
      />
    </React.Fragment>
  );
};

type TravelActionProps = {
  title: string;
  onClick: () => void;
};

const TravelAction = ({ title, onClick }: TravelActionProps) => {
  return (
    <button
      onClick={onClick}
      className="flex pl-3 pr-1 py-2 hover:bg-slate-200/40 active:text-primary"
    >
      <span className="flex font-medium">{title}</span>
    </button>
  );
};

export default TravelsActions;
