import React from "react";
import { TextField } from "@mui/material";
import { isNumber } from "../../constants";

const NumberInput = ({
  onChange = (e: string) => {},
  errorState = { state: false, msg: "" },
  defaultValue = "",
  ...args
}) => {
  const [inputVal, setinputVal] = React.useState(defaultValue ?? "");
  const handleInputChange = (e: any) => {
    const inputValue = e.currentTarget.value;
    if (isNumber(inputValue) || inputValue.length === 0) {
      setinputVal(inputValue);
      onChange(inputValue);
    }
  };

  return (
    <TextField
      color="warning"
      variant="outlined"
      className="flex bg-white border-none outline-none"
      value={inputVal}
      error={errorState.state}
      helperText={errorState.msg.length !== 0 ? errorState.msg : null}
      onChange={handleInputChange}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      {...args}
    />
  );
};

export default NumberInput;
