import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  stroke?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const HelpIcon = ({
  color = "#828282",
  stroke = 2,
  size = 24,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 24 / 24;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width={width}
      height={heigth}
      xmlns="http://www.w3.org/2000/svg"
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
      stroke={color}
      strokeWidth={stroke.toString()}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          opacity="0.1"
          d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          fill={color}
        ></path>
        <path
          d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke={color}
          strokeWidth={stroke.toString()}
        ></path>
        <path
          d="M10.5 8.67709C10.8665 8.26188 11.4027 8 12 8C13.1046 8 14 8.89543 14 10C14 10.9337 13.3601 11.718 12.4949 11.9383C12.2273 12.0064 12 12.2239 12 12.5V12.5V13"
          stroke={color}
          strokeWidth={stroke.toString()}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M12 16H12.01"
          stroke={color}
          strokeWidth={stroke.toString()}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
};

export default HelpIcon;
