import React from "react";
import voyageurLogo from "../../assets/voyageurLogo.svg";
import HeaderLang from "./lang";
import AccountProfile from "./accountProfile";
import HelpIcon from "../svgJsx/help";

const Header = () => {
  return (
    <div
      className={`flex h-[65px] w-full items-center justify-center bg-coral px-10 fixed z-[0] top-0 left-0`}
    >
      <div className={`flex flex-row justify-between w-full`}>
        <div className="flex flex-row gap-2 items-center">
          <a href="/" className="flex">
            <img className="w-[166px]" alt="" src={voyageurLogo} />
          </a>
          <div className="flex bg-white w-px h-[25px]" />
          <span className="flex text-white text-[20px]">Espace compagnie</span>
        </div>
        <div className="flex flex-row items-center gap-[10px] phone:gap-[5px]">
          <HelpIcon color="white" href="/help" size={29} />
          <HeaderLang />
          <AccountProfile />
        </div>
      </div>
    </div>
  );
};

export default Header;
