import React from "react";

type Props = {
  title: string;
  number: string;
  buttonTitle?: string;
  buttonOnClick?: () => void | Promise<void>;
};

const FinanceCardItem = ({
  title,
  number,
  buttonTitle,
  buttonOnClick,
}: Props) => {
  return (
    <div className="flex flex-col p-7 gap-8 bg-white rounded-md max-w-[700px] justify-between">
      <span className="flex text-[23px] font-semibold">{title}</span>
      <div className="flex flex-row gap-3">
        <span className="flex self-end text-[28px] font-semibold">
          {number}
        </span>
      </div>
      {buttonTitle && (
        <button
          onClick={buttonOnClick}
          className="flex text-[20px] active:bg-active hover:bg-hover transition-all text-white py-4 px-5 w-fit font-semibold bg-primary rounded-lg"
        >
          {buttonTitle}
        </button>
      )}
    </div>
  );
};

export default FinanceCardItem;
