import React from "react";

const BlocItem = ({ title = "", number = "" }) => {
  return (
    <div className="flex flex-col p-5 gap-5 bg-white rounded-md max-w-[300px] justify-between">
      <span className="flex text-[17px] font-medium">{title}</span>
      <span className="flex self-end text-[25px] font-semibold">{number}</span>
    </div>
  );
};

export default BlocItem;
