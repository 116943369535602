import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getPropValue } from "../../functions/getPropValue";
import { primaryColor } from "../../constants";
import { IconButton, Popover } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Column {
  align?: "center" | "left" | "right" | "inherit" | "justify" | undefined;
  minWidth?: string;
  maxWidth?: string | number | undefined;
  label: string;
  slug?: string;
  maxValueLenght?: number;
  isComponent?: Boolean;
}

interface Row {
  [key: string]: any;
}

interface Props {
  columns: Column[];
  childrenColumns?: Column[];
  rows: Row[];
  actions?: any;
  maxWidth?: number | string;
  maxHeight?: number | string;
  haveChildren?: boolean;
  childrenAction?: any;
  viewIcon?: any;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onRemove?: (data: any) => void;
  onToogleState?: (data: any, value: boolean) => void;
}

function TableDataAutoList({
  columns = [],
  rows = [],
  actions = null,
  maxHeight = "auto",
  maxWidth = "100%",
  childrenColumns = [],
  haveChildren = false,
  childrenAction = null,
  viewIcon = null,
  onView,
  onEdit,
  onRemove,
  onToogleState,
}: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{ width: "100%", maxWidth: maxWidth, overflow: "hidden", zIndex: 0 }}
      elevation={0}
    >
      <TableContainer sx={{ maxHeight: maxHeight, zIndex: 0 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {haveChildren && (
                <TableCell
                  align={"center"}
                  style={{
                    width: 50,
                    fontWeight: "700",
                  }}
                >
                  <IconButton aria-label="expand row" size="small">
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell
                  key={column.slug}
                  align={"left"}
                  sx={{ width: column?.maxWidth ?? "fit" }}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    fontWeight: "700",
                  }}
                >
                  <span className="line-clamp-1 w-full">{column.label}</span>
                </TableCell>
              ))}
              {!!actions && (
                <TableCell
                  align={"center"}
                  style={{
                    width: 100,
                    fontWeight: "700",
                  }}
                >
                  <div className="flex flex-1 justify-center items-center">
                    <span className="flex">Options</span>
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length !== 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRowItem
                      row={row}
                      key={index}
                      columns={columns}
                      actions={actions}
                      haveChildren={haveChildren}
                      childrenAction={childrenAction}
                      childrenColumns={childrenColumns}
                    />
                  );
                })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell
                  align={"left"}
                  colSpan={columns.length + 2}
                  style={{ fontWeight: "500" }}
                >
                  Aucune donnee enregister !
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

type TableRowItemProps = {
  columns: Column[];
  row: Row;
  actions: any;
  childrenColumns?: Column[];
  haveChildren?: boolean;
  childrenAction?: any;
};

const TableRowItem = ({
  columns,
  row,
  actions,
  childrenColumns = [],
  childrenAction = null,
  haveChildren = false,
}: TableRowItemProps) => {
  const [openChildren, setOpenChildren] = React.useState(true);
  // Actions
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popup" : undefined;
  const ACTIONSLIST = actions;
  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {haveChildren && (
          <TableCell
            align={"center"}
            style={{
              width: 50,
              fontWeight: "700",
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenChildren(!openChildren)}
            >
              {openChildren ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column, index2) => {
          const value = getPropValue(row, column.slug);
          return !column?.isComponent ? (
            <TableCell
              key={index2}
              align={column.align}
              style={{
                maxWidth: column.maxWidth,
              }}
            >
              <span className="line-clamp-1 w-full">{value}</span>
            </TableCell>
          ) : (
            <TableCell
              key={index2}
              align={column.align}
              style={{
                maxWidth: column.maxWidth,
              }}
            >
              {value}
            </TableCell>
          );
        })}
        {!!actions && (
          <TableCell align={"center"}>
            <div className="flex flex-1 justify-center items-center">
              <button
                aria-describedby={id}
                type="button"
                onClick={handleClick}
                className="cursor-pointer relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke={open ? primaryColor : "black"}
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                elevation={4}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="flex flex-col w-[180px] rounded-lg border overflow-hidden shadow-md shadow-black/30 bg-white max-h-[150px] overflow-y-auto">
                  <ACTIONSLIST row={row} />
                </div>
              </Popover>
            </div>
          </TableCell>
        )}
      </TableRow>
      {haveChildren && (
        <TableRow>
          <TableCell
            style={{
              width: 70,
              height: 0,
              paddingBottom: 0,
              paddingTop: 0,
              paddingInline: 0,
            }}
          >
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingInline: 0,
              backgroundColor: "#ccc",
            }}
            colSpan={columns.length + (!!childrenAction ? 3 : 2)}
          >
            <Collapse in={openChildren} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {childrenColumns.map((column, index) => (
                        <TableCell
                          key={column.slug}
                          align={"left"}
                          sx={{ width: column?.maxWidth ?? "fit" }}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                            fontWeight: "600",
                          }}
                        >
                          <span className="line-clamp-1 w-full">
                            {column.label}
                          </span>
                        </TableCell>
                      ))}
                      {!!childrenAction && (
                        <TableCell
                          align={"center"}
                          style={{
                            width: 100,
                            fontWeight: "700",
                          }}
                        >
                          <div className="flex flex-1 justify-center items-center">
                            <span className="flex">Options</span>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.children.length !== 0 ? (
                      row.children.map((rowC: any, index: number) => {
                        return (
                          <TableRowChildrenItem
                            key={index}
                            row={row}
                            rowC={rowC}
                            childrenAction={childrenAction}
                            childrenColumns={childrenColumns}
                          />
                        );
                      })
                    ) : (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell
                          align={"left"}
                          colSpan={childrenColumns.length + 2}
                          style={{ fontWeight: "500" }}
                        >
                          Aucune donnee enregister !
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

type TableRowChildrenItemProps = {
  row: any;
  rowC: any;
  childrenColumns?: Column[];
  childrenAction?: any;
};

function TableRowChildrenItem({
  row,
  rowC,
  childrenColumns = [],
  childrenAction,
}: TableRowChildrenItemProps) {
  // Children Actions
  const [anchorElChildren, setAnchorElChildren] =
    React.useState<null | HTMLElement>(null);
  const childrenHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElChildren(anchorElChildren ? null : event.currentTarget);
  };
  const childrenHandleClose = () => {
    setAnchorElChildren(null);
  };
  const childrenOpen = Boolean(anchorElChildren);
  const childrenId = childrenOpen ? "children-simple-popup" : undefined;
  const CHILDRENACTIONSLIST = childrenAction;
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {childrenColumns.map((column, index2) => {
        const value = getPropValue(rowC, column.slug);
        return !column?.isComponent ? (
          <TableCell
            key={index2}
            align={column.align}
            style={{
              maxWidth: column.maxWidth,
            }}
          >
            <span className="line-clamp-1 w-full">{value}</span>
          </TableCell>
        ) : (
          <TableCell
            key={index2}
            align={column.align}
            style={{
              maxWidth: column.maxWidth,
            }}
          >
            {value}
          </TableCell>
        );
      })}
      {!!childrenAction && (
        <TableCell align={"center"}>
          <div className="flex flex-1 justify-center items-center">
            <button
              aria-describedby={childrenId}
              type="button"
              onClick={childrenHandleClick}
              className="cursor-pointer relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={childrenOpen ? primaryColor : "black"}
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
            <Popover
              id={childrenId}
              open={childrenOpen}
              anchorEl={anchorElChildren}
              elevation={4}
              onClose={childrenHandleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="flex flex-col w-[180px] rounded-lg border overflow-hidden shadow-md shadow-black/30 bg-white max-h-[150px] overflow-y-auto">
                <CHILDRENACTIONSLIST row={row} rowC={rowC} />
              </div>
            </Popover>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
}

export default TableDataAutoList;
