import React from "react";
import RefreshButton from "../../../../components/buttons/refeshButton";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useAuthStore } from "../../../../configs/stores/auth";
import TicketsTable from "./table";
import { useTicketStore } from "../../../../configs/stores/ticket";

const TicketPage = () => {
  const { member } = useAuthStore();
  const isLoading = useTicketStore((e) => e.isLoading);
  const getAll = useTicketStore((e) => e.getAll);
  function refreshStations() {
    getAll(member?.member_company.company_id ?? "");
  }
  useOneEffect(() => {
    if (!isLoading) {
      refreshStations();
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-5 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">Liste des Tickets</span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refreshStations} isLoading={isLoading} />
          </div>
        </div>
        <TicketsTable />
      </div>
    </React.Fragment>
  );
};

export default TicketPage;
