import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const TeamIcon = ({ color = "#828282", size = 23, href, onClick }: Props) => {
  const Ratio_SVG = 23 / 23;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
      fill={"none"}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1529 2.38468C6.3098 2.38468 2.3837 6.31078 2.3837 11.1539C2.3837 12.995 2.95113 14.7037 3.92064 16.1145C6.10416 15.0434 8.55962 14.4423 11.1529 14.4423C13.7462 14.4423 16.2016 15.0434 18.3852 16.1145C19.3547 14.7037 19.9221 12.995 19.9221 11.1539C19.9221 6.31078 15.996 2.38468 11.1529 2.38468ZM19.5431 18.2083C21.1477 16.3018 22.1144 13.8407 22.1144 11.1539C22.1144 5.10001 17.2068 0.192383 11.1529 0.192383C5.09903 0.192383 0.191406 5.10001 0.191406 11.1539C0.191406 13.8407 1.15813 16.3018 2.76272 18.2083C2.7714 18.2189 2.78025 18.2294 2.78926 18.2397C4.79994 20.6106 7.80069 22.1154 11.1529 22.1154C14.5051 22.1154 17.5058 20.6106 19.5165 18.2397C19.5255 18.2294 19.5344 18.2189 19.5431 18.2083ZM16.8514 17.8194C15.1072 17.0575 13.1805 16.6346 11.1529 16.6346C9.12534 16.6346 7.19864 17.0575 5.45442 17.8194C6.9871 19.1309 8.97749 19.9231 11.1529 19.9231C13.3283 19.9231 15.3187 19.1309 16.8514 17.8194ZM11.1529 6.76928C9.94212 6.76928 8.9606 7.7508 8.9606 8.96157C8.9606 10.1723 9.94212 11.1539 11.1529 11.1539C12.3637 11.1539 13.3452 10.1723 13.3452 8.96157C13.3452 7.7508 12.3637 6.76928 11.1529 6.76928ZM6.7683 8.96157C6.7683 6.54003 8.73135 4.57698 11.1529 4.57698C13.5744 4.57698 15.5375 6.54003 15.5375 8.96157C15.5375 11.3831 13.5744 13.3462 11.1529 13.3462C8.73135 13.3462 6.7683 11.3831 6.7683 8.96157Z"
        fill={color}
      />
    </svg>
  );
};

export default TeamIcon;
