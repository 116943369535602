import React from "react";
import { EmailVerifContext } from "./context";
// import { useStateBasicCryptSave } from "../basicdata/hooks";
import { useQueryHook } from "../../functions/useQueryHook";
import { decodeBase64Url } from "../../constants";
import { getEmailStatus, verificateCode } from "./functions";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

const EmailVerificationProvider = ({ children = <div /> }) => {
  const navigate = useNaviLoadBoth;
  const [isLoading, setisLoading] = React.useState(true);
  const [confirmationError, setConfirmationError] = React.useState(false);
  const [isConfirmationLoading, setisConfirmationLoading] =
    React.useState(false);
  const { query, remove } = useQueryHook();
  // const [state, setState] = useStateBasicCryptSave("emailVerification", {
  //   company_email: "",
  //   company_id: "",
  // });
  function reinitialiseError() {
    setConfirmationError(false);
  }
  function checkEmailStatus() {
    let tok = query.get("token");
    if (tok) {
      setisLoading(true);
      let data = JSON.parse(decodeBase64Url(tok)).company;
      getEmailStatus(data.company_id, function (reponse) {
        setisLoading(false);
        if (!reponse.data.actived) {
          // setState(data);
          remove("token");
        } else {
          navigate("/signin");
        }
      });
    } 
    // else if (state.company_email.length !== 0) {
    //   setisLoading(false);
    // } 
    else {
      navigate("/signin");
      setisLoading(false);
    }
  }
  function confirmCode(code: string) {
    setisConfirmationLoading(true);
    // verificateCode({ code, id: state.company_id }, function (reponse) {
    //   setisConfirmationLoading(false);
    //   if (reponse.success) {
    //     navigate("/signin");
    //   } else {
    //     setConfirmationError(true);
    //   }
    // });
  }
  React.useEffect(() => {
    checkEmailStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <EmailVerifContext.Provider
      value={{
        confirmationError,
        reinitialiseError,
        // company_email: state.company_email,
        isLoading,
        isConfirmationLoading,
        confirmCode,
      }}
    >
      {children}
    </EmailVerifContext.Provider>
  );
};

export default EmailVerificationProvider;
