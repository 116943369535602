import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const PolicyIcon = ({ color = "#828282", size = 20, href, onClick }: Props) => {
  const Ratio_SVG = 21 / 20;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule={"evenodd"}
        clipRule={color}
        d="M10.9241 0C11.4764 0 11.9241 0.447715 11.9241 1V2.27924L16.9241 3.94591L19.6079 3.05132C20.1318 2.87667 20.6981 3.15983 20.8728 3.68377C21.0474 4.20772 20.7643 4.77404 20.2403 4.94868L18.189 5.63245L20.8567 13.6354C21.0166 14.043 20.8935 14.5231 20.5264 14.7992C18.3979 16.4003 15.4515 16.4003 13.323 14.7991C12.9794 14.5407 12.8395 14.0916 12.9754 13.6838L15.6592 5.63246L11.9241 4.38743V18H13.9241C14.4764 18 14.9241 18.4477 14.9241 19C14.9241 19.5523 14.4764 20 13.9241 20H7.9241C7.37181 20 6.9241 19.5523 6.9241 19C6.9241 18.4477 7.37181 18 7.9241 18H9.9241V4.38743L6.18902 5.63245L8.85672 13.6354C9.01656 14.0431 8.89344 14.5231 8.52643 14.7992C6.39788 16.4003 3.45149 16.4003 1.32295 14.7991C0.979402 14.5407 0.839469 14.0916 0.975416 13.6838L3.65919 5.63246L1.60787 4.94868C1.08393 4.77404 0.800768 4.20772 0.975416 3.68377C1.15006 3.15983 1.71638 2.87667 2.24033 3.05132L4.9241 3.94591L9.9241 2.27924V1C9.9241 0.447715 10.3718 0 10.9241 0ZM4.92411 8.16225L3.12102 13.5715C4.25125 14.1427 5.59706 14.1429 6.7274 13.572L4.92411 8.16225ZM16.9241 8.16225L15.121 13.5715C16.2512 14.1427 17.5971 14.1429 18.7274 13.572L16.9241 8.16225Z"
        fill={color}
      />
    </svg>
  );
};

export default PolicyIcon;
