import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import UserIconOutlined from "../svgJsx/userIconOutlinned";
import { usePopUpHook } from "../../hooks/popuphook";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import ConfirmActionModal from "../modals/models/confirmAction";

const AccountProfile = () => {
  const { isAuthenticated, member } = useAuthStore();
  const { refInput, refPopUp, open, switchMenu } = usePopUpHook<
    HTMLButtonElement,
    HTMLDivElement
  >({});
  function onClick() {
    switchMenu();
  }
  return (
    <div className="flex flex-col relative">
      {isAuthenticated && (
        <button
          ref={refInput}
          onClick={onClick}
          className="transition-all duration-150 flex h-[50px] px-3 py-1 items-center justify-center rounded-[10px] flex-row gap-2 hover:bg-black/15 active:bg-black/10"
        >
          {!!member?.member_profile?.link ? (
            <div className="flex h-[30px] w-[30px] rounded-full">
              <img
                className="flex flex-1 rounded-full object-cover overflow-hidden"
                src={member.member_profile.link}
                alt="Member_profile"
              />
            </div>
          ) : (
            <div className="flex w-[30px] h-[30px] items-center justify-center bg-stone-400 rounded-full overflow-hidden">
              <UserIconOutlined size={18} />
            </div>
          )}
          <span className="text-[15px] text-white font-medium max-w-[100px] line-clamp-1">
            {member?.member_fullname}
          </span>
        </button>
      )}
      {!isAuthenticated && (
        <button
          ref={refInput}
          onClick={onClick}
          className="transition-all duration-150 flex h-[50px] phone:h-[40px] px-3 py-1 items-center justify-center rounded-[10px] flex-row gap-2 hover:bg-black/15 active:bg-black/10"
        >
          <div className="flex tablet_mini:hidden phone:hidden w-[30px] h-[30px] items-center justify-center bg-stone-400 rounded-full">
            <UserIconOutlined size={18} />
          </div>
          <span className="flex text-[15px] text-white font-medium">
            Se connecter
          </span>
        </button>
      )}
      <div
        ref={refPopUp}
        className="absolute select-none z-[700] flex-col top-[70px] phone:top-[60px] right-0 phone:right-0 w-[200px] overflow-y-auto rounded-lg bg-white border overflow-hidden shadow-md shadow-black/30 text-[16px]"
        style={{
          visibility: open ? "visible" : "hidden",
        }}
      >
        {isAuthenticated && <UserConnectedProfileMenus />}
        {!isAuthenticated && <NotConnectedMenus />}
      </div>
    </div>
  );
};

type ButtonProfileProps = {
  title: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function ButtonProfile({
  title,
  href,
  onClick = (e) => {},
}: ButtonProfileProps) {
  const navigate = useNaviLoadBoth;
  function click(e: any) {
    !!onClick && onClick(e);
    !!href && navigate(href);
  }
  return (
    <div
      onClick={click}
      className="flex cursor-pointer px-3 py-1 h-[40px] items-center hover:bg-black/15 active:bg-black/10 rounded-[10px]"
    >
      <span className="flex">{title}</span>
    </div>
  );
}

function UserConnectedProfileMenus() {
  return (
    <div className="flex flex-col p-1">
      <LogOutAccount />
    </div>
  );
}

const LogOutAccount = () => {
  const logout = useAuthStore((e) => e.logout);
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    return await logout();
  }
  return (
    <React.Fragment>
      <ButtonProfile title="Deconnexion" onClick={() => setisOpen(true)} />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment vous déconnecter ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

function NotConnectedMenus() {
  return (
    <div className="flex flex-col p-1">
      <ButtonProfile title="Connexion" href="login" />
      <ButtonProfile title="Créer un compte" href="signup" />
    </div>
  );
}

export default AccountProfile;
