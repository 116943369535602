import React from "react";
import { CreateGareContext } from "./context";
import { getPropValue } from "../../functions/getPropValue";
import { sendValidData } from "./functions";
import { useAuthDataHook } from "../authData/hooks";
import { useAuthHook } from "../auth/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { isPhoneNumber, isPrefixPhoneNumber } from "../../constants";

const CreateGareProvider = ({ children = <div /> }) => {
  const { company } = useAuthHook();
  const { refreshGares } = useAuthDataHook();
  const { closeModal } = useModalHook();
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    gare_name: "",
    radioButtonValue: "current_position",
    prefix: "",
    phone: "",
    gare_id: "",
    gare_locality: "",
    gare_geocoords: "",
  });

  const [errorState, setErrorState] = React.useState({
    gare_name: { state: false, msg: "" },
    radioButtonValue: { state: false, msg: "" },
    prefix: { state: false, msg: "" },
    phone: { state: false, msg: "" },
    gare_id: { state: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }

  function setVerificationValues(data: any) {
    setFormData((f) => ({ ...formData, ...data }));
    setErrorState((e) => ({ ...e, gare_id: { state: false, msg: "" } }));
  }

  function checkFormData() {
    let errorOccured = false;
    // gare_name
    if (formData.gare_name.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, gare_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, gare_name: { state: false, msg: "" } }));
    }
    // radioButtonValue
    if (formData.radioButtonValue === "current_position") {
      if (formData.gare_id.length === 0) {
        errorOccured = true;
        let msg =
          "Assurer vous d'avoir veritablement enregistrer votre position apres avoir scanner le code Qr";
        setErrorState((e) => ({ ...e, id: { state: true, msg } }));
      } else {
        setErrorState((e) => ({ ...e, id: { state: false, msg: "" } }));
      }
    } else {
      if (
        formData.prefix.length === 0 ||
        !isPrefixPhoneNumber(formData.prefix)
      ) {
        errorOccured = true;
        let msg = "1 a 4 chiffres autorises pour le prefix";
        setErrorState((e) => ({ ...e, phone: { state: true, msg } }));
      } else {
        setErrorState((e) => ({ ...e, phone: { state: false, msg: "" } }));
      }
      if (formData.phone.length === 0 || !isPhoneNumber(formData.phone)) {
        errorOccured = true;
        let msg = "6 a 14 chiffres autorises";
        setErrorState((e) => ({ ...e, phone: { state: true, msg } }));
      } else {
        setErrorState((e) => ({ ...e, phone: { state: false, msg: "" } }));
      }
    }
    return errorOccured;
  }

  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    console.log(formData);
    setisLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendValidData(
      {
        company_id: company.company_id,
        gare_name: formData.gare_name,
        gare_id: formData.gare_id,
        gare_locality: formData.gare_locality,
        gare_geocoords: formData.gare_geocoords,
      },
      function (reponse) {
        setisLoading(false);
        if (reponse.success) {
          refreshGares();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
        }
      }
    );
  }

  function submitFormSendLink() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    // setisLoading(true);
    alert("Pas encore disponible !");
  }

  return (
    <CreateGareContext.Provider
      value={{
        isLoading,
        formData,
        onValueChange,
        setVerificationValues,
        errorState,
        submitForm,
        submitFormSendLink,
      }}
    >
      {children}
    </CreateGareContext.Provider>
  );
};

export default CreateGareProvider;
