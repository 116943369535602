import React from "react";
import { DashboardRoutes } from "../routes/dashboard";
import SidebarItem from "./item";

const SideBar = () => {
  return (
    <div className="flex gap-[1px] w-[230px] border-r pr-[1px] pt-[6px] pl-[1px] pb-[30px] flex-col bg-[#CECECE] h-full overflow-y-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      {DashboardRoutes.filter((e) => e.show_in_sidebar).map((item, index) => {
        return <SidebarItem key={index} item={item} />;
      })}
    </div>
  );
};

export default SideBar;
