import { useSearchParams } from "react-router-dom";

export function useModalHook() {
  const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const query = URLSearchParams;
  function closeModal() {
    query.delete("mdl");
    setURLSearchParams(() => query);
  }
  function setModal(value: string) {
    query.set("mdl", value);
    setURLSearchParams(() => query);
  }
  return {
    query,
    closeModal,
    setModal,
    setURLSearchParams,
  };
}
