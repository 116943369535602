import React from "react";
import { useStationStore } from "../../../../configs/stores/station";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useAuthStore } from "../../../../configs/stores/auth";
import UpdateFiles from "./updateFiles";
import DownloadPolicyContrat from "./downloadPolicyContrat";
import UploadPolicyContrat from "./uploadPolicyContrat";

const PolicyPage = () => {
  const { member } = useAuthStore();
  const isLoading = useStationStore((e) => e.isLoading);
  const getAll = useStationStore((e) => e.getAll);
  function refreshStations() {
    getAll(member?.member_company.company_id ?? "");
  }
  useOneEffect(() => {
    if (!isLoading) {
      refreshStations();
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-5 py-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">
            Contrat et Documents Requis
          </span>
        </div>
        <div className="flex flex-row gap-10">
          <div className="flex flex-1 flex-col gap-8">
            <span className="flex text-[15px] leading-relaxed">
              Bienvenue dans l'espace Contrat de votre dashboard ! Cette section
              vous permet de télécharger et d'uploader les documents nécessaires
              pour finaliser votre partenariat avec LesVoyageurs. Veuillez
              suivre les étapes ci-dessous pour compléter le processus :
            </span>
            <div className="flex flex-col gap-1">
              <span className="flex text-[15px] font-semibold">
                1. Téléchargement des documents requis :
              </span>
              <div className="flex flex-col gap-5 pl-4">
                <div className="flex flex-row gap-2">
                  <span className="flex text-[15px] pt-px font-bold">a.</span>
                  <span className="flex text-[15px] leading-relaxed">
                    Contrat : Téléchargez ici le contrat de collaboration entre
                    votre compagnie et LesVoyageurs. Assurez-vous de lire
                    attentivement le contrat, de le comprendre et de le signer
                    avant de l'uploader. Il est important de respecter les
                    termes et conditions énoncés dans le contrat pour bénéficier
                    pleinement de notre partenariat.
                  </span>
                </div>
                <div className="flex flex-row gap-6">
                  <DownloadPolicyContrat />
                  <UploadPolicyContrat />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="flex text-[15px] font-semibold">
                2. Téléchargement des documents requis :
              </span>
              <div className="flex flex-col gap-5 pl-4">
                <div className="flex flex-row gap-2">
                  <span className="flex text-[15px] pt-px font-bold">•</span>
                  <span className="flex text-[15px] leading-relaxed">
                    Veuillez télécharger ici une copie à jour de votre KYC,
                    comprenant votre Registre de commerce, DFE et autorisation
                    d’exercices de transport
                  </span>
                </div>
                <UpdateFiles />
              </div>
            </div>
            <div className="flex flex-col gap-2 max-w-[800px] bg-white rounded-lg border p-7">
              <span className="flex text-[15px] font-semibold">
                Remarques importantes :
              </span>
              <div className="flex flex-col gap-2 pl-4">
                <div className="flex flex-row gap-2">
                  <span className="flex text-[15px] pt-px font-bold">•</span>
                  <span className="flex text-[15px] leading-relaxed">
                    Assurez-vous que tous les documents téléchargés sont au
                    format PDF, PNG ou JPEG, et ne dépassent pas la taille
                    maximale autorisée.
                  </span>
                </div>
                <div className="flex flex-row gap-2">
                  <span className="flex text-[15px] pt-px font-bold">•</span>
                  <span className="flex text-[15px] leading-relaxed">
                    Veuillez noter que tous les documents téléchargés seront
                    traités de manière confidentielle et sécurisée, conformément
                    à notre politique de confidentialité.
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 max-w-[800px] bg-primary rounded-lg mb-10 border p-7">
              <span className="flex text-[15px] text-white leading-relaxed">
                Si vous rencontrez des difficultés techniques lors du
                téléchargement ou de l'upload de vos documents, n'hésitez pas à
                contacter notre équipe d'assistance. Nous serons ravis de vous
                aider dans ce processus.
              </span>
            </div>
          </div>
          <div className="flex w-[35%] max-w-[600px] flex-col gap-7">
            <div className="flex flex-row gap-3 items-center">
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.3662 11.2716C36.9134 16.7573 36.3118 26.5188 28.3107 32.3143C20.7051 37.8235 10.0019 36.4462 4.09992 29.2029C-1.99564 21.7231 -1.22402 11.0462 5.94602 4.56342C12.9501 -1.7617 23.0642 -1.12006 29.1543 4.08357L26.8518 6.4994C25.805 5.89095 25.0334 5.38344 24.212 4.97827C15.3742 0.576685 4.73465 6.36111 3.60763 16.1572C2.69219 24.0255 8.13782 31.0545 16.1597 32.082C24.0612 33.0914 30.7985 27.5601 32.0375 20.1895C32.3682 18.2753 32.289 16.3127 31.8052 14.4314C31.6931 13.9972 31.7609 13.7012 32.0997 13.3998C32.8575 12.7222 33.578 12.0169 34.3662 11.2716Z"
                  fill="#737373"
                />
                <path
                  d="M16.0883 19.1896L33.2826 2.64111L37.0011 6.12311L16.0773 26.1854L7.31836 17.9851C8.51313 16.8789 9.6443 15.8002 10.8114 14.7686C10.9124 14.6788 11.3231 14.7949 11.4793 14.936C12.8248 16.1598 14.1454 17.4126 15.4757 18.653C15.6569 18.8231 15.8491 18.9808 16.0883 19.1896Z"
                  fill="#737373"
                />
              </svg>
              <span className="flex font-bold text-[#737373] text-[19px]">
                Votre contrat en attente de validation
              </span>
            </div>
            <div className="flex flex-col w-full min-h-[700px] rounded-lg overflow-hidden shadow-sm bg-white">
              <div className="flex bg-black/5 py-2 items-center pl-5">
                <span className="flex font-semibold">
                  Documents téléchargés
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PolicyPage;
