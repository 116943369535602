import React from "react";
import { CreateTronconContext } from "./context";
import { useAuthHook } from "../auth/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useAuthDataHook } from "../authData/hooks";
import { getPropValue } from "../../functions/getPropValue";
import { sendValidData } from "./functions";

const CreateTronconProvider = ({ children = <div /> }) => {
  const { company } = useAuthHook();
  const { refreshTroncons } = useAuthDataHook();
  const { closeModal } = useModalHook();
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    troncon_locality_from: "",
    troncon_locality_to: "",
    troncon_start: "",
    troncon_vehicule: "",
    troncon_places_length: "",
  });
  const [errorState, setErrorState] = React.useState({
    troncon_locality_from: { state: false, msg: "" },
    troncon_locality_to: { state: false, msg: "" },
    troncon_start: { state: false, msg: "" },
    troncon_vehicule: { state: false, msg: "" },
    troncon_places_length: { state: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // troncon_locality_from
    if (formData.troncon_locality_from.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        troncon_locality_from: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        troncon_locality_from: { state: false, msg: "" },
      }));
    }
    // troncon_locality_to
    if (formData.troncon_locality_to.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({
        ...e,
        troncon_locality_to: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        troncon_locality_to: { state: false, msg: "" },
      }));
    }
    // troncon_start
    if (formData.troncon_start.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, troncon_start: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        troncon_start: { state: false, msg: "" },
      }));
    }
    // troncon_vehicule
    if (formData.troncon_vehicule.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, troncon_vehicule: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        troncon_vehicule: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }

  function submitForm() {
    console.log(formData);
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    setisLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendValidData(
      {
        company_id: company.company_id,
        troncon_name:
          formData.troncon_locality_from + " - " + formData.troncon_locality_to,
        ...formData,
      },
      function (reponse) {
        setisLoading(false);
        if (reponse.success) {
          refreshTroncons();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
        }
      }
    );
  }

  return (
    <CreateTronconContext.Provider
      value={{
        isLoading,
        formData,
        submitForm,
        errorState,
        onValueChange,
      }}
    >
      {children}
    </CreateTronconContext.Provider>
  );
};

export default CreateTronconProvider;
