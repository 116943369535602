import React from "react";
import NumberInput from "../../../inputs/number";
import { useStationStore } from "../../../../configs/stores/station";
import { StationModel } from "../../../../models/models";
import AutoCompleteSelectStationInput from "./autoCompleteSelect";
import SelectDropDown from "./select";
import { TravelTypeLabel } from "../../../../configs/stores/travel";
import clsx from "clsx";

type Props = {
  globalFormData: any;
  onGlobalValueChange: (slug: string) => (value: any) => void;
  errorStationState: {
    [x: string]: { state: boolean; msg: string };
  };
  errorGlobalFormState: {
    [x: string]: { state: boolean; msg: string };
  };
  stationList: Array<{
    id: string;
    number: number;
    station: StationModel | null;
  }>;
  addStation: () => void;
  updateStation: (
    id: string,
    number: number,
    station: StationModel | null
  ) => void;
  removeStation: (id: string) => void;
};

const StationList = ({
  errorStationState,
  errorGlobalFormState,
  stationList,
  globalFormData,
  onGlobalValueChange,
  addStation,
  updateStation,
  removeStation,
}: Props) => {
  const { stations } = useStationStore();
  return (
    <div className="flex gap-3 flex-col h-[65vh] min-h-[400px] w-[350px] border border-black/10 rounded-lg">
      <div className="flex flex-col gap-3 w-full p-4 border-b border-black/10 ">
        <SelectDropDown
          label={"Type"}
          defaultValue={globalFormData.travel_type}
          error={errorGlobalFormState?.travel_type.state ?? true}
          options={TravelTypeLabel}
          onChange={onGlobalValueChange("travel_type")}
        />
        <NumberInput
          label={"Nombre de places"}
          placeholder={"Exemple: 30"}
          defaultValue={globalFormData.travel_places}
          errorState={errorGlobalFormState?.travel_places ?? true}
          onChange={onGlobalValueChange("travel_places")}
          InputProps={{
            endAdornment: <span className="text-[15px]">Places</span>,
          }}
          size={"small"}
        />
      </div>
      <span className="flex text-[16px] pl-4">Ajouter les points d'arrets</span>
      <div className="flex flex-col w-full gap-3 p-4 pt-0 overflow-y-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
        {stationList
          .sort((a, b) => a.number - b.number)
          .map((e) => {
            const placeholder =
              e.number === 0
                ? "Gare de depart"
                : "Destination n° " + e.number.toString();
            return (
              <div
                key={e.id}
                className="flex flex-row gap-3 w-full items-center"
              >
                <div
                  className={clsx(
                    "flex w-[30px] h-[30px] items-center justify-center rounded-full",
                    errorStationState?.[e.id].state
                      ? "bg-red-500/30"
                      : "bg-black/10"
                  )}
                >
                  <span className="flex text-[14px] font-semibold">
                    {e.number.toString()}
                  </span>
                </div>
                <div className="flex flex-1 flex-col">
                  <AutoCompleteSelectStationInput
                    placeholder={placeholder}
                    defaultOption={
                      e?.station
                        ? {
                            label: e.station.station_name,
                            value: e.station.station_id,
                          }
                        : undefined
                    }
                    options={stations
                      .filter(
                        (s) =>
                          !stationList
                            .map((sl) => sl.station?.station_id)
                            .includes(s.station_id)
                      )
                      .map((e) => ({
                        label: e.station_name,
                        value: e.station_id,
                      }))}
                    errorState={errorStationState?.[e.id] ?? undefined}
                    onChange={(r) => {
                      const station =
                        stations.find((s) => s.station_id === r) ?? null;
                      updateStation(e.id, e.number, station);
                    }}
                  />
                </div>
                {stationList.length > 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => {
                      removeStation(e.id);
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
            );
          })}
        <div className="flex justify-end">
          <span
            onClick={addStation}
            className="flex cursor-pointer select-none underline underline-offset-4 text-primary hover:text-hover active:text-active text-[14px]"
          >
            Ajouter un destination
          </span>
        </div>
      </div>
    </div>
  );
};

export default StationList;
