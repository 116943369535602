import React from "react";
import ConvenienceList from "./list";
import DescriptionCard from "./description";

const ConveniencePage = () => {
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-5 py-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">Commodités</span>
        </div>
        <div className="flex flex-row flex-wrap w-full gap-8">
          <div className="flex min_pc:hidden h-fit w-full">
            <DescriptionCard />
          </div>
          <ConvenienceList />
          <div className="hidden min_pc:flex sticky h-fit w-[500px] top-[65px]">
            <DescriptionCard />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ConveniencePage;
