import React from "react";
import { ANY, primaryColor } from "../../../../../constants";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  LocalizationProvider,
  DateTimeField,
  DatePicker,
  // DateField,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCreateTrajetHook } from "../../../../createTrajet/hooks";

const MonthMomentItem = ({
  item = { id: "", data: ANY },
  numeroDepart = 0,
  remove = () => {},
}) => {
  const [
    dateFieldDateTimePickerValueStart,
    setDateFieldDateTimePickerValueStart,
  ] = React.useState<string | null>(null);
  const [dateFieldDateTimePickerValueEnd, setDateFieldDateTimePickerValueEnd] =
    React.useState<string | null>(null);
  const { updateDepart } = useCreateTrajetHook();
  React.useEffect(() => {
    updateDepart({
      ...item,
      data: {
        ...item.data,
        label: "Depart N°" + numeroDepart.toString(),
        value: {
          month: null,
          day: null,
          start: null,
          end: null,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroDepart]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-row gap-3 items-center">
        <div className="flex w-full flex-col ">
          <DatePicker
            label={"Mois et jour de depart"}
            views={["month", "day"]}
            format="MM/dd"
            openTo="month"
            onChange={function (val: any, e) {
              if (val) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      month: (val.getMonth() + 1).toString(),
                      day: val.getDate().toString(),
                    },
                  },
                });
              } else {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data.value,
                      month: null,
                      day: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Heure de depart"}
            value={dateFieldDateTimePickerValueStart}
            color={"warning"}
            format="HH:mm"
            onChange={(newValue) => {
              setDateFieldDateTimePickerValueStart(newValue);
              try {
                if (typeof newValue !== "undefined" && !!newValue) {
                  updateDepart({
                    ...item,
                    data: {
                      ...item.data,
                      value: {
                        ...item.data.value,
                        start: newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":"),
                      },
                    },
                  });
                }
              } catch (error) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      start: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Heure d'arriver"}
            value={dateFieldDateTimePickerValueEnd}
            color={"warning"}
            format="HH:mm"
            onChange={(newValue) => {
              setDateFieldDateTimePickerValueEnd(newValue);
              try {
                if (typeof newValue !== "undefined" && !!newValue) {
                  updateDepart({
                    ...item,
                    data: {
                      ...item.data,
                      value: {
                        ...item.data.value,
                        end: newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":"),
                      },
                    },
                  });
                }
              } catch (error) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      end: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <IconButton
          size="small"
          aria-label="remove item"
          color={"warning"}
          onClick={remove}
        >
          <RemoveIcon htmlColor={primaryColor} />
        </IconButton>
      </div>
    </LocalizationProvider>
  );
};

export default MonthMomentItem;
