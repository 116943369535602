import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const TravelIcon = ({
  color = "#828282",
  size = 19,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 20 / 19;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule={"evenodd"}
        clipRule={color}
        d="M12.8424 0.111437C13.1304 -0.0371458 13.4694 -0.0371458 13.7575 0.111437L19.3304 2.98634C20.0236 3.34395 20.4614 4.07494 20.4614 4.87457V16.2364C20.4614 17.8058 18.8607 18.8265 17.5002 18.1247L13.2999 15.9579L7.61904 18.8885C7.33101 19.0371 6.992 19.0371 6.70397 18.8885L1.13108 16.0136C0.43788 15.656 0 14.925 0 14.1253V2.76347C0 1.19411 1.60072 0.173404 2.96121 0.875241L7.16151 3.04206L12.8424 0.111437ZM6.13843 4.87457L2.04614 2.76347V14.1253L6.13843 16.2364V4.87457ZM8.18458 16.2364L12.2769 14.1253V2.76347L8.18458 4.87457V16.2364ZM14.323 2.76347V14.1253L18.4153 16.2364V4.87457L14.323 2.76347Z"
        fill={color}
      />
    </svg>
  );
};

export default TravelIcon;
