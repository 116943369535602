import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "./encryption";
import { useAuthStore } from "./stores/auth";

export const clientGeolocationUrl = window.location.hostname.includes("lesvoyageurs")
  ? "https://lesvoyageurs-service-weblocation.vercel.app"
  : "http://" + window.location.hostname + ":5002";

export const onlineApisUrl = "https://lesvoyageurs-api.vercel.app";

export const localApisUrl = window.location.hostname.includes("lesvoyageurs")
  ? onlineApisUrl
  : "http://" + window.location.hostname + ":5010";

export const usedBaseUrl = localApisUrl;

export const api = axios.create({
  baseURL: usedBaseUrl + "/v1/business",
});

export function useAuthApiInterceptor() {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const refreshToken = useAuthStore((e) => e.refreshToken);
  const tokens = useAuthStore((e) => e.tokens);
  const logout = useAuthStore((e) => e.logout);
  const navigate = useNavigate();
  const location = useLocation();

  // Interceptiion des requetes sortantes
  api.interceptors.request.use(
    (config) => {
      if (!!tokens) {
        const lastTokens = decrypt(
          tokens,
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        const newTokens = encrypt(
          JSON.stringify(lastTokens),
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        config.headers.Authorization = `Bearer ${newTokens}`; // Ajouter le token à l'en-tête
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Interceptiion des requetes entrantes
  api.interceptors.response.use(
    (response) => {
      if (!!response.data?.tokens) {
        refreshToken(response.data.tokens);
      }
      return response;
    },
    async (error) => {
      if (error?.response?.status === 403) {
        if (isAuthenticated) {
          await logout(() => {
            navigate("login", { state: { from: location }, replace: true });
          });
        }
      }
      return Promise.reject(error);
    }
  );

  return true;
}
