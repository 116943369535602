import React from "react";
import TravelTable from "./table";
import RefreshButton from "../../../../components/buttons/refeshButton";
import TravelNew from "../../../../components/modals/models/travel-new";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useTravelStore } from "../../../../configs/stores/travel";
import { useOneEffect } from "../../../../hooks/useOnEffect";
// import { ToastWarnNotifier } from "../../../../constants";

const TravelPage = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  const { member } = useAuthStore();
  // const company = member?.member_company;
  const isLoading = useTravelStore((e) => e.isLoading);
  const getAll = useTravelStore((e) => e.getAll);
  function refreshTravels() {
    getAll(member?.member_company.company_id ?? "");
  }
  function openModal() {
    // const contrat = company?.company_contrat.state;
    // if (contrat === "inactif") {
    //   ToastWarnNotifier({
    //     message: "Impossible, votre contrat est inactif !",
    //   });
    //   return null;
    // }
    setisOpen(true);
  }
  useOneEffect(() => {
    if (!isLoading) {
      refreshTravels();
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-5 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">
            Liste de tous les trajets
          </span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refreshTravels} isLoading={isLoading} />
            <button
              onClick={openModal}
              className="flex px-12 py-2 items-center bg-primary hover:bg-hover active:bg-active rounded-md"
            >
              <span className="flex text-white">Nouveau trajet</span>
            </button>
          </div>
        </div>
        <TravelTable />
      </div>
      <TravelNew open={isOpen} handleClose={close} />
    </React.Fragment>
  );
};

export default TravelPage;
