import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  stroke?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const ArrowDownMiniIcon = ({
  color = "#828282",
  stroke = 1,
  size = 20,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 20 / 20;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill={color}
      width={width}
      height={heigth}
      strokeWidth={stroke.toString()}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule="evenodd"
        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ArrowDownMiniIcon;
