import React, { useEffect } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff, Refresh } from "@mui/icons-material";

const PasswordInput = ({
  onChange = (e: string) => {},
  errorState = { state: false, msg: "" },
  togglePassword = true,
  autoGenerate = false,
  showPasswordDefault = false, 
  ...args
}) => {
  const [showPassword, setShowPassword] = React.useState(showPasswordDefault);
  const [password, setPassword] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  function generateRandomPassword() {
    // Caractères possibles pour chaque type
    let lettresMinuscules = 'abcdefghijklmnopqrstuvwxyz';
    let lettresMajuscules = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let chiffres = '0123456789';

    // Fonction pour choisir un caractère aléatoire d'une chaîne
    function choisirCaractere(chaine:string) {
        return chaine.charAt(Math.floor(Math.random() * chaine.length));
    }

    // Générer un mot de passe avec au moins une lettre minuscule, une majuscule et un chiffre
    let motDePasse = '';
    motDePasse += choisirCaractere(lettresMinuscules);
    motDePasse += choisirCaractere(lettresMajuscules);
    motDePasse += choisirCaractere(chiffres);
    for (let i = 0; i < 7; i++) {
        motDePasse += choisirCaractere(lettresMinuscules + lettresMajuscules + chiffres);
    }

    // Mélanger le mot de passe pour un meilleur mélange des caractères
    motDePasse = motDePasse.split('').sort(function(){return 0.5-Math.random()}).join('');

    return motDePasse;
}

  const handleRegeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setPassword(newPassword);
    onChange(newPassword);
  };

  useEffect(() => {
    if (autoGenerate) {
      handleRegeneratePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoGenerate]);

  return (
    <FormControl variant="outlined" color="warning" error={errorState.state}>
      {!!args.label && (
        <InputLabel
          htmlFor="outlined-adornment-password"
          error={errorState.state}
        >
          {args.label}
        </InputLabel>
      )}
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        error={errorState.state}
        value={password}
        className="flex bg-white border-none outline-none"
        onChange={function (value) {
          setPassword(value.currentTarget.value);
          onChange(value.currentTarget.value);
        }}
        endAdornment={
          <>
            {togglePassword && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
            {autoGenerate && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="regenerate password"
                  onClick={handleRegeneratePassword}
                  edge="end"
                >
                  <Refresh />
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
        label={args.label}
      />
      {errorState.state && errorState.msg.length !== 0 && (
        <span className="flex w-full bg-white pl-[16px] py-1 font-medium text-red-600 text-[12px]">
          {errorState.msg}
        </span>
      )}
    </FormControl>
  );
};
export default PasswordInput;
