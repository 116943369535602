import React from "react";
import RefreshButton from "../../../../components/buttons/refeshButton";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useAuthStore } from "../../../../configs/stores/auth";
import FinanceCardItem from "./card";
import { useStatsStore } from "../../../../configs/stores/stats";
import FinanceTable from "./table";

const FinancePage = () => {
  const { member } = useAuthStore();
  const isLoading = useStatsStore((e) => e.isLoading);
  const { stats } = useStatsStore();
  const getAll = useStatsStore((e) => e.getAll);
  function refreshStats() {
    getAll(member?.member_company.company_id ?? "");
  }
  useOneEffect(() => {
    if (!isLoading) {
      refreshStats();
    }
  }, []);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col gap-8 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[22px] font-medium">Finances</span>
          <div className="flex flex-row gap-4">
            <RefreshButton refresh={refreshStats} isLoading={isLoading} />
          </div>
        </div>
        <FinanceCardItem
          title="Total Solde Net :"
          number={stats.finance.net + " Frcfa"}
        />
        <FinanceCardItem
          title="Total Solde Disponible :"
          number={stats.finance.disponible + " Frcfa"}
          buttonTitle={"Démarrer le retrait"}
          buttonOnClick={() => {}}
        />
        <div className="flex flex-col gap-5">
          <span className="flex text-[22px] font-medium">Historiques Mensuelles</span>
          <FinanceTable />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinancePage;
