import React from "react";
import { useAuthApiInterceptor } from "../../configs/api";

const AuthProvider = ({ children = <div /> }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const interceptors = useAuthApiInterceptor();
  return (
    <React.Fragment>
      <div className="flex flex-1">{children}</div>
    </React.Fragment>
  );
};

export default AuthProvider;
