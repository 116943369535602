import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useTicketStore } from "../../../../configs/stores/ticket";
import moment from "moment";
import { TravelDepartNumberLabel } from "../../../../configs/stores/travel";
import "moment/locale/fr";

const TicketsTable = () => {
  const { tickets } = useTicketStore();
  const columns = [
    {
      label: "Client",
      slug: "user",
      maxWidth: "250px",
    },
    {
      label: "Départ",
      slug: "from",
      maxWidth: "250px",
    },
    {
      label: "Arrivée",
      slug: "to",
      maxWidth: "250px",
    },
    {
      label: "Date du départ",
      slug: "date",
      maxWidth: "280px",
    },
    {
      label: "Numero de départ",
      slug: "number",
      maxWidth: "170px",
    },
    {
      label: "Validation",
      slug: "code",
      maxWidth: "200px",
    },
    {
      label: "Cout",
      slug: "cost",
      maxWidth: "150px",
    },
    {
      label: "Validé",
      slug: "validate_state",
      maxWidth: "100px",
    },
    {
      label: "Etat",
      slug: "state",
      maxWidth: "100px",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        rows={tickets.map(function (ticket) {
          return {
            ticket,
            code: ticket.ticket_validation_code,
            user: ticket.ticket_user_fullname,
            from: ticket.ticket_from_station_name,
            to: ticket.ticket_to_station_name,
            number:
              `(${ticket.ticket_start_number.toString()}) ` +
              TravelDepartNumberLabel.find(
                (e) => e.value === ticket.ticket_start_number.toString()
              )?.label,
            date:
              moment(ticket.ticket_start_date, "YYYY-MM-DD").format("LL") +
              " à " +
              ticket.ticket_start_hour.split(":").join("h") +
              "min",
            cost: (ticket?.ticket_travel_cost?.toString() ?? "0") + " FRCFA",
            validate_state: ticket.ticket_validate.state ? "Oui" : "Non",
            state: ticket.ticket_payment_state === "paied" ? "Payé" : "Impayé",
          };
        })}
      />
    </div>
  );
};

export default TicketsTable;
