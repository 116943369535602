import React from "react";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { ANY, primaryColor } from "../../../../../constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DateTimeField } from "@mui/x-date-pickers";
import { useCreateTrajetHook } from "../../../../createTrajet/hooks";

const DayMomentItem = ({
  item = { id: "", data: ANY },
  numeroDepart = 0,
  remove = (id: string) => {},
}) => {
  const [dateFieldDateTimePickerValueStart, setDateFieldDateTimePickerValueStart] =
    React.useState<string | null>(null);
    const [dateFieldDateTimePickerValueEnd, setDateFieldDateTimePickerValueEnd] =
      React.useState<string | null>(null);
  const { updateDepart } = useCreateTrajetHook();

  React.useEffect(() => {
    updateDepart({
      ...item,
      data: {
        ...item.data,
        label: " N°" + numeroDepart.toString(),
        value: { start: null, end: null },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroDepart]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-row gap-3 items-center">
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Depart" + item.data?.label ?? ""}
            value={dateFieldDateTimePickerValueStart}
            color={"warning"}
            format="HH:mm"
            onChange={(newValue) => {
              setDateFieldDateTimePickerValueStart(newValue);
              try {
                if (typeof newValue !== "undefined" && !!newValue) {
                  updateDepart({
                    ...item,
                    data: {
                      ...item.data,
                      value: {
                        ...item.data.value,
                        start: newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":"),
                      },
                    },
                  });
                }
              } catch (error) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      start: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Arriver" + item.data?.label ?? ""}
            value={dateFieldDateTimePickerValueEnd}
            color={"warning"}
            format="HH:mm"
            onChange={(newValue) => {
              setDateFieldDateTimePickerValueEnd(newValue);
              try {
                if (typeof newValue !== "undefined" && !!newValue) {
                  updateDepart({
                    ...item,
                    data: {
                      ...item.data,
                      value: {
                        ...item.data.value,
                        end: newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":"),
                      },
                    },
                  });
                }
              } catch (error) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      end: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <IconButton
          aria-label="remove item"
          color={"warning"}
          onClick={function () {
            remove(item.id);
          }}
        >
          <RemoveIcon htmlColor={primaryColor} />
        </IconButton>
      </div>
    </LocalizationProvider>
  );
};

export default DayMomentItem;
