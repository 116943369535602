import React from "react";
import moment from "moment"

const Footer = () => {
  return (
    <div className="footerCompoent flex w-full py-[6px] px-10 bg-white border-t border-black/20">
      <span className="flex text-coral text-[10px]">
        © {moment().format("yyyy")} LesVoyageurs, Tous droits réservés
      </span>
    </div>
  );
};

export default Footer;
