import React from "react";
import GareRow from "./components/gareRow";
import ReccurenceRow from "./components/recurrenceRow";

const Form = () => {
  return (
    <div className="flex gap-4 gap-x-1 flex-col w-[75%] px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      <GareRow />
      <ReccurenceRow />
    </div>
  );
};

export default Form;
