import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllStation(id: string) {
  return getUrl("/station/all?id=" + id);
}

export function checkStationLocality(id: string = "") {
  return getUrl("/station/check/locality?id=" + id);
}

export function sendStationMessage(data: {
  id: string;
  station: string;
  company: string;
}) {
  return postUrl("/station/send/sms", data);
}

export function newStation(data: any = {}) {
  return postUrl("/station/new", data);
}

export function updateStation(id: string = "", data: any = {}) {
  return postUrl("/station/update?id=" + id, data);
}

export function updateStationConveniences(id: string = "", data: any = {}) {
  return postUrl("/station/update/conveniences?id=" + id, data);
}

export function removeStation(id: string = "") {
  return deleteUrl("/station/remove?id=" + id);
}

export function verifyStation(id: string = "") {
  return getUrl("/station/verify?id=" + id);
}

export function sendLinkStation(id: string = "", data: any) {
  return postUrl("/station/send/link?id=" + id, data);
}
