import React from "react";
import AutocompleteSelectInput from "../../../../components/inputs/autoCompleteSelect";
import { VehiculeObject } from "../../../../types/models";
import { useCreateVehiculeHook } from "../../../createVehicule/hooks";
import TextInput from "../../../../components/inputs/input";
import NumberInput from "../../../../components/inputs/number";

const Form = () => {
  const { onValueChange, errorState, formData } = useCreateVehiculeHook();
  const options = [
    { label: "Bus", value: "bus" },
    { label: "Train/Metro", value: "train/subway" },
    { label: "Bateau", value: "boat" },
  ];
  return (
    <div className="flex gap-4 gap-x-1 flex-col w-full px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-col w-full">
            <TextInput
              label={"Marque du vehicule"}
              onChange={onValueChange("vehicule_brand")}
              errorState={errorState.vehicule_brand}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-col w-full">
            <TextInput
              label={"Model du vehicule ( facultatif )"}
              onChange={onValueChange("vehicule_model")}
              errorState={errorState.vehicule_model}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-col w-full">
            <TextInput
              label={"Immatriculation du vehicule"}
              onChange={onValueChange("vehicule_registration")}
              errorState={errorState.vehicule_registration}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-1 flex-col w-full">
            <AutocompleteSelectInput
              label="Sélectionner le type de vehicule"
              defaultOption={{
                label: VehiculeObject.vehicule_registration,
                value: VehiculeObject.company_id,
              }}
              selectedOption={{
                label:
                  options.filter((e) => e.value === formData.vehicule_type)[0]
                    ?.label ?? "",
                value: formData.vehicule_type,
              }}
              options={options}
              errorState={errorState.vehicule_type}
              onChange={onValueChange("vehicule_type")}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-1 flex-col w-full">
            <NumberInput
              label={"Nombre de place"}
              errorState={errorState.vehicule_places_length}
              onChange={onValueChange("vehicule_places_length")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
