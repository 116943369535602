import React from 'react'
import Insertionpresse from "../../assets/insertionpresse.jpg"

const VoyagePresentation = () => {
  return (
    <div className="flex flex-col w-[375px] gap-4">
        <img src={Insertionpresse} alt="" />
    </div>
  )
}

export default VoyagePresentation