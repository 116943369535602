import React from "react";
import Image from "../../../../assets/convenience.jpg";
import ParkingIcon from "../../../../components/icons/parkingIcon";
import WifiIcon from "../../../../components/icons/wifiIcon";
import MealIcon from "../../../../components/icons/MealIcon";
import AirVentIcon from "../../../../components/icons/AirVentIcon";
import HandicapIcon from "../../../../components/icons/HandicapIcon";
import WaitIcon from "../../../../components/icons/WaitIcon";

const DescriptionCard = () => {
  const conveniences = [
    { label: "Zone d’attente", icon: WaitIcon },
    { label: "Prise en charge handicapé", icon: HandicapIcon },
    { label: "Wifi", icon: WifiIcon },
    { label: "Toilette", icon: ParkingIcon },
    { label: "Parking", icon: ParkingIcon },
    { label: "Air conditionné", icon: AirVentIcon },
    { label: "Bus climatisé", icon: AirVentIcon },
    { label: "Repas", icon: MealIcon },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-col gap-3 w-full pb-[50px] h-fit border bg-white overflow-hidden rounded-t-lg">
        <div className="flex relative flex-col w-full items-start justify-end">
          <img className="flex w-full object-cover max-h-[350px] object-top" src={Image} alt="kjfg" />
          <div className="flex absolute h-full w-full bg-gradient-to-b from-transparent via-black/20 to-black/75" />
          <span className="flex absolute mx-3 mb-3 text-[20px] text-white font-semibold">
            Sublimez vos voyageurs par une expérience client inoubliable !
          </span>
        </div>
        <div className="flex flex-col gap-3 px-3 pb-3">
          <span className="flex text-[17px] font-medium">
            Innovez, équipez vos gars et véhicule de transport de commodités qui
            marquent l’esprit de vos clients et devenez leur choix numero 1.
          </span>
          <div className="flex flex-col gap-2">
            {conveniences.map((e) => {
              const COMPICON = e.icon;
              return (
                <div className="flex items-center flex-row gap-2">
                  <COMPICON color="black" />
                  <span className="flex text-[15px]">{e.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DescriptionCard;
