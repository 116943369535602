import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { MemberModel } from "../../models/models";
import {
  getAllMember,
  newMember,
  removeMember,
  updateMember,
  updateMemberPassword,
} from "./functions/member";
import { ToastWarnNotifier } from "../../constants";

export const MemberRoles = [
  {
    label: "Super Administrateur",
    value: "super-administrator",
    sub: ["*"],
    description: ``,
  },
  {
    label: "Controlleur",
    value: "controller",
    sub: [],
    description: ``,
  },
  {
    label: "Pilote",
    value: "driver",
    sub: [],
    description: ``,
  },
];

export function getRoleLabel(value: string) {
  return MemberRoles.find((e) => e.value === value)?.label;
}
export function getRoleSub(value: string) {
  return MemberRoles.find((e) => e.value === value)?.sub;
}

const LOCAL_DATASTORE_NAME = "lesvoyageursbusiness-member";

type ContextProps = {
  isLoading: boolean;
  members: Array<MemberModel>;
  getAll: (id: string) => Promise<any>;
  newMember: (data: any) => Promise<any>;
  updateMember: (id: string, companyId: string, update: any) => Promise<any>;
  updateMemberPassword: (id: string, companyId: string, update: any) => Promise<any>;
  removeMember: (id: string, companyId: string) => Promise<any>;
};

export const useMembersStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      members: [],
      getAll: async (id: string) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllMember(id);
        const data = (res?.data ?? []) as Array<MemberModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.members = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      newMember: async (data) => {
        const state = get();
        const res = await newMember(data);
        if (res.success) {
          await state.getAll(data.member_company);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateMember: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateMember(id, update);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateMemberPassword: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateMemberPassword(id, update);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      removeMember: async (id: string, companyId) => {
        const state = get();
        const res = await removeMember(id);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
