import React from "react";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { member } = useAuthStore();
  const company = member?.member_company;
  function goSettings() {
    navigate("settings");
  }
  return (
    <div className="flex flex-row gap-3 bg-white rounded-md p-4 items-center">
      <div
        onClick={goSettings}
        className="flex min-w-[70px] max-w-[200px] h-[60px] overflow-hidden select-none"
      >
        {!!company?.company_logo?.link ? (
          <div className="flex flex-1">
            <img
              className="flex flex-1 object-contain"
              src={company.company_logo?.link}
              alt="company_logo"
            />
          </div>
        ) : (
          <div className="flex flex-1 rounded-full border bg-[rgba(150,150,150,.8)] justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="w-[50px] h-[50px]"
            >
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-[2px] leading-tight">
        <span className="flex font-semibold text-black text-[18px]">
          {company?.company_name}
        </span>
        <span
          onClick={goSettings}
          className="flex font-medium text-black hover:text-primary hover:underline hover:underline-offset-2 cursor-pointer text-[13px]"
        >
          Acronyme: {company?.company_acronym}
        </span>
      </div>
    </div>
  );
};

export default Header;
