import React from "react";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { primaryColor } from "../../../../constants";

type Props = {
  update: (coords: { lon: number; lat: number; zoom: number }) => void;
  coords: { lon: number; lat: number; zoom: number } | null;
};

const ManageLocalityWithMap = ({ update, coords }: Props) => {
  return (
    <div
      style={{
        height: 300,
      }}
      className="flex w-[98.5%] h-auto relative"
    >
      <ReactMapGL
        initialViewState={
          coords
            ? {
                longitude: coords.lon,
                latitude: coords.lat,
                zoom: coords.zoom,
              }
            : undefined
        }
        onMove={(e) =>
          update({
            lat: e.target.getCenter().lat,
            lon: e.target.getCenter().lng,
            zoom: e.target.getZoom(),
          })
        }
        onZoom={(e) =>
          update({
            lat: e.target.getCenter().lat,
            lon: e.target.getCenter().lng,
            zoom: e.target.getZoom(),
          })
        }
        onClick={(e) => {
          e.target.setCenter(e.lngLat);
          update({
            lat: e.lngLat.lat,
            lon: e.lngLat.lng,
            zoom: e.target.getZoom(),
          });
        }}
        style={{ width: "100%", height: "100%", borderRadius: 8 }}
        mapboxAccessToken={process.env.REACT_APP_MapboxAccessToken}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        attributionControl={false}
      >
        {coords && (
          <Marker
            color={primaryColor}
            longitude={coords.lon}
            latitude={coords.lat}
            anchor="bottom"
          />
        )}
        <NavigationControl />
      </ReactMapGL>
      <div className="flex absolute z-10 px-3 mt-2 ml-2 rounded-md py-1 bg-white">
        <span className="flex text-[15px] font-medium">
          Géolocaliser la gare
        </span>
      </div>
    </div>
  );
};

export default ManageLocalityWithMap;
