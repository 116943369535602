import { createContext } from "react";

export const EmailVerifContext = createContext({
  // company_email: "",
  isLoading: false,
  isConfirmationLoading: false,
  confirmCode: function (code: string) {},
  confirmationError: false,
  reinitialiseError: function () {},
});
