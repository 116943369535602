import React from "react";
import TableDataAutoList from "../../../../components/array";
import { useStationStore } from "../../../../configs/stores/station";
import StationActions from "./actions";
import { StationModel } from "../../../../models/models";
import StationUpdateLocation from "../../../../components/modals/models/station-location";

const GaresTable = () => {
  const { stations } = useStationStore();
  const columns = [
    {
      label: "Nom",
      slug: "name",
      maxWidth: "300px",
    },
    {
      label: "Localite",
      slug: "locality",
      isComponent: true,
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        actions={StationActions}
        rows={stations.map(function (station) {
          return {
            station,
            name: station.station_name,
            locality: <LocalityValidator station={station} />,
          };
        })}
      />
    </div>
  );
};

type LocalityValidatorProps = {
  station: StationModel;
};
function LocalityValidator({ station }: LocalityValidatorProps) {
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  const isValidated = !!station?.station_locality;
  return (
    <React.Fragment>
      <div onClick={()=>setisOpen(true)} className="group flex flex-row gap-3 w-fit items-center cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-6 h-6 fill-primary group-hover:fill-primary/80"
        >
          <path
            fillRule="evenodd"
            d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            clipRule="evenodd"
          />
        </svg>
        {isValidated ? (
          <span className="line-clamp-1 text-[17px]">
            {station?.station_locality}
          </span>
        ) : (
          <span className="line-clamp-1 underline underline-offset-2 font-medium text-[16px] text-primary group-hover:text-primary/80">
            Ajouter la localisation
          </span>
        )}
      </div>
      <StationUpdateLocation
        station={station}
        open={isOpen}
        handleClose={close}
      />
    </React.Fragment>
  );
}

export default GaresTable;
