import { createContext } from "react";
import { GareObject } from "../../types/models";

export const CreateTrajetContext = createContext({
  isLoading: false,
  formData: {
    trajet_type: "local",
    voyage_type: "normal",
    vehicule_type: "bus",
    gare_start: GareObject,
    gare_end: GareObject,
    trajet_price: "",
    troncon: "",
    reccurence_type: "everyday",
    reccurence_data: Array<{ id: string; data: any }>(),
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  addNewDepart: function (data: any) {},
  updateDepart: function (data: any) {},
  removeDepart: function (id: string) {},
  errorState: {
    gare_start: { state: false, msg: "" },
    gare_end: { state: false, msg: "" },
    trajet_price: { state: false, msg: "" },
    troncon: { state: false, msg: "" },
    reccurence_type: { state: false, msg: "" },
    reccurence_data: { state: false, msg: "" },
    generalMsg: "",
  },
});
