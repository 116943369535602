import React from "react";
import { CreateTrajetContext } from "./context";
import { useAuthHook } from "../auth/hooks";
import { useModalHook } from "../../functions/useModalHook";
import { useAuthDataHook } from "../authData/hooks";
import { GareObject } from "../../types/models";
import { getPropValue } from "../../functions/getPropValue";
import { sendValidData } from "./functions";

const CreateTrajetProvider = ({ children = <div /> }) => {
  const { company } = useAuthHook();
  const { refreshTrajets } = useAuthDataHook();
  const { closeModal } = useModalHook();
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    trajet_type: "local",
    voyage_type: "normal",
    vehicule_type: "bus",
    gare_start: GareObject,
    gare_end: GareObject,
    trajet_price: "",
    troncon: "",
    trajet_duration: "",
    reccurence_type: "every_day",
    reccurence_data: Array<{ id: string; data: any }>(),
  });
  const [errorState, setErrorState] = React.useState({
    gare_start: { state: false, msg: "" },
    gare_end: { state: false, msg: "" },
    trajet_price: { state: false, msg: "" },
    trajet_duration: { state: false, msg: "" },
    reccurence_type: { state: false, msg: "" },
    reccurence_data: { state: false, msg: "" },
    troncon: { state: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // gare_start
    if (formData.gare_start.gare_id.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, gare_start: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, gare_start: { state: false, msg: "" } }));
    }
    // gare_end
    if (formData.gare_end.gare_id.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, gare_end: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, gare_end: { state: false, msg: "" } }));
    }
    // trajet_price
    if (formData.trajet_price.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, trajet_price: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, trajet_price: { state: false, msg: "" } }));
    }
    // reccurence_type
    if (formData.reccurence_type.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, reccurence_type: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        reccurence_type: { state: false, msg: "" },
      }));
    }
    // reccurence_type
    if (formData.troncon.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, troncon: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        troncon: { state: false, msg: "" },
      }));
    }
    // reccurence_data
    if (
      formData.reccurence_data.length === 0 ||
      formData.reccurence_type.length === 0
    ) {
      errorOccured = true;
      let msg = "Veuiller ajouter au minimum un ou plusieurs departs !";
      setErrorState((e) => ({ ...e, reccurence_data: { state: true, msg } }));
    } else {
      if (
        formData.reccurence_type === "everyday" &&
        formData.reccurence_data.filter(
          (e) => !!!e.data.value?.start || !!!e.data.value?.end
        ).length !== 0
      ) {
        errorOccured = true;
        let msg = "Renseigner correctement les departs !";
        setErrorState((e) => ({ ...e, reccurence_data: { state: true, msg } }));
      } else if (
        formData.reccurence_type === "perweek" &&
        formData.reccurence_data.filter(
          (e) =>
            !!!e.data.value?.day ||
            !!!e.data.value?.start ||
            !!!e.data.value?.end
        ).length !== 0
      ) {
        errorOccured = true;
        let msg = "Renseigner correctement les departs !";
        setErrorState((e) => ({ ...e, reccurence_data: { state: true, msg } }));
      } else if (
        formData.reccurence_type === "permonth" &&
        formData.reccurence_data.filter(
          (e) =>
            !!!e.data.value?.month ||
            !!!e.data.value?.day ||
            !!!e.data.value?.start ||
            !!!e.data.value?.end
        ).length !== 0
      ) {
        errorOccured = true;
        let msg = "Renseigner correctement les departs !";
        setErrorState((e) => ({ ...e, reccurence_data: { state: true, msg } }));
      } else {
        setErrorState((e) => ({
          ...e,
          reccurence_data: { state: false, msg: "" },
        }));
      }
    }
    return errorOccured;
  }

  function submitForm() {
    console.log(formData);
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    setisLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendValidData(
      {
        company_id: company.company_id,
        trajet_type: formData.trajet_type,
        trajet_voyage_type: formData.voyage_type,
        trajet_vehicule_type: formData.vehicule_type,
        gare_start: formData.gare_start.gare_id,
        gare_end: formData.gare_end.gare_id,
        trajet_price: formData.trajet_price,
        trajet_troncon: formData.troncon,
        trajet_duration: formData.trajet_duration,
        trajet_reccurence_type: formData.reccurence_type,
        trajet_reccurence_data: formData.reccurence_data,
      },
      function (reponse) {
        setisLoading(false);
        if (reponse.success) {
          refreshTrajets();
          closeModal();
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 7000);
          setErrorState((e) => ({
            ...e,
            generalMsg: reponse.msg,
          }));
        }
      }
    );
  }

  function addNewDepart(data: any) {
    setErrorState((e) => ({
      ...e,
      reccurence_data: { state: false, msg: "" },
    }));
    setFormData((e) => ({
      ...e,
      reccurence_data: e.reccurence_data.concat(data),
    }));
  }

  function updateDepart(data: any) {
    setErrorState((e) => ({
      ...e,
      reccurence_data: { state: false, msg: "" },
    }));
    setFormData((e) => ({
      ...e,
      reccurence_data: e.reccurence_data.map(function (dt) {
        return dt.id === data.id ? data : dt;
      }),
    }));
  }

  function removeDepart(id: string) {
    setFormData((e) => ({
      ...e,
      reccurence_data: e.reccurence_data.filter(
        (depart: any) => depart.id !== id
      ),
    }));
  }

  React.useEffect(() => {
    setFormData((e) => ({ ...e, reccurence_data: [] }));
  }, [formData.reccurence_type]);

  return (
    <CreateTrajetContext.Provider
      value={{
        isLoading,
        formData,
        submitForm,
        errorState,
        onValueChange,
        addNewDepart,
        removeDepart,
        updateDepart,
      }}
    >
      {children}
    </CreateTrajetContext.Provider>
  );
};

export default CreateTrajetProvider;
