import React from "react";
import TravelChildrenUpdate from "../../../../components/modals/models/travel-children-update";

type TravelsActionsProps = {
  row: any;
  rowC: any;
};

const TravelsChildrenActions = ({ row, rowC }: TravelsActionsProps) => {
  const [isOpenUpdate, setisOpenUpdate] = React.useState(false);
  function closeUpdate() {
    setisOpenUpdate(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-1">
        <TravelChildrenAction
          title={"Modifier"}
          onClick={() => setisOpenUpdate(true)}
        />
      </div>
      <TravelChildrenUpdate
        travel={row.travel}
        travelTrip={rowC.trip}
        open={isOpenUpdate}
        handleClose={closeUpdate}
      />
    </React.Fragment>
  );
};

type TravelActionProps = {
  title: string;
  onClick: () => void;
};

const TravelChildrenAction = ({ title, onClick }: TravelActionProps) => {
  return (
    <button
      onClick={onClick}
      className="flex pl-3 pr-1 py-2 hover:bg-slate-200/40 active:text-primary"
    >
      <span className="flex font-medium">{title}</span>
    </button>
  );
};

export default TravelsChildrenActions;
