import React from "react";
import UpdateCompanyData from "./updateCompanyData";

const SettingsPage = () => {
  return (
    <div className="flex flex-1 flex-col gap-5 pt-5">
      <span className="flex text-[22px] font-medium">Paramètres</span>
      <UpdateCompanyData />
    </div>
  );
};

export default SettingsPage;
