import React from "react";
import { StationModel } from "../../../../models/models";
import clsx from "clsx";
import NumberInput from "../../../inputs/number";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SelectDropDown from "./select";
import {
  TravelFrequencyLabel,
  TravelVehiculeLabel,
  TravelZoneLabel,
} from "../../../../configs/stores/travel";
import EveryDay from "./frequencyComponents/everyDay";
import PerWeek from "./frequencyComponents/perWeek";
import PerMonth from "./frequencyComponents/perMonth";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { primaryColor } from "../../../../constants";
import { useAuthStore } from "../../../../configs/stores/auth";

export type TravelItem = {
  id: string;
  number_from: number;
  number_to: number;
  active: boolean;
  form: {
    travel_from: StationModel | null;
    travel_to: StationModel | null;
    travel_cost: string;
    travel_duration: string;
    travel_vehicule: string;
    travel_zone: string;
    travel_frequency: {
      type: string;
      data: Array<{
        id: string;
        number: number;
        day: string;
        hour: string;
      }>;
    };
  };
  [x: string]: any;
};

type Props = {
  travelList: Array<TravelItem>;
  addNewStart: (id: string) => void;
  updateActivation: (travelId: string, update: boolean) => void;
  onTravelValueChange: (travelId: string, slug: string) => (value: any) => void;
  manageTravelFrequencyError: (
    travelId: string,
    action: string | undefined,
    id: string
  ) => void;
  errorTravelState: {
    [x: string]: {
      [x: string]: { state: boolean; msg: string };
    };
  };
  errorTravelFrequencyState: {
    [x: string]: {
      [x: string]: { state: boolean; msg: string };
    };
  };
};

const TravetList = ({
  travelList,
  addNewStart,
  updateActivation,
  onTravelValueChange,
  manageTravelFrequencyError,
  errorTravelState,
  errorTravelFrequencyState,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        className={clsx(
          "flex-col space-y-3 p-4 h-[65vh] min-h-[400px] w-[500px] border border-black/10 rounded-lg overflow-y-auto",
          "scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60"
        )}
      >
        {travelList
          .sort((a, b) => {
            return a.number_from - b.number_from;
          })
          .map((travel) => {
            return (
              <Travel
                key={travel.id}
                travel={travel}
                updateActivation={updateActivation}
                manageTravelFrequencyError={manageTravelFrequencyError}
                addNewStart={addNewStart}
                onTravelValueChange={onTravelValueChange}
                errorTravelState={errorTravelState[travel.id]}
                errorTravelFrequencyState={errorTravelFrequencyState[travel.id]}
              />
            );
          })}
      </div>
    </LocalizationProvider>
  );
};

type TravelItemProps = {
  travel: TravelItem;
  errorTravelState: {
    [x: string]: { state: boolean; msg: string };
  };
  errorTravelFrequencyState: {
    [x: string]: { state: boolean; msg: string };
  };
  addNewStart: (id: string) => void;
  updateActivation: (travelId: string, update: boolean) => void;
  onTravelValueChange: (travelId: string, slug: string) => (value: any) => void;
  manageTravelFrequencyError: (
    travelId: string,
    action: string | undefined,
    id: string
  ) => void;
};

function Travel({
  travel,
  addNewStart,
  updateActivation,
  onTravelValueChange,
  manageTravelFrequencyError,
  errorTravelState,
  errorTravelFrequencyState,
}: TravelItemProps) {
  const [isOpen, setisOpen] = React.useState(false);
  const [defaultChecked] = React.useState(travel.active);
  const { member } = useAuthStore();
  const company = member?.member_company;
  function calculateSellingPrice(cost: string) {
    const a = company?.company_contrat.royality ?? 0;
    const x = parseInt(cost);
    const b = company?.company_contrat.charge ?? 0;
    const y = a * x + x + b;
    return y.toFixed(0).toString();
  }
  return (
    <div className="flex flex-col rounded-lg overflow-hidden border border-black/10">
      <div className="flex flex-row w-full">
        <div
          className={clsx(
            "flex flex-col w-[45px] items-center justify-center duration-150 transition-all",
            travel.active ? "bg-primary" : "bg-red-500"
          )}
        >
          {travel.active ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 fill-white"
            >
              <path
                fillRule="evenodd"
                d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 fill-red-600"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        <div
          onClick={() => setisOpen(!isOpen)}
          className="flex flex-1 flex-row justify-between items-center gap-2 cursor-pointer duration-150 transition-all bg-black/10 hover:bg-black/15 active:bg-black/20"
        >
          <div className="flex flex-col border-l-4 pl-2 gap-[2px] leading-none my-[10px]">
            <span className="line-clamp-1 text-[14px]">
              Depart - {travel.number_from}:{" "}
              {travel.form.travel_from?.station_name ?? "Vide"}
            </span>
            <span className="line-clamp-1 text-[14px]">
              Arriver - {travel.number_to}:{" "}
              {travel.form.travel_to?.station_name ?? "Vide"}
            </span>
          </div>
          <div className="flex px-3 h-full justify-center rounded-lg items-center">
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "flex flex-col p-3 gap-3 overflow-hidden duration-150 transition-all overflow-y-auto",
          isOpen ? "h-auto" : "py-0 h-[0px]"
        )}
      >
        <div
          className={clsx(
            "flex flex-row px-2 py-2 gap-2 items-center rounded-md border border-black/20 text-black",
            travel.active ? "hover:bg-black/5" : "bg-red-500/10"
          )}
        >
          <Switch
            size="small"
            disableRipple
            sx={{
              m: 0,
              "& .MuiSwitch-switchBase": {
                "&.Mui-checked": {
                  "& .MuiSwitch-thumb": {
                    backgroundColor: primaryColor,
                  },
                },
                "& .MuiSwitch-thumb": {
                  backgroundColor: "red",
                },
              },
            }}
            defaultChecked={defaultChecked}
            onChange={(e, checked) => {
              updateActivation(travel.id, checked);
            }}
          />
          <div className="flex select-none text-[16px] pt-[3px] pb-[4px] leading-none justify-center">
            {travel.active ? "Ce voyage est: Actif" : "Ce voyage est: Inactif"}
          </div>
        </div>
        <div className="flex flex-col gap-[6px] mb-1">
          <NumberInput
            label={"Cout du voyage"}
            placeholder={"Exemple: 2000"}
            defaultValue={travel.form.travel_cost}
            errorState={errorTravelState.travel_cost}
            onChange={onTravelValueChange(travel.id, "travel_cost")}
            size={"small"}
            InputProps={{
              endAdornment: <span>Frcfa</span>,
            }}
          />
          {travel.form.travel_cost && (
            <span className="flex ml-3 text-[14px] text-primary">
              Prix du ticket: {calculateSellingPrice(travel.form.travel_cost)}{" "}
              Frcfa
            </span>
          )}
        </div>
        <DateTimeField
          label={"Durée Moyenne du voyage"}
          value={travel.form.travel_duration ?? undefined}
          color={"warning"}
          format="HH:mm"
          size={"small"}
          InputProps={{
            endAdornment: <span>Heure:Minutes</span>,
          }}
          {...{ error: errorTravelState.travel_duration.state }}
          onChange={(newValue: any) => {
            const hour = moment(newValue).format("HH:mm");
            if (hour !== "Invalid date") {
              onTravelValueChange(travel.id, "travel_duration")(hour);
            } else {
              onTravelValueChange(travel.id, "travel_duration")(null);
            }
          }}
        />
        <div className="flex flex-row gap-3">
          <div className="flex flex-col flex-1">
            <SelectDropDown
              label={"Zone"}
              defaultValue={travel.form.travel_zone}
              error={errorTravelState.travel_zone.state}
              options={TravelZoneLabel}
              onChange={onTravelValueChange(travel.id, "travel_zone")}
            />
          </div>
          <div className="flex flex-col flex-1">
            <SelectDropDown
              label={"Vehicule"}
              defaultValue={travel.form.travel_vehicule}
              error={errorTravelState.travel_vehicule.state}
              options={TravelVehiculeLabel}
              onChange={onTravelValueChange(travel.id, "travel_vehicule")}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div className="flex flex-col flex-1">
              <SelectDropDown
                label={"Fréquence de departs"}
                defaultValue={travel.form.travel_frequency.type}
                error={errorTravelState.travel_frequency.state}
                options={TravelFrequencyLabel.map((e) => ({
                  label: e.label,
                  value: e.type,
                }))}
                onChange={function (value) {
                  onTravelValueChange(
                    travel.id,
                    "travel_frequency"
                  )({
                    type: value,
                    data: [],
                  });
                }}
              />
            </div>
            <div className="flex flex-col w-[35%]">
              <button
                onClick={() => addNewStart(travel.id)}
                className={`flex w-full h-full ${
                  errorTravelState.travel_frequency.state
                    ? "border-red-500 hover:border-red-500 active:border-red-500 text-red-500"
                    : "border-black/20 hover:border-black/90 active:border-active text-black"
                } rounded-[4px] flex-row gap-1 items-center justify-center hover:bg-gainsboro/20 border `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-[14px] h-[14px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span className="flex text-[15px] mb-[2px] text-center">
                  Ajouter départ
                </span>
              </button>
            </div>
          </div>
          {travel.form.travel_frequency.data.length !== 0 && (
            <div className="flex flex-col w-full">
              <SwichFrequency
                type={travel.form.travel_frequency.type}
                formData={travel.form}
                onValueChange={(slug) => {
                  return onTravelValueChange(travel.id, slug);
                }}
                manageError={(...args) => {
                  manageTravelFrequencyError(travel.id, ...args);
                }}
                errorState={errorTravelFrequencyState}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

type SwichFrequencyProps = {
  type: string;
  formData: any;
  onValueChange: (slug: string) => (value: any) => void;
  errorState: {
    [x: string]: { state: boolean; msg: string };
  };
  manageError: (action: string | undefined, id: string) => void;
};
function SwichFrequency({
  type,
  formData,
  onValueChange,
  errorState,
  manageError,
}: SwichFrequencyProps) {
  switch (type) {
    case "every_day":
      return (
        <EveryDay
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );
    case "per_week":
      return (
        <PerWeek
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );
    case "per_month":
      return (
        <PerMonth
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );

    default:
      return (
        <EveryDay
          formData={formData}
          onValueChange={onValueChange}
          errorState={errorState}
          manageError={manageError}
        />
      );
  }
}

export default TravetList;
