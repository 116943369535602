import React from "react";
import ModalCustom from "../..";
import Icon from "../../../convenience-icons/icon";
import { useConveniencesStore } from "../../../../configs/stores/conveniences";
import { primaryColor } from "../../../../constants";
import { ConvenienceModel, StationModel } from "../../../../models/models";
import { useStationStore } from "../../../../configs/stores/station";
import clsx from "clsx";

type Props = {
  handleClose: () => void;
  open: boolean;
  station: StationModel;
  closeOnFinsh?: boolean;
};

const ConvenienceAddModal = ({
  handleClose = () => {},
  open = false,
  closeOnFinsh = true,
  station,
}: Props) => {
  const [closable] = React.useState(true);
  const inputTitleRef = React.useRef<HTMLTextAreaElement | any>();
  const [inputText, setinputText] = React.useState("");
  const conveniences = useConveniencesStore((e) => e.conveniences);
  const isLoading = useConveniencesStore((e) => e.isLoading);
  const getAllConveniences = useConveniencesStore((e) => e.getAll);
  function refresh() {
    getAllConveniences();
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col p-1 pb-2 px-2 pt-0 space-y-4 items-center justify-evenly">
        <span className="flex text-[20px] font-medium">
          Ajouter de nouvelles Commodités
        </span>
        <div className="flex flex-row max-h-[65vh] min-h-[300px] gap-2 w-[550px] max-w-[65vw]">
          <div className="flex flex-1 flex-col gap-3">
            <div className="flex flex-row gap-3">
              <input
                ref={inputTitleRef}
                type="text"
                placeholder={"Rechercher"}
                onChange={(e) => {
                  setinputText(e.target.value ?? "");
                }}
                className={`flex flex-1 transition-all duration-150 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary border-slate-300 outline-none focus:border-primary font-medium text-[19px] py-[10px] bg-slate-100`}
              />
              <button
                onClick={refresh}
                className="flex px-4 py-[6px] gap-2 flex-row items-center rounded-md bg-primary text-white hover:bg-hover active:bg-active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className={clsx("w-6 h-6", isLoading && "animate-spin")}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                <span className="flex text-[16px]">Rafraishir la liste</span>
              </button>
            </div>
            <div className="flex flex-1 flex-col overflow-y-scroll pl-3 gap-2">
              {conveniences
                .filter((e) => e.convenience_active)
                .filter(
                  (e) =>
                    !station.station_conveniences
                      .map((v) => v.convenience_id)
                      .includes(e.convenience_id)
                )
                .filter((e) => {
                  if (!inputText) return true;
                  return (
                    e.convenience_name.toLowerCase().includes(inputText) ||
                    e.convenience_title.toLowerCase().includes(inputText) ||
                    e.convenience_description.toLowerCase().includes(inputText)
                  );
                })
                .map((e, index) => {
                  return (
                    <ConvenienceItem
                      key={index}
                      item={e}
                      station={station}
                      color="red"
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </ModalCustom>
  );
};

type ConvenienceItemProps = {
  color: string;
  item: ConvenienceModel;
  station: StationModel;
};

function ConvenienceItem({ station, item }: ConvenienceItemProps) {
  const updateStationConveniences = useStationStore(
    (e) => e.updateStationConveniences
  );
  const [isUpdating, setisUpdating] = React.useState(false);
  function Update() {
    setisUpdating(true);
    updateStationConveniences(
      station.station_id,
      station.station_company.company_id,
      {
        action: "add",
        convenience: item.convenience_id,
      }
    ).then((res) => {
      setisUpdating(false);
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-row gap-3 items-start">
        <Icon name={item.convenience_name} color={primaryColor} size={40} />
        <div className="flex flex-col gap-[2px] pt-1 leading-tight">
          <span className="flex text-[16px] font-semibold">
            {item.convenience_title}
          </span>
          <span className="flex text-[15px] font-medium">
            {item.convenience_description}
          </span>
          <div className="flex flex-row gap-3 text-[13px] font-medium text-primary italic">
            <span onClick={Update} className="flex cursor-pointer">
              {isUpdating ? "Chargement..." : "Ajouter"}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConvenienceAddModal;
