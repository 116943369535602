import { createContext } from "react";

export const CreateTronconContext = createContext({
  isLoading: false,
  formData: {
    troncon_locality_from: "",
    troncon_locality_to: "",
    troncon_start: "",
    troncon_vehicule: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    troncon_locality_from: { state: false, msg: "" },
    troncon_locality_to: { state: false, msg: "" },
    troncon_start: { state: false, msg: "" },
    troncon_vehicule: { state: false, msg: "" },
    generalMsg: "",
  },
});
