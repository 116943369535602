import axios from "axios";
// import { AxiosGetUrl } from "../../../../functions/axiosfunctions";
import queryString from "query-string";

export function GetAddressDataFromOSMrEVERSE(
  { latitude = 0, longitude = 0 },
  cb = (reponse: any) => {}
) {
  axios
    .get(
      `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&addressdetails=1&format=json`
    )
    .then(function (reponse) {
      if (reponse.data) {
        cb && cb(reponse.data);
      }
    });
}

export function VerfiySavedLocation(
  { ...args },
  cb = function (reponse: any) {}
) {
  // AxiosGetUrl(
  //   "/company/gare/geolocation/verify?" + queryString.stringify(args),
  //   (reponse) => {
  //     if (reponse) {
  //       cb(reponse);
  //     } else {
  //       cb({
  //         success: false,
  //         data: {},
  //         msg: "Erreur survenue !",
  //       });
  //     }
  //   }
  // );
}
