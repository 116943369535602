import clsx from "clsx";
import React from "react";

type Props = {
  refresh?: () => void | Promise<any>;
  isLoading?: boolean;
  title?: string;
};

const RefreshButton = ({
  refresh,
  isLoading = false,
  title = "Rafraichir",
}: Props) => {
  return (
    <button
      onClick={refresh}
      className="flex px-4 py-[10.5px] gap-2 items-center flex-row rounded-md bg-primary text-white hover:bg-hover active:bg-active"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="currentColor"
        className={clsx("w-6 h-6", isLoading && "animate-spin")}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
      <span className="flex text-[16px]">{title}</span>
    </button>
  );
};

export default RefreshButton;
