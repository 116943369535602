import React from "react";
import TextInput from "../../../../components/inputs/input";
import { useCreateGareHook } from "../../../createGare/hooks";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { primaryColor } from "../../../../constants";
import QRCode from "qrcode.react";
import PhoneInput from "../../../../components/inputs/phone";
import { VerfiySavedLocation } from "./functions";
import mongoose from "mongoose";

const Form = () => {
  const {
    isLoading,
    errorState,
    onValueChange,
    setVerificationValues,
    formData: { gare_name, radioButtonValue },
    submitFormSendLink,
  } = useCreateGareHook();
  const [Uid] = React.useState(new mongoose.Types.ObjectId());
  const [isLoadingVerification, setIsLoadingVerification] =
    React.useState(false);
  const [locationData, setLocationData] = React.useState({
    success: true,
    msg: "Scanner le QR code, suivez le lien, localisez-vous dans l'enceinte de la gare puis verifier.",
  });
  return (
    <div className="flex gap-4 gap-x-1 flex-col w-full px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      <div className="flex flex-col w-full">
        <TextInput
          label={"Nom de la gare"}
          onChange={onValueChange("gare_name")}
          errorState={errorState.gare_name}
        />
      </div>
      <div className="flex flex-col w-full gap-1">
        <span className="flex font-medium">Scanner pour localiser la gare</span>
        <span className="flex font-normal text-[12px]">
          La geolocalistion de la gare se fait uniquement avec un telephone
          connecter a internet
        </span>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-4">
            <div className="flex h-fit relative">
              <QRCode
                size={160}
                value={
                  gare_name.length !== 0 ||
                  radioButtonValue === "current_position"
                    ? "https://location.lesvoyageurs.ci/geolocation/check?id=" +
                      Uid +
                      "&name=" +
                      gare_name
                    : radioButtonValue === "current_position"
                    ? "Envoie de lien de localisation"
                    : "Veuillez entrer le nom de la gare"
                }
              />

              {gare_name.length === 0 &&
                radioButtonValue === "current_position" && (
                  <div className="flex absolute flex-1 backdrop-blur-[2px] w-full h-full bg-black/60 justify-center items-center">
                    <span className="flex text-white w-[70%] text-center text-[16px]">
                      Veuillez entrer le nom de la gare
                    </span>
                  </div>
                )}
              {radioButtonValue !== "current_position" && (
                <div className="flex absolute flex-1 w-full h-full backdrop-blur-[2.5px] bg-black/60 justify-center items-center">
                  <div className="flex p-3 shadow-sm shadow-white/70 bg-white justify-center items-center rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="coral"
                      className="w-10 h-10 -rotate-45 ml-1 mb-1"
                    >
                      <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className="flex -mt-2">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={radioButtonValue}
                  onChange={function (_, value) {
                    onValueChange("radioButtonValue")(value);
                  }}
                >
                  <FormControlLabel
                    value="current_position"
                    control={
                      <Radio
                        defaultChecked
                        sx={{
                          color: primaryColor,
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label="Position actuelle"
                  />
                  <div
                    className={`flex ml-8 text-[14px] -mt-2 ${
                      locationData.success ? "text-black" : "text-red-500"
                    }`}
                  >
                    {locationData.msg}
                  </div>
                  <button
                    style={{
                      display:
                        radioButtonValue === "current_position"
                          ? "flex"
                          : "none",
                    }}
                    disabled={gare_name.length === 0}
                    onClick={function () {
                      setIsLoadingVerification(true);
                      VerfiySavedLocation({ id: Uid }, (reponse) => {
                        setIsLoadingVerification(false);
                        if (reponse.success) {
                          setVerificationValues({
                            gare_id: Uid,
                            gare_geocoords: reponse.data.location_coords,
                            gare_locality: reponse.data.locality_id,
                          });
                          setLocationData({
                            success: true,
                            msg: reponse.data.location_display_name,
                          });
                        } else {
                          setLocationData({
                            success: false,
                            msg: "Localisation introuvable, relancer la verification ou rescanner le QR code",
                          });
                        }
                      });
                    }}
                    className="flex px-4 py-[6px] ml-8 mt-2 text-coral bg-coral/5 hover:bg-hover/25 active:bg-coral/40 disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20 rounded-md"
                  >
                    {!isLoadingVerification ? (
                      <span className="flex text-[15px]">
                        Verifier la localisation
                      </span>
                    ) : (
                      <svg
                        className="animate-spin h-5 w-5 text-black/50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={"transparent"}
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke={primaryColor}
                          strokeWidth="2"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill={primaryColor}
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                  <FormControlLabel
                    value="send_localisation_link"
                    control={
                      <Radio
                        sx={{
                          color: primaryColor,
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label="Envoyer un lien pour localiser la gare"
                  />
                  <div
                    style={{
                      display:
                        radioButtonValue === "send_localisation_link"
                          ? "flex"
                          : "none",
                    }}
                    className="flex ml-7 mt-1 flex-row gap-3"
                  >
                    <PhoneInput
                      label="Numero de telephone"
                      placeholder="00 00 00 00 00"
                      countryprefix={
                        <input
                          className="flex outline-none border-none w-[35px] mt-[1px] text-[15px] px-0 mx-0"
                          placeholder="+000"
                          maxLength={5}
                          onChange={function (txt) {
                            onValueChange("prefix")(txt.currentTarget.value);
                          }}
                        />
                      }
                      errorState={errorState.phone}
                      onChange={onValueChange("phone")}
                      size={"small"}
                    />
                    <button
                      onClick={!isLoading ? submitFormSendLink : undefined}
                      disabled={gare_name.length === 0}
                      className={`flex transition-all px-3 py-2 h-fit items-center justify-center ${
                        radioButtonValue !== "current_position"
                          ? "bg-coral"
                          : "bg-black/30"
                      } hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20`}
                    >
                      {!isLoading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="animate-spin h-5 w-5 text-black/50"
                          xmlns="http://www.w3.org/2000/svg"
                          fill={"transparent"}
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke={"white"}
                            strokeWidth="2"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill={"white"}
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                    </button>
                  </div>
                  {gare_name.length === 0 && (
                    <span
                      style={{
                        display:
                          radioButtonValue === "send_localisation_link"
                            ? "flex"
                            : "none",
                      }}
                      className={`flex ml-7 mt-1 text-[14px] font-normal text-red-500`}
                    >
                      Veuillez entrer le nom de la gare
                    </span>
                  )}
                  {radioButtonValue !== "send_localisation_link" && (
                    <span className={`flex ml-8 -mt-2 text-[14px] font-normal`}>
                      L'agent recevra un SMS contenant le lien
                    </span>
                  )}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
