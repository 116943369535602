import React from "react";
import { ANY, primaryColor } from "../../../../../constants";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AutocompleteSelectInput from "../../../../../components/inputs/autoCompleteSelect";
import { LocalizationProvider, DateTimeField } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCreateTrajetHook } from "../../../../createTrajet/hooks";

const WeekMomentItem = ({
  item = { id: "", data: ANY },
  numeroDepart = 0,
  remove = () => {},
}) => {
  const { updateDepart } = useCreateTrajetHook();
  const [
    dateFieldDateTimePickerValueStart,
    setDateFieldDateTimePickerValueStart,
  ] = React.useState<string | null>(null);
  const [dateFieldDateTimePickerValueEnd, setDateFieldDateTimePickerValueEnd] =
    React.useState<string | null>(null);
  const [selected, setSelected] = React.useState({
    label: "",
    value: "",
  });
  const opts = [
    { label: "Lundi", value: "monday" },
    { label: "Mardi", value: "tuesday" },
    { label: "Mercredi", value: "wednesday" },
    { label: "Jeudi", value: "thirsday" },
    { label: "Vendredi", value: "friday" },
    { label: "Samedi", value: "saturday" },
    { label: "Dimanche", value: "sunday" },
  ];
  React.useEffect(() => {
    updateDepart({
      ...item,
      data: {
        ...item.data,
        label: "Depart N°" + numeroDepart.toString(),
        value: { day: null, start: null, end: null },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroDepart]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-row gap-3 items-center">
        <div className="flex w-full flex-col ">
          <AutocompleteSelectInput
            label="Jour de depart"
            selectedOption={selected}
            defaultOption={{ label: "", value: "" }}
            options={opts}
            onChange={function (val) {
              setSelected(opts.filter((opt) => opt.value === val)[0]);
              updateDepart({
                ...item,
                data: {
                  ...item.data,
                  value: {
                    ...item.data.value,
                    day: val,
                  },
                },
              });
            }}
          />
        </div>
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Heure de depart"}
            value={dateFieldDateTimePickerValueStart}
            format="HH:mm"
            color={"warning"}
            onChange={(newValue) => {
              setDateFieldDateTimePickerValueStart(newValue);
              try {
                if (typeof newValue !== "undefined" && !!newValue) {
                  updateDepart({
                    ...item,
                    data: {
                      ...item.data,
                      value: {
                        ...item.data.value,
                        start: newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":"),
                      },
                    },
                  });
                }
              } catch (error) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      start: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Heure d'arriver"}
            value={dateFieldDateTimePickerValueEnd}
            format="HH:mm"
            color={"warning"}
            onChange={(newValue) => {
              setDateFieldDateTimePickerValueEnd(newValue);
              try {
                if (typeof newValue !== "undefined" && !!newValue) {
                  updateDepart({
                    ...item,
                    data: {
                      ...item.data,
                      value: {
                        ...item.data.value,
                        end: newValue
                          .toString()
                          .split(" ")
                          .filter((str) => str.includes(":"))[0]
                          .split(":")
                          .slice(0, 2)
                          .join(":"),
                      },
                    },
                  });
                }
              } catch (error) {
                updateDepart({
                  ...item,
                  data: {
                    ...item.data,
                    value: {
                      ...item.data?.value,
                      end: null,
                    },
                  },
                });
              }
            }}
          />
        </div>
        <IconButton aria-label="remove item" color={"warning"} onClick={remove}>
          <RemoveIcon htmlColor={primaryColor} />
        </IconButton>
      </div>
    </LocalizationProvider>
  );
};

export default WeekMomentItem;
