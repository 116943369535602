import React from "react";
import ModalCustom from "../..";
import QRCode from "qrcode.react";
import { useStationStore } from "../../../../configs/stores/station";
import { StationModel } from "../../../../models/models";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  ToastWarnNotifier,
  isPhoneNumber,
  isPrefixPhoneNumber,
  primaryColor,
} from "../../../../constants";
import PhoneInput from "../../../inputs/phone";
import { useAuthStore } from "../../../../configs/stores/auth";
import { clientGeolocationUrl } from "../../../../configs/api";
import { encrypt } from "../../../../configs/encryption";
import moment from "moment";
import { getPropValue } from "../../../../functions/getPropValue";
import CryptoJS from "crypto-js";
import ManageLocalityWithMap from "./map";

type Props = {
  handleClose: () => void;
  open: boolean;
  station: StationModel;
  closeOnFinsh?: boolean;
};

const StationUpdateLocation = ({
  open = false,
  station,
  handleClose = () => {},
  closeOnFinsh = true,
}: Props) => {
  const { member } = useAuthStore();
  const [radioButtonValue, setRadioButtonValue] =
    React.useState("current_position");
  const [locality, setLocality] = React.useState(station.station_locality);
  const [closable] = React.useState(true);
  const { verifyStation, sendLinkStation, saveManualyLocalityStation } =
    useStationStore();
  const [isVerifying, setisVerifying] = React.useState(false);
  const [isSending, setisSending] = React.useState(false);
  const [isSavingManualy, setisSavingManualy] = React.useState(false);
  const [coords, setCoords] = React.useState<{
    lat: number;
    lon: number;
    zoom: number;
  } | null>(
    station.station_geocoords.lat !== 5.0
      ? { ...station.station_geocoords }
      : null
  );
  function updateCoords(coords: { lat: number; lon: number; zoom: number }) {
    setCoords(coords);
  }
  const [sendLinkFormData, setsendLinkFormData] = React.useState({
    prefix: "",
    phone: "",
  });
  const [sendLinkErrorState, setSendLinkErrorState] = React.useState({
    prefix: { state: false, msg: "" },
    phone: { state: false, msg: "" },
  });
  function onValueChangeSendLinkForm(slug: string) {
    return (value: any) => {
      setsendLinkFormData((f) => ({ ...sendLinkFormData, [slug]: value }));
      if (getPropValue(sendLinkErrorState, slug)) {
        setSendLinkErrorState((e) => ({
          ...e,
          [slug]: { state: false, msg: "" },
        }));
      }
    };
  }
  function VerifyStation() {
    setisVerifying(true);
    verifyStation(
      station.station_id,
      member?.member_company?.company_id ?? ""
    ).then((res) => {
      setisVerifying(false);
      if (res.success) {
        setLocality(res.data.station_locality);
      } else {
        ToastWarnNotifier({
          message: `${station.station_name} n'est pas geolocaliser !`,
        });
      }
    });
  }
  function checkSendLinkFormData() {
    let errorOccured = false;
    if (
      sendLinkFormData.prefix.length === 0 ||
      !isPrefixPhoneNumber(sendLinkFormData.prefix)
    ) {
      errorOccured = true;
      let msg = "1 a 4 chiffres autorises pour le prefix";
      setSendLinkErrorState((e) => ({ ...e, phone: { state: true, msg } }));
    } else {
      setSendLinkErrorState((e) => ({
        ...e,
        phone: { state: false, msg: "" },
      }));
    }
    if (
      sendLinkFormData.phone.length === 0 ||
      !isPhoneNumber(sendLinkFormData.phone)
    ) {
      errorOccured = true;
      let msg = "6 a 14 chiffres autorises";
      setSendLinkErrorState((e) => ({ ...e, phone: { state: true, msg } }));
    } else {
      setSendLinkErrorState((e) => ({
        ...e,
        phone: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function SendLinkStation() {
    if (checkSendLinkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisSending(true);
    sendLinkStation(station.station_id, {
      phone: sendLinkFormData.prefix + " " + sendLinkFormData.phone,
      url:
        clientGeolocationUrl +
        "/geolocation/check?token=" +
        CryptoJS.enc.Base64url.stringify(
          CryptoJS.enc.Utf8.parse(
            encrypt(
              {
                id: station.station_id,
                timestamp: moment().valueOf(),
              },
              process.env.REACT_APP_Key,
              process.env.REACT_APP_Iv
            )
          )
        ),
    }).then((res) => {
      setisSending(false);
      if (res.success) {
        setLocality(res.data.station_locality);
      }
    });
  }
  function checkManageManualyFormData() {
    let errorOccured = false;
    if (!coords) {
      errorOccured = true;
    }
    return errorOccured;
  }
  function submitManageManualyForm() {
    if (checkManageManualyFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisSavingManualy(true);
    saveManualyLocalityStation(
      station.station_id,
      member?.member_company.company_id ?? "",
      coords
    ).then((res) => {
      setisSavingManualy(false);
      if (res.success) {
        setLocality(res.data.station_locality);
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col max-h-[80vh] pb-3 px-3 gap-3 rounded-md w-[550px] ">
        <span className="flex text-hover font-semibold text-[20px]">
          Modifier la localisation
        </span>
        <span className="flex text-hover font-semibold text-[18px]">
          {station.station_name}
        </span>
        <div className="flex gap-4 gap-x-1 flex-col w-full h-full max-h-[60vh] overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={radioButtonValue}
              onChange={function (_, value) {
                setRadioButtonValue(value);
              }}
            >
              <FormControlLabel
                value="current_position"
                control={
                  <Radio
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label="Position actuelle"
              />
              <div className="flex flex-row pl-8 gap-5 mb-4">
                <QRCode
                  size={180}
                  value={
                    radioButtonValue === "current_position"
                      ? clientGeolocationUrl +
                        "/geolocation/check?token=" +
                        CryptoJS.enc.Base64url.stringify(
                          CryptoJS.enc.Utf8.parse(
                            encrypt(
                              {
                                id: station.station_id,
                                timestamp: moment().valueOf(),
                              },
                              process.env.REACT_APP_Key,
                              process.env.REACT_APP_Iv
                            )
                          )
                        )
                      : "Envoie de lien de localisation"
                  }
                />
                <div className="flex flex-col h-auto flex-1 justify-between">
                  <span className="flex text-[15px]">
                    Scanner le code QR avec votre smartphone pour géolocaliser
                    votre position actuelle à la gare.
                  </span>
                  <div
                    className={`flex rounded-md p-3 py-[8px] ${
                      !!locality ? "bg-teal-100" : "bg-slate-100"
                    } `}
                  >
                    <span className="line-clamp-2 text-[13px]">
                      {!!locality
                        ? locality
                        : "Aucune localisation enregistrer pour l'instant"}
                    </span>
                  </div>
                  <button
                    disabled={radioButtonValue !== "current_position"}
                    onClick={
                      radioButtonValue === "current_position" || !isVerifying
                        ? VerifyStation
                        : undefined
                    }
                    className="flex h-[40px] items-center justify-center rounded-md bg-primary active:bg-primary hover:bg-hover disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
                  >
                    {!isVerifying ? (
                      <span className="flex text-white">Verifier</span>
                    ) : (
                      <svg
                        className="animate-spin h-5 w-5 text-black/50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={"transparent"}
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke={"white"}
                          strokeWidth="2"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill={"white"}
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <FormControlLabel
                value="send_localisation_link"
                control={
                  <Radio
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label="Envoyer un lien pour localiser la gare  (Indisponible)"
              />
              <div className="flex flex-col ml-8 pt-2 mb-2 gap-2">
                <div className="flex flex-row gap-3">
                  <PhoneInput
                    label="Numero de telephone"
                    placeholder="00 00 00 00 00"
                    countryprefix={
                      <input
                        className="flex outline-none border-none w-[35px] mt-[1px] text-[15px] px-0 mx-0"
                        placeholder="+000"
                        maxLength={5}
                        onChange={function (txt) {
                          onValueChangeSendLinkForm("prefix")(
                            txt.currentTarget.value
                          );
                        }}
                      />
                    }
                    errorState={sendLinkErrorState.phone}
                    onChange={onValueChangeSendLinkForm("phone")}
                    size={"small"}
                  />
                  <button
                    onClick={!isSending ? SendLinkStation : undefined}
                    // disabled={radioButtonValue !== "send_localisation_link"}
                    disabled={true}
                    className={`flex transition-all px-3 py-2 h-fit items-center justify-center ${
                      radioButtonValue !== "current_position"
                        ? "bg-coral"
                        : "bg-black/30"
                    } hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20`}
                  >
                    {!isSending ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="white"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="animate-spin h-5 w-5 text-black/50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={"transparent"}
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke={"white"}
                          strokeWidth="2"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill={"white"}
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
                <span className={`flex text-[14px] font-normal`}>
                  L'agent recevra un SMS contenant le lien
                </span>
              </div>
              <FormControlLabel
                value="manage_manually"
                control={
                  <Radio
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label="Configurer manuellement"
              />
              <div className="flex flex-col ml-8 mb-2 gap-3">
                <span className={`flex text-[14px] font-normal`}>
                  Entrer le nom de la localite (quartier ou commune ou ville ou
                  district) dans laquelle est situe la gare et selectionner
                  parmi les resultats, le resultat plus precis !
                </span>
                <ManageLocalityWithMap update={updateCoords} coords={coords} />
                <button
                  disabled={radioButtonValue !== "manage_manually" || !coords}
                  onClick={
                    radioButtonValue === "manage_manually" ||
                    !coords ||
                    !isSavingManualy
                      ? submitManageManualyForm
                      : undefined
                  }
                  className="flex h-[40px] w-[98.5%] items-center justify-center rounded-md bg-primary active:bg-primary hover:bg-hover disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
                >
                  {!isSavingManualy ? (
                    <span className="flex text-white">Enregistrer</span>
                  ) : (
                    <svg
                      className="animate-spin h-5 w-5 text-black/50"
                      xmlns="http://www.w3.org/2000/svg"
                      fill={"transparent"}
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke={"white"}
                        strokeWidth="2"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill={"white"}
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </ModalCustom>
  );
};

export default StationUpdateLocation;
