import React from 'react'
import PerMonthDayHourItem from './perMonth-dayHourItem';

type Props = {
  formData: any;
  onValueChange: (slug: string) => (value: any) => void;
  errorState: {
    [x: string]: { state: boolean; msg: string };
  };
  manageError: (action: string | undefined, id: string) => void;
};

const PerMonth = ({
  formData,
  onValueChange,
  manageError,
  errorState,
}: Props) => {
  function updateDepart(id: string, slug: string, value: any) {
    onValueChange("travel_frequency")({
      type: formData.travel_frequency.type,
      data: formData.travel_frequency.data.map((e: any) => {
        if (e.id === id) {
          return {
            ...e,
            [slug]: value,
          };
        }
        return e;
      }),
    });
    manageError("reinit", id);
  }
  function removeDepart(id: string) {
    onValueChange("travel_frequency")({
      type: formData.travel_frequency.type,
      data: formData.travel_frequency.data
        .map((e: any) => {
          const dt = formData.travel_frequency.data.find(
            (r: any) => r.id === id
          );
          if (e.number - dt.number >= 0) {
            return { ...e, number: e.number - 1 };
          }
          return e;
        })
        .filter((e: any) => e.id !== id),
    });
    manageError("remove", id);
  }
  return (
    <div className="flex flex-1 flex-col w-full gap-2">
      {formData.travel_frequency.data.map((depart: any, index: number) => {
        return (
          <PerMonthDayHourItem
            key={depart.id}
            depart={depart}
            error={errorState[depart.id]}
            remove={(id) => {
              removeDepart(id);
            }}
            update={updateDepart}
          />
        );
      })}
    </div>
  )
}

export default PerMonth
