import React from "react";

export function usePopUpHook<
  INPUTTYPE extends HTMLElement,
  POPUPTYPE extends HTMLElement
>() {
  const [open, setOpen] = React.useState(false);
  const refInput = React.useRef<INPUTTYPE>(null);
  const refPopUp = React.useRef<POPUPTYPE>(null);
  function switchMenu() {
    setOpen(!open);
  }
  function openM() {
    setOpen(true);
  }
  function closeM() {
    setOpen(false);
  }
  function handleClickOutside(event: any) {
    if (refInput.current && refPopUp.current && event.target) {
      if (
        !refInput.current.contains(event.target) &&
        !refPopUp.current.contains(event.target)
      ) {
        // alert("zretrt")
        setOpen(false);
      }
    }
  }
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  return { refInput, refPopUp, open, openM, closeM, switchMenu };
}
