import { useSearchParams } from "react-router-dom";

export function useQueryHook() {
  const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const query = URLSearchParams;
  function remove(name: string) {
    query.delete(name);
    setURLSearchParams(() => query);
  }
  function set(name: string, value: string) {
    query.set(name, value);
    setURLSearchParams(() => query);
  }
  function get(name: string) {
    return query.get(name);
  }
  return {
    query,
    remove,
    set,
    get,
    setURLSearchParams,
  };
}
