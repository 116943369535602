import ConveniencePage from "../../pages/dashboard/pages/convenience";
import FinancePage from "../../pages/dashboard/pages/finance";
import DashboardHome from "../../pages/dashboard/pages/home";
import PolicyPage from "../../pages/dashboard/pages/policy";
import SettingsPage from "../../pages/dashboard/pages/settings";
import StationPage from "../../pages/dashboard/pages/station";
import TeamPage from "../../pages/dashboard/pages/team";
import TicketPage from "../../pages/dashboard/pages/ticket";
import TravelPage from "../../pages/dashboard/pages/travel";
import ConvenienceIcon from "../svgJsx/convenience";
import DashboardIcon from "../svgJsx/dashboard";
import FinanceIcon from "../svgJsx/finance";
import PolicyIcon from "../svgJsx/policy";
import SettingIcon from "../svgJsx/setting";
import StationIcon from "../svgJsx/station";
import TeamIcon from "../svgJsx/team";
import TravelIcon from "../svgJsx/travel";
import { RouteModel } from "./model";

export const DashboardRoutes: Array<RouteModel> = [
  {
    path: "",
    name: "Dashboard",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: DashboardIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <DashboardHome />,
      },
    ],
  },
  {
    path: "ticket",
    name: "Tickets",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: TravelIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <TicketPage />,
      },
    ],
  },
  {
    path: "travel",
    name: "Trajets",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: TravelIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <TravelPage />,
      },
    ],
  },
  {
    path: "station",
    name: "Gares",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: StationIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <StationPage />,
      },
    ],
  },
  {
    path: "team",
    name: "Équipe",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: TeamIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <TeamPage />,
      },
    ],
  },
  {
    path: "finance",
    name: "Finance",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: FinanceIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <FinancePage />,
      },
    ],
  },
  {
    path: "policy",
    name: "Contrat",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: PolicyIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <PolicyPage />,
      },
    ],
  },
  {
    path: "convenience",
    name: "Commodité",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: ConvenienceIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <ConveniencePage />,
      },
    ],
  },
  {
    path: "settings",
    name: "Paramètres",
    show_in_sidebar: true,
    authorized_roles: ["*"],
    icon: SettingIcon,
    children: [
      {
        path: "",
        name: "any",
        show_in_sidebar: false,
        authorized_roles: ["*"],
        element: <SettingsPage />,
      },
    ],
  },
];
