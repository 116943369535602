import React from "react";
import SignupForm from "./form";
import VoyagePresentation from "./presentation";
import { useAuthStore } from "../../configs/stores/auth";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { primaryColor } from "../../constants";

const SignUp = () => {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const navigate = useNaviLoadBoth;
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <div className="flex flex-1 flex-col gap-10 items-center justify-center overflow-y-auto py-10">
      <div className="flex bg-white rounded-[10px] border-black/75 flex-col gap-2 px-8 py-9">
        <a href="/login" className="flex flex-row w-fit gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={primaryColor}
            className="w-7 h-7"
          >
            <path
              fillRule="evenodd"
              d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z"
              clipRule="evenodd"
            />
          </svg>
          <span className="flex transition-all duration-150 text-primary group-hover:text-hover font-semibold text-[18px]">
            Connexion
          </span>
        </a>
        <div className="flex flex-1 items-start flex-row gap-6 pt-[10px] pb-[20px]">
          <SignupForm />
          <VoyagePresentation />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
