import { createContext } from "react";

export const CreateGareContext = createContext({
  isLoading: false,
  formData: {
    gare_name: "",
    radioButtonValue: "current_position",
    prefix: "",
    phone: "",
    gare_id: "",
    gare_locality: "",
    gare_geocoords: "",
    // verificationData: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  setVerificationValues:function (value: any) {},
  submitFormSendLink: function () {},
  submitForm: function () {},
  errorState: {
    gare_name: { state: false, msg: "" },
    radioButtonValue: { state: false, msg: "" },
    prefix: { state: false, msg: "" },
    phone: { state: false, msg: "" },
    gare_id: { state: false, msg: "" },
    generalMsg: "",
  },
});
