import React from "react";
import InputText from "../../../../components/inputs/input-text";
import InputPhone from "../../../../components/inputs/input-phone";
import InputEmail from "../../../../components/inputs/input-email";
import { useAuthStore } from "../../../../configs/stores/auth";
import { getPropValue } from "../../../../functions/getPropValue";
import { MemberModel } from "../../../../models/models";

const UpdateCompanyForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const member = useAuthStore((e) => (e.member ?? {}) as MemberModel);
  const company = member.member_company;
  const updateCompany = useAuthStore((e) => e.updateCompany);
  const [formData, setFormData] = React.useState({
    company_name: company.company_name,
    company_email: company?.company_email ?? "",
    company_social_reason: company?.company_social_reason ?? "",
    company_commercial_register_no:
      company?.company_commercial_register_no ?? "",
    company_taxpayer_account_no: company?.company_taxpayer_account_no ?? "",
    company_head_office_address: company?.company_head_office_address ?? "",
    company_contact_0: company?.company_contacts?.[0] ?? "",
    company_contact_1: company?.company_contacts?.[1] ?? "",
  });
  const [errorState, setErrorState] = React.useState({
    company_name: { status: false, msg: "" },
    company_email: { status: false, msg: "" },
    company_social_reason: { status: false, msg: "" },
    company_commercial_register_no: { status: false, msg: "" },
    company_taxpayer_account_no: { status: false, msg: "" },
    company_head_office_address: { status: false, msg: "" },
    company_contact_0: { status: false, msg: "" },
    company_contact_1: { status: false, msg: "" },
    generalMsg: "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { status: false, msg: "" } }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.company_name.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({ ...e, company_name: { status: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_name: { status: false, msg: "" },
      }));
    }
    if (formData.company_email.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({ ...e, company_email: { status: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_email: { status: false, msg: "" },
      }));
    }
    if (formData.company_commercial_register_no.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        company_commercial_register_no: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_commercial_register_no: { status: false, msg: "" },
      }));
    }
    if (formData.company_head_office_address.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        company_head_office_address: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_head_office_address: { status: false, msg: "" },
      }));
    }
    if (formData.company_social_reason.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        company_social_reason: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_social_reason: { status: false, msg: "" },
      }));
    }
    if (formData.company_taxpayer_account_no.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        company_taxpayer_account_no: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_taxpayer_account_no: { status: false, msg: "" },
      }));
    }
    if (formData.company_contact_0.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        company_contact_0: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_contact_0: { status: false, msg: "" },
      }));
    }
    if (formData.company_contact_1.length === 0) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrorState((e) => ({
        ...e,
        company_contact_1: { status: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        company_contact_1: { status: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    setIsLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    const { company_contact_0, company_contact_1, ...rest } = formData;
    updateCompany({
      ...rest,
      company_contacts: [company_contact_0, company_contact_1],
    }).then((res) => {
      if (!res.success && res.message.length !== 0) {
        setErrorState((e) => ({ ...e, generalMsg: res.message.toString() }));
      }
      setIsLoading(false);
    });
  }
  return (
    <form onSubmit={submitForm} className="flex flex-col gap-5 max-w-[400px]">
      <InputText
        defaultValue={company.company_name}
        placeholder="Nom de la compagnie"
        onTextChange={onValueChange("company_name")}
        error={errorState.company_name}
      />
      <InputText
        defaultValue={company.company_social_reason}
        placeholder="Raison sociale"
        onTextChange={onValueChange("company_social_reason")}
        error={errorState.company_social_reason}
      />
      <InputText
        defaultValue={company.company_commercial_register_no}
        placeholder="N° registre de commerce"
        onTextChange={onValueChange("company_commercial_register_no")}
        error={errorState.company_commercial_register_no}
      />
      <InputText
        defaultValue={company.company_taxpayer_account_no}
        placeholder="N° Compte contribuable"
        onTextChange={onValueChange("company_taxpayer_account_no")}
        error={errorState.company_taxpayer_account_no}
      />
      <InputPhone
        defaultValue={company.company_contacts?.[0] ?? ""}
        placeholder="Contact 1"
        onTextChange={onValueChange("company_contact_0")}
        error={errorState.company_contact_0}
      />
      <InputPhone
        defaultValue={company.company_contacts?.[1] ?? ""}
        placeholder="Contact 2"
        onTextChange={onValueChange("company_contact_1")}
        error={errorState.company_contact_1}
      />
      <InputText
        defaultValue={company.company_head_office_address}
        placeholder="Adresse du siège"
        onTextChange={onValueChange("company_head_office_address")}
        error={errorState.company_head_office_address}
      />
      <InputEmail
        defaultValue={company.company_email}
        placeholder="Email"
        onTextChange={onValueChange("company_email")}
        error={errorState.company_email}
      />
      {!!errorState.generalMsg && (
        <span className="flex text-[15px] font-medium text-red-600">
          {errorState.generalMsg}
        </span>
      )}
      <button
        type="submit"
        className={`flex rounded-[10px] ${
          !isLoading ? "bg-primary hover:bg-hover" : "bg-hover"
        }  items-center justify-center py-[16px]`}
      >
        <span className="flex text-white font-semibold text-[18px]">
          {isLoading ? "Enregistrement...." : "Enregistrer"}
        </span>
      </button>
    </form>
  );
};

export default UpdateCompanyForm;
