import { createContext } from "react";
import { GareObject, TrajetObject, TronconObject, VehiculeObject } from "../../types/models";

export const AuthDataContext = createContext({
  sidebarItems: Array<{
    img: ({
      color,
      ...args
    }: {
      [x: string]: any;
      color?: string | undefined;
    }) => JSX.Element;
    title: string;
    url: string;
    content: JSX.Element;
  }>(),
  gares: Array<typeof GareObject>(),
  trajets: Array<typeof TrajetObject>(),
  troncons: Array<typeof TronconObject>(),
  vehicules: Array<typeof VehiculeObject>(),
  refreshGares: function () {},
  refreshTrajets: function () {},
  refreshTroncons: function () {},
  refreshVehicules: function () {},
});
