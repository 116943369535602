import React from "react";

const MissingPage = ({
  message = "Oops! Page introuvable.",
  children = <div />,
}) => {
  return (
    <div className="flex flex-1 h-full flex-col items-center justify-center">
      <h1 className="flex text-[30px] text-black font-bold leading-tight">
        404
      </h1>
      <h2 className="flex text-[20px] mt-2 font-medium">{message}</h2>
      {children}
    </div>
  );
};

export default MissingPage;
