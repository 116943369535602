import React from "react";
import ModalCustom from "../..";
import { useAuthStore } from "../../../../configs/stores/auth";
import { getPropValue } from "../../../../functions/getPropValue";
import { useTravelStore } from "../../../../configs/stores/travel";
import { isNumber, ToastWarnNotifier } from "../../../../constants";
import StationList from "./stationList";
import { StationModel } from "../../../../models/models";
import { generateId } from "../../../../functions/generateId";
import TravetList, { TravelItem } from "./travetList";

export function genererCouples<T>(tableauDonnees: Array<T>) {
  var couples = [];

  // Double boucle pour parcourir tous les éléments du tableau
  for (var i = 0; i < tableauDonnees.length; i++) {
    for (var j = i + 1; j < tableauDonnees.length; j++) {
      // Ajouter le couple [tableauDonnees[i], tableauDonnees[j]] à la liste des couples
      couples.push([tableauDonnees[i], tableauDonnees[j]]);
    }
  }

  return couples;
}

type Props = {
  handleClose: () => void;
  open: boolean;
  closeOnFinsh?: boolean;
};

const TravelNew = ({
  open = false,
  handleClose = () => {},
  closeOnFinsh = true,
}: Props) => {
  const { member } = useAuthStore();
  const [closable] = React.useState(true);
  const { newTravel } = useTravelStore();
  const [isLoading, setIsLoading] = React.useState(false);

  // Global Form
  const [globalFormData, setGlobalFormData] = React.useState<{
    travel_type: string;
    travel_places: string;
  }>({
    travel_type: "normal",
    travel_places: "",
  });
  const [errorGlobalFormState, setErrorGlobalFormState] = React.useState<{
    [x: string]: { state: boolean; msg: string };
  }>({
    travel_type: { state: false, msg: "" },
    travel_places: { state: false, msg: "" },
  });

  // Station Form
  const [stationList, setStationList] = React.useState<
    Array<{
      id: string;
      number: number;
      station: StationModel | null;
    }>
  >([
    {
      id: "stationitem0",
      number: 0,
      station: null,
    },
    { id: "stationitem1", number: 1, station: null },
  ]);
  const [errorStationState, setErrorStationState] = React.useState<{
    [x: string]: { state: boolean; msg: string };
  }>({
    stationitem0: { state: false, msg: "" },
    stationitem1: { state: false, msg: "" },
  });

  // Travel Form
  const [travelList, setTravelList] = React.useState<Array<TravelItem>>([
    {
      id: "stationitem0__stationitem1",
      number_from: 0,
      number_to: 1,
      stationitem0: "from",
      stationitem1: "to",
      active: true,
      form: {
        travel_to: null,
        travel_from: null,
        travel_cost: "",
        travel_duration: "",
        travel_vehicule: "bus",
        travel_zone: "local",
        travel_frequency: {
          type: "every_day",
          data: Array<{
            id: string;
            number: number;
            day: string;
            hour: string;
          }>(),
        },
      },
    },
  ]);
  const [errorTravelState, setErrorTravelState] = React.useState<{
    [x: string]: {
      [x: string]: { state: boolean; msg: string };
    };
  }>({
    stationitem0__stationitem1: {
      travel_zone: { state: false, msg: "" },
      travel_cost: { state: false, msg: "" },
      travel_duration: { state: false, msg: "" },
      travel_vehicule: { state: false, msg: "" },
      travel_frequency: { state: false, msg: "" },
    },
  });
  const [errorTravelFrequencyState, setErrorTravelFrequencyState] =
    React.useState<{
      [x: string]: {
        [x: string]: { state: boolean; msg: string };
      };
    }>({
      stationitem0__stationitem1: {},
    });

  // Global Manager
  function onGlobalValueChange(slug: string) {
    return (value: any) => {
      setGlobalFormData((f) => ({ ...globalFormData, [slug]: value }));
      if (getPropValue(errorGlobalFormState, slug)) {
        setErrorGlobalFormState((e) => ({
          ...e,
          [slug]: { state: false, msg: "" },
        }));
      }
    };
  }
  function checkGlobalFormData() {
    let errorOccured = false;
    // travel_type
    if (globalFormData.travel_type.trim().length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorGlobalFormState((e) => ({
        ...e,
        travel_type: { state: true, msg },
      }));
    } else {
      setErrorGlobalFormState((e) => ({
        ...e,
        travel_type: { state: false, msg: "" },
      }));
    }
    // travel_places
    if (
      globalFormData.travel_places.trim().length === 0 ||
      !isNumber(globalFormData.travel_places.trim())
    ) {
      errorOccured = true;
      let msg = "";
      setErrorGlobalFormState((e) => ({
        ...e,
        travel_places: { state: true, msg },
      }));
    } else {
      setErrorGlobalFormState((e) => ({
        ...e,
        travel_places: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }

  // Station Mangers
  function removeStationItem(id: string) {
    const exist = stationList.find((e) => e.id === id);
    if (!exist) {
      return null;
    }
    if ([0, 1].includes(exist.number) && stationList.length <= 2) {
      ToastWarnNotifier({
        message: "Votre trajet ne peut contenir moins de 2 points d'arret.",
      });
      return null;
    }
    const updatedStationList = stationList
      .filter((e: any) => e.id !== exist.id)
      .map((e) => {
        if (e.number - exist.number >= 0) {
          return { ...e, number: e.number - 1 };
        }
        return e;
      });
    setStationList(updatedStationList);
    setErrorTravelFrequencyState((etf) =>
      Object.entries(etf).reduce((prev, curr, ind, arr) => {
        if (curr[0].includes(id)) {
          return prev;
        }
        return { ...prev, [curr[0]]: curr[1] };
      }, {})
    );
    setErrorTravelState((et) =>
      Object.entries(et).reduce((prev, curr, ind, arr) => {
        if (curr[0].includes(id)) {
          return prev;
        }
        return { ...prev, [curr[0]]: curr[1] };
      }, {})
    );
    setTravelList((l) =>
      l.reduce((prev, curr, index, arr) => {
        if (curr.id.includes(id)) {
          return prev;
        }
        const ids = curr.id.split("__");
        const fromId = ids[0];
        const toId = ids[1];
        const from_numb =
          updatedStationList.find((e) => e.id === fromId)?.number ?? 0;
        const to_numb =
          updatedStationList.find((e) => e.id === toId)?.number ?? 0;
        return prev.concat({
          ...curr,
          number_from: from_numb,
          number_to: to_numb,
        });
      }, Array<TravelItem>())
    );
    const errors = Object.entries(errorStationState)
      .filter((e) => e[0] !== id)
      .reduce((prev, curr, ind, arr) => {
        return { ...prev, [curr[0]]: curr[1] };
      }, {});
    setErrorStationState(errors);
  }
  function updateStationItem(
    id: string,
    number: number,
    station: StationModel | null
  ) {
    const exist = stationList.find((e) => e.id === id);
    if (!exist) {
      return null;
    }
    setStationList((s) =>
      s.map((e) => {
        if (e.id === id) {
          return { ...e, number, station };
        }
        return e;
      })
    );
    setTravelList((l) =>
      l.map((t) => {
        if (t.id.includes(id)) {
          const part = t[id]; // to or from
          return {
            ...t,
            [`number_${part}`]: number,
            form: {
              ...t.form,
              [`travel_${part}`]: station,
            },
          };
        }
        return t;
      })
    );
    setErrorStationState((e) => ({
      ...e,
      [id]: { state: false, msg: "" },
    }));
  }
  function addStationItem() {
    const id = generateId();
    const sttion = { id, number: stationList.length, station: null };
    setStationList((s) => s.concat(sttion));
    setErrorStationState((e) => ({
      ...e,
      [id]: { state: false, msg: "" },
    }));
    const travels = genererCouples(stationList.concat(sttion)).reduce<
      Array<TravelItem>
    >((prev, curr, index, arr) => {
      const existed = travelList.find(
        (e) => e.id === `${curr[0].id}__${curr[1].id}`
      );
      if (existed) {
        return prev;
      }
      const from = curr[0];
      const to = curr[1];
      const travel = {
        id: `${from.id}__${to.id}`,
        number_from: from.number,
        number_to: to.number,
        [from.id]: "from",
        [to.id]: "to",
        active: true,
        form: {
          travel_to: to.station,
          travel_from: from.station,
          travel_cost: "",
          travel_duration: "",
          travel_vehicule: "bus",
          travel_zone: "local",
          travel_frequency: {
            type: "every_day",
            data: Array<{
              id: string;
              number: number;
              day: string;
              hour: string;
            }>(),
          },
        },
      };
      return prev.concat(travel);
    }, []);
    const travelsFrequencyErrors = travels.reduce((prev, curr, index, arr) => {
      const exist = Object.entries(errorTravelFrequencyState).find(
        (e) => e[0] === curr.id
      );
      if (exist) {
        return {
          ...prev,
          [exist[0]]: exist[1],
        };
      }
      return {
        ...prev,
        [curr.id]: {},
      };
    }, {});
    setErrorTravelFrequencyState({
      ...errorTravelFrequencyState,
      ...travelsFrequencyErrors,
    });
    const travelsErrors = travels.reduce((prev, curr, index, arr) => {
      const exist = Object.entries(errorTravelState).find(
        (e) => e[0] === curr.id
      );
      if (exist) {
        return {
          ...prev,
          [exist[0]]: exist[1],
        };
      }
      return {
        ...prev,
        [curr.id]: {
          travel_zone: { state: false, msg: "" },
          travel_cost: { state: false, msg: "" },
          travel_duration: { state: false, msg: "" },
          travel_vehicule: { state: false, msg: "" },
          travel_frequency: { state: false, msg: "" },
        },
      };
    }, {});
    setErrorTravelState({ ...errorTravelState, ...travelsErrors });
    setTravelList((l) => l.concat(travels));
  }
  function checkStationListData() {
    const stationErrors = stationList.filter((e) => !!!e.station);
    setErrorStationState((e) =>
      Object.entries(e).reduce((prev, curr, index, arr) => {
        const existedError = stationErrors.find((s) => s.id === curr[0]);
        if (existedError) {
          return {
            ...prev,
            [curr[0]]: {
              state: true,
              msg: "",
            },
          };
        }
        return {
          ...prev,
          [curr[0]]: curr[0],
        };
      }, {})
    );
    return stationErrors.length !== 0;
  }

  // travel Managers
  function onTravelValueChange(travelId: string, slug: string) {
    return (value: any) => {
      setTravelList((l) =>
        l.map((t) => {
          if (t.id === travelId) {
            return {
              ...t,
              form: { ...t.form, [slug]: value },
            };
          }
          return t;
        })
      );
      if (getPropValue(errorTravelState[travelId], slug)) {
        setErrorTravelState((e) => ({
          ...e,
          [travelId]: { ...e[travelId], [slug]: { state: false, msg: "" } },
        }));
      }
    };
  }
  function updateActivation(travelId: string, checked: boolean) {
    setTravelList((l) =>
      l.map((t) => {
        if (t.id === travelId) {
          return {
            ...t,
            active: checked,
          };
        }
        return t;
      })
    );
  }
  function addNewStart(travelId: string) {
    const id = generateId();
    const form = travelList.filter((e) => e.id === travelId)[0].form;
    onTravelValueChange(
      travelId,
      "travel_frequency"
    )({
      type: form.travel_frequency.type,
      data: [
        ...form.travel_frequency.data,
        {
          id,
          number: form.travel_frequency.data.length + 1,
          day: null,
          hour: null,
        },
      ],
    });
    manageTravelFrequencyError(travelId, "add", id);
  }
  function manageTravelFrequencyError(
    travelId: string,
    action: string = "add",
    id: string
  ) {
    if (action === "add") {
      setErrorTravelFrequencyState((e) => ({
        ...e,
        [travelId]: {
          ...e[travelId],
          [id]: { state: false, msg: "" },
        },
      }));
      return;
    } else if (action === "remove") {
      const errorTravelFrequencyStateElement =
        errorTravelFrequencyState[travelId];
      const errors = {
        ...errorTravelFrequencyState,
        [travelId]: Object.entries(errorTravelFrequencyStateElement)
          .filter((e) => e[0] !== id)
          .reduce((prev, curr, ind, arr) => {
            return { ...prev, [curr[0]]: curr[1] };
          }, {}),
      };
      setErrorTravelFrequencyState(errors);
      return;
    } else if (action === "reinit") {
      setErrorTravelFrequencyState((e) => ({
        ...e,
        [travelId]: {
          ...e[travelId],
          [id]: { state: false, msg: "" },
        },
      }));
      return;
    }
    return;
  }
  function checkTravelListData() {
    const travelErrors = travelList.reduce(
      (prev, curr, index, arr) => {
        if (!curr.active) {
          return prev;
        }
        const form = Object.entries(curr.form).reduce(
          (prevF, currF, indexF, arrF) => {
            const value = currF[1];
            if (
              currF[0] === "travel_cost" &&
              ((typeof value === "string" &&
                (value.length === 0 || !isNumber(value))) ||
                typeof value !== "string")
            ) {
              return {
                ...prevF,
                [currF[0]]: {
                  state: true,
                  msg: "",
                },
              };
            }
            if (
              ["travel_duration", "travel_zone", "travel_vehicule"].includes(
                currF[0]
              ) &&
              ((typeof value === "string" && value.length === 0) ||
                typeof value !== "string")
            ) {
              return {
                ...prevF,
                [currF[0]]: {
                  state: true,
                  msg: "",
                },
              };
            }
            if (
              currF[0] === "travel_frequency" &&
              (
                value as {
                  type: string;
                  data: {
                    id: string;
                    number: number;
                    day: string;
                    hour: string;
                  }[];
                }
              ).data.length === 0
            ) {
              return {
                ...prevF,
                [currF[0]]: {
                  state: true,
                  msg: "",
                },
              };
            }
            return prevF;
          },
          {}
        );
        return prev.concat({ id: curr.id, form });
      },
      Array<{
        id: string;
        form: {
          [x: string]: { state: boolean; msg: string };
        };
      }>()
    );
    setErrorTravelState((e) =>
      Object.entries(e).reduce((prev, curr, index, arr) => {
        return {
          ...prev,
          [curr[0]]: {
            ...errorTravelState[curr[0]],
            ...travelErrors.find((r) => r.id === curr[0])?.form,
          },
        };
      }, {})
    );
    return (
      travelErrors.reduce((prev, curr, index, arr) => {
        return prev.concat(Object.entries(curr.form).filter((e) => e[1].state));
      }, Array<any>()).length !== 0
    );
  }
  function checkTravelFrequencyFormData() {
    const travelErrors = travelList.reduce(
      (prev, curr, index, arr) => {
        if (!curr.active) {
          return prev;
        }
        const typeF = curr.form.travel_frequency.type;
        const form = curr.form.travel_frequency.data.reduce(
          (prevF, currF, indexF, arrF) => {
            const id = currF.id;
            const value = currF;
            if (typeF === "every_day") {
              return {
                ...prevF,
                [id]: {
                  state: !value.hour,
                  msg: "",
                },
              };
            } else {
              return {
                ...prevF,
                [id]: {
                  state: !value.day || !value.hour,
                  msg: "",
                },
              };
            }
          },
          {}
        );
        return prev.concat({ id: curr.id, form });
      },
      Array<{
        id: string;
        form: {
          [x: string]: { state: boolean; msg: string };
        };
      }>()
    );
    setErrorTravelFrequencyState((e) =>
      Object.entries(e).reduce((prev, curr, index, arr) => {
        return {
          ...prev,
          [curr[0]]: {
            ...errorTravelFrequencyState[curr[0]],
            ...travelErrors.find((r) => r.id === curr[0])?.form,
          },
        };
      }, {})
    );
    return (
      travelErrors.reduce((prev, curr, index, arr) => {
        return prev.concat(Object.entries(curr.form).filter((e) => e[1].state));
      }, Array<any>()).length !== 0
    );
  }

  /// checkFormS Data
  function checkFormData() {
    let errorOccured = false;
    // globalFormData
    if (checkGlobalFormData()) {
      errorOccured = true;
    }
    /// stationList
    if (checkStationListData()) {
      errorOccured = true;
    }
    /// travelList
    if (checkTravelListData()) {
      errorOccured = true;
    }
    // travel_frequency
    if (checkTravelFrequencyFormData()) {
      errorOccured = true;
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      ToastWarnNotifier({
        message: "Erreur Rencontrer",
      });
      return;
    }
    setIsLoading(true);
    const data = {
      travel_company: member?.member_company.company_id,
      ...globalFormData,
      travels: travelList.map((travel) => {
        return {
          ...travel.form,
          travel_from: travel.form.travel_from?.station_id,
          travel_to: travel.form.travel_to?.station_id,
          travel_from_index: travel.number_from,
          travel_to_index: travel.number_to,
          travel_active: travel.active,
        };
      }),
    };
    newTravel(data).then((res) => {
      setIsLoading(false);
      if (res.success) {
        close();
      }
    });
  }
  function close() {
    // Global
    setGlobalFormData({
      travel_places: "",
      travel_type: "normal",
    });
    setErrorGlobalFormState({
      travel_places: { state: false, msg: "" },
      travel_type: { state: false, msg: "" },
    });
    //Station
    setStationList([
      {
        id: "stationitem0",
        number: 0,
        station: null,
      },
      { id: "stationitem1", number: 1, station: null },
    ]);
    setErrorStationState({
      stationitem0: { state: false, msg: "" },
      stationitem1: { state: false, msg: "" },
    });
    // Travel
    setTravelList([
      {
        id: "stationitem0__stationitem1",
        number_from: 0,
        number_to: 1,
        stationitem0: "from",
        stationitem1: "to",
        active: true,
        form: {
          travel_to: null,
          travel_from: null,
          travel_cost: "",
          travel_duration: "",
          travel_vehicule: "bus",
          travel_zone: "local",
          travel_frequency: {
            type: "every_day",
            data: Array<{
              id: string;
              number: number;
              day: string;
              hour: string;
            }>(),
          },
        },
      },
    ]);
    setErrorTravelState({
      stationitem0__stationitem1: {
        travel_zone: { state: false, msg: "" },
        travel_cost: { state: false, msg: "" },
        travel_duration: { state: false, msg: "" },
        travel_vehicule: { state: false, msg: "" },
        travel_frequency: { state: false, msg: "" },
      },
    });
    setErrorTravelFrequencyState({
      stationitem0__stationitem1: {},
    });
    handleClose();
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col items-center pb-3 px-5 gap-4 rounded-md">
        <span className="flex text-hover font-semibold text-[20px]">
          Ajouter un trajet
        </span>
        <div className="flex flex-row max-h-[75vh]">
          <StationList
            stationList={stationList}
            addStation={addStationItem}
            updateStation={updateStationItem}
            removeStation={removeStationItem}
            errorStationState={errorStationState}
            errorGlobalFormState={errorGlobalFormState}
            globalFormData={globalFormData}
            onGlobalValueChange={onGlobalValueChange}
          />
          <div className="flex h-[70%] w-1 bg-coral self-center mx-1" />
          <TravetList
            travelList={travelList}
            addNewStart={addNewStart}
            updateActivation={updateActivation}
            onTravelValueChange={onTravelValueChange}
            errorTravelState={errorTravelState}
            manageTravelFrequencyError={manageTravelFrequencyError}
            errorTravelFrequencyState={errorTravelFrequencyState}
          />
        </div>
        <button
          onClick={!isLoading ? submitForm : undefined}
          className="flex px-12 py-3 text-white justify-center bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
        >
          {!isLoading ? (
            <span className="flex text-[18px]">Enregister le trajet</span>
          ) : (
            <svg
              className="animate-spin h-4 w-4 text-black/50"
              xmlns="http://www.w3.org/2000/svg"
              fill={"transparent"}
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={"white"}
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill={"white"}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </ModalCustom>
  );
};

export default TravelNew;
