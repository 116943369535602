import React from "react";
import TableDataAutoList from "../../../../components/array";
import MemberActions from "./actions";
import { getRoleLabel, useMembersStore } from "../../../../configs/stores/member";

const TeamTable = () => {
  const { members } = useMembersStore();
  const columns = [
    {
      label: "Nom",
      slug: "fullname",
      maxWidth: "300px",
    },
    {
      label: "Identifiant",
      slug: "login",
      maxWidth: "300px",
    },
    {
      label: "Role",
      slug: "role",
      maxWidth: "300px",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        actions={MemberActions}
        rows={members.map(function (member) {
          return {
            member,
            fullname: member.member_fullname,
            login: member.member_login,
            role: getRoleLabel(member.member_role),
          };
        })}
      />
    </div>
  );
};

export default TeamTable;
