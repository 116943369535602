import React from "react";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { primaryColor } from "../../../../../constants";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, DateTimeField } from "@mui/x-date-pickers";
import moment from "moment";

type Props = {
  depart: {
    id: string;
    number: number;
    day: string | null;
    hour: string | null;
  };
  remove: (id: string) => void;
  update: (id: string, slug: string, value: any) => void;
  error: {
    state: boolean;
    msg: string;
  };
};

const EveryDayHourItem = ({ depart, error, remove, update }: Props) => {
  function updateDepart(slug: string, value: any) {
    update(depart.id, slug, value);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex w-full flex-col ">
          <DateTimeField
            label={"Départ n°" + depart.number.toString()}
            value={moment("2000-01-01T" + depart.hour + ":00+00:00")}
            color={"warning"}
            format="HH:mm"
            size="small"
            {...{ error: error.state }}
            onChange={(newValue: any) => {
              const hour = moment(newValue).format("HH:mm");
              if (hour !== "Invalid date") {
                updateDepart("hour", hour);
              } else {
                updateDepart("hour", null);
              }
            }}
          />
        </div>
        <IconButton
          aria-label="remove item"
          color={"warning"}
          onClick={function () {
            remove(depart.id);
          }}
        >
          <RemoveIcon htmlColor={primaryColor} />
        </IconButton>
      </div>
    </LocalizationProvider>
  );
};

export default EveryDayHourItem;
