import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getAllTravel(id: string) {
  return getUrl("/travel/all?id=" + id);
}

export function newTravel(data: any = {}) {
  return postUrl("/travel/new", data);
}

export function updateTravel(id: string = "", data: any = {}) {
  return postUrl("/travel/update?id=" + id, data);
}
export function updateTravelTrip(id: string = "", data: any = {}) {
  return postUrl("/travel/update/trip?id=" + id, data);
}

export function removeTravel(id: string = "") {
  return deleteUrl("/travel/remove?id=" + id);
}
