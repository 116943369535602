import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import axios from "axios";
import { ZustandLocalStorageCrypted } from "./utils";
import { StationModel } from "../../models/models";
import {
  getAllStation,
  newStation,
  removeStation,
  sendLinkStation,
  updateStation,
  updateStationConveniences,
  verifyStation,
} from "./functions/station";
import { ToastWarnNotifier } from "../../constants";

const LOCAL_DATASTORE_NAME = "lesvoyageursbusiness-station";

type ContextProps = {
  isLoading: boolean;
  stations: Array<StationModel>;
  getAll: (id: string) => Promise<any>;
  newStation: (data: any) => Promise<any>;
  updateStation: (id: string, companyId: string, update: any) => Promise<any>;
  updateStationConveniences: (id: string, companyId: string, update: any) => Promise<any>;
  removeStation: (id: string, companyId: string) => Promise<any>;
  verifyStation: (id: string, companyId: string) => Promise<any>;
  sendLinkStation: (id: string, data: any) => Promise<any>;
  saveManualyLocalityStation: (
    id: string,
    companyId: string,
    data: any
  ) => Promise<any>;
};

export const useStationStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      stations: [],
      getAll: async (id: string) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllStation(id);
        const data = (res?.data ?? []) as Array<StationModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.stations = data;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      newStation: async (data) => {
        const state = get();
        const res = await newStation(data);
        if (res.success) {
          await state.getAll(data.station_company);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateStation: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateStation(id, update);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateStationConveniences: async (id: string, companyId, update: any) => {
        const state = get();
        const res = await updateStationConveniences(id, update);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      removeStation: async (id: string, companyId) => {
        const state = get();
        const res = await removeStation(id);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      verifyStation: async (id: string, companyId: string) => {
        const state = get();
        const res = await verifyStation(id);
        if (res.success) {
          await state.getAll(companyId);
        } else {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      sendLinkStation: async (id: string, data: any) => {
        const res = await sendLinkStation(id, data);
        if (!res.success) {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
      saveManualyLocalityStation: async (
        id: string,
        companyId: string,
        data: any
      ) => {
        const state = get();
        const result_locality = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${data.lat}&lon=${data.lon}&format=json`
        );
        const res = await updateStation(id, {
          station_locality: result_locality.data.display_name,
          station_geocoords: data,
        });
        if (res.success) {
          await state.getAll(companyId);
        }
        if (!res.success) {
          ToastWarnNotifier({ message: res.message.toString() });
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_als")),
    }
  )
);
