import React from "react";
import TableDataAutoList from "../../../../components/array";
import "moment/locale/fr";
import { useStatsStore } from "../../../../configs/stores/stats";
import moment from "moment";

const FinanceTable = () => {
  const { stats } = useStatsStore();
  const columns = [
    {
      label: "Mois",
      slug: "month",
    },
    {
      label: "Total Ticket Vendus",
      slug: "sum",
    },
    {
      label: "Total Ventes",
      slug: "sell",
    },
    {
      label: "Total Retrait",
      slug: "withdraw",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        rows={
          stats.finance?.history?.map(function (monthStat) {
            return {
              monthStat,
              month: moment(monthStat.month, "MM-YYYY")
                .format("MMMM YYYY")
                .toUpperCase(),
              sell: monthStat.sell + " FCFA",
              withdraw: monthStat.withdraw + " FCFA",
              sum: monthStat.sum,
            };
          }) ?? []
        }
      />
    </div>
  );
};

export default FinanceTable;
