import { Autocomplete, TextField } from "@mui/material";
import React from "react";

interface Option {
  label: string;
  value: any;
}

interface Props {
  onChange?: (value: any) => void;
  onTextChange?: (value: string) => void;
  errorState?: { state: boolean; msg: string };
  options?: Option[];
  defaultOption?: Option;
  [x: string]: any;
}

function AutoCompleteSelectStationInput({
  onChange = (value: any) => {},
  onTextChange = (value: any) => {},
  errorState = { state: false, msg: "" },
  options = [],
  defaultOption,
  freeSolo = false,
  ...args
}: Props) {
  const [defaultLocalValue] = React.useState(defaultOption);
  return (
    <Autocomplete
      id="free-solo-2-demo"
      // disableClearable
      className="self-stretch"
      disablePortal
      defaultValue={defaultLocalValue}
      options={options}
      onChange={(event, value) => {
        if (value && value.label.length !== 0) {
          onChange(value.value);
        } else {
          onChange(null);
        }
      }}
      getOptionLabel={(option) => option.label}
      renderInput={(params: any) => (
        <TextInput
          {...params}
          color="warning"
          variant="standard"
          error={errorState.state}
          helperText={errorState.msg || null}
          className="flex bg-white border-none outline-none"
          InputProps={{ ...params.InputProps }}
          {...args}
        />
      )}
      size="small"
      isOptionEqualToValue={(option, value) => {
        return value ? option.label === value.label : false;
      }}
    />
  );
}

const TextInput = ({
  onChange = (e: string) => {},
  errorState = { state: false, msg: "" },
  ...args
}) => {
  return (
    <TextField
      color="warning"
      variant="standard"
      className="flex bg-white border-none outline-none"
      error={errorState.state}
      helperText={errorState.msg.length !== 0 ? errorState.msg : null}
      onChange={function (value) {
        onChange(value.currentTarget.value);
      }}
      defaultValue={args?.defaultValue}
      {...args}
    />
  );
};

export default AutoCompleteSelectStationInput;
