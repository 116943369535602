import React from "react";
import ModalCustom from "../..";
import { useAuthStore } from "../../../../configs/stores/auth";
import { getPropValue } from "../../../../functions/getPropValue";
import {
  TravelTypeLabel,
  useTravelStore,
} from "../../../../configs/stores/travel";
import { isNumber, ToastWarnNotifier } from "../../../../constants";
import { TravelModel } from "../../../../models/models";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import NumberInput from "../../../inputs/number";
import SelectDropDown from "./select";

type Props = {
  travel: TravelModel;
  handleClose: () => void;
  open: boolean;
  closeOnFinsh?: boolean;
};

const TravelUpdate = ({
  open = false,
  handleClose = () => {},
  travel,
  closeOnFinsh = true,
}: Props) => {
  const { member } = useAuthStore();
  const [closable] = React.useState(true);
  const { updateTravel } = useTravelStore();
  const [isLoading, setIsLoading] = React.useState(false);

  const [formData, setFormData] = React.useState<{
    travel_type: string;
    travel_places: string;
  }>({
    travel_type: travel.travel_type,
    travel_places: travel.travel_places.toString(),
  });
  const [errorFormState, setErrorFormState] = React.useState<{
    [x: string]: { state: boolean; msg: string };
  }>({
    travel_type: { state: false, msg: "" },
    travel_places: { state: false, msg: "" },
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorFormState, slug)) {
        setErrorFormState((e) => ({
          ...e,
          [slug]: { state: false, msg: "" },
        }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // travel_type
    if (formData.travel_type.trim().length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorFormState((e) => ({
        ...e,
        travel_type: { state: true, msg },
      }));
    } else {
      setErrorFormState((e) => ({
        ...e,
        travel_type: { state: false, msg: "" },
      }));
    }
    // travel_places
    if (
      formData.travel_places.trim().length === 0 ||
      !isNumber(formData.travel_places.trim())
    ) {
      errorOccured = true;
      let msg = "";
      setErrorFormState((e) => ({
        ...e,
        travel_places: { state: true, msg },
      }));
    } else {
      setErrorFormState((e) => ({
        ...e,
        travel_places: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }

  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      ToastWarnNotifier({
        message: "Erreur Rencontrer",
      });
      return;
    }
    setIsLoading(true);
    updateTravel(
      travel.travel_id,
      member?.member_company.company_id ?? "",
      formData
    ).then((res) => {
      setIsLoading(false);
      if (res.success) {
        close();
      }
    });
  }
  function close() {
    handleClose();
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="flex flex-col w-[500px] pb-3 px-5 gap-4 rounded-md">
          <span className="flex text-hover font-semibold text-[20px]">
            Modifier un trajet
          </span>
          <div className="flex flex-col gap-5 pr-1 py-1 max-h-[70vh] overflow-y-auto">
            <div className="flex flex-col gap-3">
              <span className="flex text-[16px] text-primary">Trajet</span>
              <span className="flex font-medium border-l-4 border-primary pl-2">
                {travel.travels
                  .reduce((prev, curr, index, arr) => {
                    const list = prev.map((e) => e.index);
                    function verify(id: number) {
                      return list.includes(id);
                    }
                    let previous = prev;
                    if (!verify(curr.travel_from_index)) {
                      previous.push({
                        index: curr.travel_from_index,
                        value: curr.travel_from.station_name,
                      });
                    }
                    if (!verify(curr.travel_to_index)) {
                      previous.push({
                        index: curr.travel_to_index,
                        value: curr.travel_to.station_name,
                      });
                    }
                    return previous;
                  }, Array<{ index: number; value: string }>())
                  .sort((a, b) => {
                    return a.index - b.index;
                  })
                  .map((e) => {
                    return e.value;
                  })
                  .join(", ")}
              </span>
            </div>
            <SelectDropDown
              label={"Type"}
              defaultValue={travel.travel_type}
              error={errorFormState.travel_type.state}
              options={TravelTypeLabel.filter((e) => {
                if (travel.travels.length === 1) {
                  return true;
                }
                return e.value === "normal";
              })}
              onChange={onValueChange("travel_type")}
            />
            <NumberInput
              label={"Places"}
              placeholder={"Exemple: 30"}
              defaultValue={travel.travel_places.toString()}
              errorState={errorFormState.travel_places}
              onChange={onValueChange("travel_places")}
              size={"small"}
              InputProps={{
                endAdornment: <span>Places</span>,
              }}
            />
          </div>
          <button
            onClick={!isLoading ? submitForm : undefined}
            className="flex px-12 py-3 text-white justify-center bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
          >
            {!isLoading ? (
              <span className="flex text-[18px]">Enregister</span>
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </LocalizationProvider>
    </ModalCustom>
  );
};

export default TravelUpdate;
