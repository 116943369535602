import { createTheme } from "@mui/material/styles";
import { primaryColor } from "../../constants";
export const themeMui = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: "white",
    },
    secondary: {
      main: "#000",
      contrastText: "white",
    },
    text: {
      primary: "#000",
      secondary: "#5e636b",
    },
  },
});
