import React from "react";
import TableDataAutoList from "../../../../components/array";
import TravelActions from "./actions";
import {
  TravelFrequencyLabel,
  TravelTypeLabel,
  TravelVehiculeLabel,
  TravelZoneLabel,
  useTravelStore,
} from "../../../../configs/stores/travel";
import TravelsChildrenActions from "./childrenActions";

const TravelTable = () => {
  const { travels } = useTravelStore();
  const columns = [
    {
      label: "Type",
      slug: "type",
      maxWidth: "100px",
    },
    {
      label: "Places",
      slug: "places",
      maxWidth: "80px",
    },
    {
      label: "Nombre de voyages",
      slug: "number",
      maxWidth: "160px",
    },
    {
      label: "Parcourt",
      slug: "trip",
    },
  ];
  const childrenColumns = [
    {
      label: "Depart",
      slug: "from",
      maxWidth: "300px",
    },
    {
      label: "Arriver",
      slug: "to",
      maxWidth: "300px",
    },
    {
      label: "Vehicule",
      slug: "vehicule",
      maxWidth: "150px",
    },
    {
      label: "Frequence",
      slug: "frequency",
      maxWidth: "150px",
    },
    {
      label: "Duree Moyenne",
      slug: "duration",
      maxWidth: "200px",
    },
    {
      label: "Zone",
      slug: "zone",
      maxWidth: "150px",
    },
    {
      label: "Cout",
      slug: "cost",
      maxWidth: "200px",
    },
    {
      label: "Actif",
      slug: "state",
      maxWidth: "100px",
    },
  ];
  return (
    <div className="flex">
      <TableDataAutoList
        columns={columns}
        actions={TravelActions}
        childrenAction={TravelsChildrenActions}
        haveChildren
        childrenColumns={childrenColumns}
        rows={travels.map(function (travel) {
          return {
            travel,
            type:
              TravelTypeLabel.find((e) => e.value === travel.travel_type)
                ?.label ?? "",
            places: travel.travel_places,
            number: travel.travels.length,
            trip: travel.travels
              .reduce((prev, curr, index, arr) => {
                const list = prev.map((e) => e.index);
                function verify(id: number) {
                  return list.includes(id);
                }
                let previous = prev;
                if (!verify(curr.travel_from_index)) {
                  previous.push({
                    index: curr.travel_from_index,
                    value: curr.travel_from.station_name,
                  });
                }
                if (!verify(curr.travel_to_index)) {
                  previous.push({
                    index: curr.travel_to_index,
                    value: curr.travel_to.station_name,
                  });
                }
                return previous;
              }, Array<{ index: number; value: string }>())
              .sort((a, b) => {
                return a.index - b.index;
              })
              .map((e) => {
                return e.value;
              })
              .join(" -> "),
            children: travel.travels
              .map((t) => {
                return {
                  trip: t,
                  from: t.travel_from.station_name,
                  to: t.travel_to.station_name,
                  vehicule:
                    TravelVehiculeLabel.find(
                      (e) => e.value === t.travel_vehicule
                    )?.label ?? "",
                  frequency:
                    TravelFrequencyLabel.find(
                      (e) => e.type === t.travel_frequency.type
                    )?.label ?? "",
                  duration: t.travel_duration,
                  zone:
                    TravelZoneLabel.find((e) => e.value === t.travel_zone)
                      ?.label ?? "",
                  cost: t.travel_cost + " Frcfa",
                  state: t.travel_active ? "Oui" : "Non",
                };
              }),
          };
        })}
      />
    </div>
  );
};

export default TravelTable;
