import { Autocomplete } from "@mui/material";
import TextInput from "./input";
import { ANY } from "../../constants";
import React from "react";

interface Option {
  label: string;
  value: any;
}

interface Props {
  onChange?: (value: any) => void;
  onTextChange?: (value: string) => void;
  errorState?: { state: boolean; msg: string };
  options?: Option[];
  defaultOption?: Option;
  selectedOption?: Option;
  [x: string]: any;
}

function AutocompleteSelectInput({
  onChange = (value: any) => {},
  onTextChange = (value: any) => {},
  errorState = { state: false, msg: "" },
  options = [],
  defaultOption = { label: "", value: ANY },
  selectedOption = { label: "", value: ANY },
  freeSolo = false,
  ...args
}: Props) {
  // const [inputValue, setInputValue] = React.useState("");
  return (
    <Autocomplete
      // freeSolo={freeSolo}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      // onInputChange={(event: any, value: string) => {
      //   setInputValue(value);
      //   onTextChange(value);
      // }}
      id="free-solo-2-demo"
      disableClearable
      // inputValue={inputValue}
      className="self-stretch"
      disablePortal
      value={selectedOption}
      options={options}
      onChange={(event, value) => {
        if (value && value.label.length !== 0) {
          onChange(value.value);
        } else {
          onChange(defaultOption.value ?? ANY);
        }
      }}
      getOptionLabel={(option) => option.label}
      renderInput={(params: any) => (
        <TextInput
          {...params}
          color="warning"
          variant="outlined"
          error={errorState.state}
          helperText={errorState.msg || null}
          className="flex bg-white border-none outline-none"
          InputProps={{ ...params.InputProps }}
          {...args}
        />
      )}
      size="medium"
    />
  );
}

export default AutocompleteSelectInput;
