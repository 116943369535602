import { Outlet } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Forloadandnavigate } from "../../functions/useNaviLoadBoth";

const LayoutPage = () => {
  return (
    <main className="flex flex-1 h-screen">
      <div className="hidden min_show_space:flex flex-col min-h-screen h-full w-full font-inter scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300 hover:scrollbar-thumb-gray-700 active:scrollbar-thumb-gray-700">
        <div className="flex flex-1 pt-[60px] bg-gradient-to-b from-[#f5f5f5] to-white bg-cover bg-primary-contrast1">
          <Outlet />
        </div>
        <Header />
        <Forloadandnavigate />
        <Footer />
      </div>
      <div className="flex min_show_space:hidden flex-1 items-center justify-center flex-col">
        <div className="flex fex-row items-center px-4 flex-wrap gap-5">
          <span className="flex text-[24px] font-bold text-black ">Ecran minimum requis: </span>
          <span className="flex text-[24px] font-bold text-black ">[Largeur minimum: 800px ]</span>
        </div>
      </div>
    </main>
  );
};

export default LayoutPage;
