import React from "react";

export function useElementSize() {
  const [size, setSize] = React.useState({ height: 0, width: 0 });
  const refInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    function handleResize() {
      setSize({
        height: refInput.current?.offsetHeight ?? 0,
        width: refInput.current?.offsetWidth ?? 0,
      });
    }
    handleResize()
    document.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("resize", handleResize);
    };
  });
  return { refInput, size };
}


export function useElementDimensions<T extends HTMLElement>() {
  const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });
  const ref = React.useRef<T>(null);

  React.useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: ref.current?.offsetHeight ?? 0,
        width: ref.current?.offsetWidth ?? 0,
      });
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return { ref, dimensions };
}
