import React from "react";
import ModalCustom from "../..";
import TextInput from "../../../inputs/input";
import { getPropValue } from "../../../../functions/getPropValue";
import {
  useMembersStore,
} from "../../../../configs/stores/member";
import PasswordInput from "./password";
import { isPassword, ToastWarnNotifier } from "../../../../constants";
import { MemberModel } from "../../../../models/models";

type Props = {
  handleClose: () => void;
  open: boolean;
  member: MemberModel;
  closeOnFinsh?: boolean;
};

const MemberUpdatePassword = ({
  open = false,
  member,
  handleClose = () => {},
  closeOnFinsh = true,
}: Props) => {
  const [closable] = React.useState(true);
  const { updateMemberPassword } = useMembersStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    member_password: "",
    member_confirmpassword: "",
  });
  const [errorState, setErrorState] = React.useState({
    member_password: { state: false, msg: "" },
    member_confirmpassword: { state: false, msg: "" },
  });
  function checkFormData() {
    let errorOccured = false;
    // member_password
    if (
      formData.member_password.length === 0 ||
      !isPassword(formData.member_password)
    ) {
      errorOccured = true;
      let msg = "";
      if (formData.member_password.length <= 10) {
        msg = "Minimum 10 caracteres";
      } else {
        msg = "Minimum un chiffre, une majuscule, une minuscule";
      }
      setErrorState((e) => ({
        ...e,
        member_password: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        member_password: { state: false, msg: "" },
      }));
    }
    if (formData.member_password !== formData.member_confirmpassword) {
      errorOccured = true;
      let msg = "Confirmer correctement le mot de passe";
      setErrorState((e) => ({
        ...e,
        member_confirmpassword: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        member_confirmpassword: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function close() {
    setFormData({
      member_password: "",
      member_confirmpassword: "",
    });
    setErrorState({
      member_password: { state: false, msg: "" },
      member_confirmpassword: { state: false, msg: "" },
    });
    handleClose();
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      return;
    }
    setIsLoading(true);
    updateMemberPassword(
      member?.member_id ?? "",
      member?.member_company.company_id ?? "",
      {
        member_password: formData.member_password,
      }
    ).then((res) => {
      setIsLoading(false);
      if (res.success) {
        closeOnFinsh && close();
      } else {
        ToastWarnNotifier({
          message: res.message,
        });
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col max-h-[80vh] items-center pb-5 px-5 gap-3 rounded-md w-[500px] ">
        <span className="flex text-hover font-semibold text-[20px]">
          Modifier membre mot de passe
        </span>
        <div className="flex gap-4 gap-x-1 flex-col w-full px-4 h-full max-h-[60vh] py-5 pt-2 mt-2 overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
          <PasswordInput
            label={"Mot de passe"}
            autoGenerate={true}
            showPasswordDefault={true}
            errorState={errorState.member_password}
            onChange={onValueChange("member_password")}
          />
          <TextInput
            label={"Confirmer le mot de passe"}
            value={formData.member_confirmpassword}
            errorState={errorState.member_confirmpassword}
            onChange={onValueChange("member_confirmpassword")}
          />
          <button
            onClick={!isLoading ? submitForm : undefined}
            className="flex px-12 py-4 text-white justify-center bg-coral hover:bg-hover active:bg-active rounded-md disabled:bg-black/20 disabled:text-black/70 hover:disabled:bg-black/20"
          >
            {!isLoading ? (
              <span className="flex text-[18px]">Modifier le mot de passe</span>
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};

export default MemberUpdatePassword;
