import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const DashboardIcon = ({
  color = "#828282",
  size = 20,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 19 / 20;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule={"evenodd"}
        clipRule={color}
        d="M0.105469 3.90361C0.105469 2.18835 1.49596 0.797852 3.21122 0.797852H5.28173C6.99699 0.797852 8.38748 2.18835 8.38748 3.90361V5.97411C8.38748 7.68937 6.99699 9.07987 5.28173 9.07987H3.21122C1.49596 9.07987 0.105469 7.68937 0.105469 5.97411V3.90361ZM3.21122 2.86836C2.63947 2.86836 2.17597 3.33185 2.17597 3.90361V5.97411C2.17597 6.54586 2.63947 7.00936 3.21122 7.00936H5.28173C5.85348 7.00936 6.31698 6.54586 6.31698 5.97411V3.90361C6.31698 3.33185 5.85348 2.86836 5.28173 2.86836H3.21122ZM10.458 3.90361C10.458 2.18835 11.8485 0.797852 13.5637 0.797852H15.6342C17.3495 0.797852 18.74 2.18835 18.74 3.90361V5.97411C18.74 7.68937 17.3495 9.07987 15.6342 9.07987H13.5637C11.8485 9.07987 10.458 7.68937 10.458 5.97411V3.90361ZM13.5637 2.86836C12.992 2.86836 12.5285 3.33185 12.5285 3.90361V5.97411C12.5285 6.54586 12.992 7.00936 13.5637 7.00936H15.6342C16.206 7.00936 16.6695 6.54586 16.6695 5.97411V3.90361C16.6695 3.33185 16.206 2.86836 15.6342 2.86836H13.5637ZM0.105469 14.2561C0.105469 12.5409 1.49596 11.1504 3.21122 11.1504H5.28173C6.99699 11.1504 8.38748 12.5409 8.38748 14.2561V16.3266C8.38748 18.0419 6.99699 19.4324 5.28173 19.4324H3.21122C1.49596 19.4324 0.105469 18.0419 0.105469 16.3266V14.2561ZM3.21122 13.2209C2.63947 13.2209 2.17597 13.6844 2.17597 14.2561V16.3266C2.17597 16.8984 2.63947 17.3619 3.21122 17.3619H5.28173C5.85348 17.3619 6.31698 16.8984 6.31698 16.3266V14.2561C6.31698 13.6844 5.85348 13.2209 5.28173 13.2209H3.21122ZM10.458 14.2561C10.458 12.5409 11.8485 11.1504 13.5637 11.1504H15.6342C17.3495 11.1504 18.74 12.5409 18.74 14.2561V16.3266C18.74 18.0419 17.3495 19.4324 15.6342 19.4324H13.5637C11.8485 19.4324 10.458 18.0419 10.458 16.3266V14.2561ZM13.5637 13.2209C12.992 13.2209 12.5285 13.6844 12.5285 14.2561V16.3266C12.5285 16.8984 12.992 17.3619 13.5637 17.3619H15.6342C16.206 17.3619 16.6695 16.8984 16.6695 16.3266V14.2561C16.6695 13.6844 16.206 13.2209 15.6342 13.2209H13.5637Z"
        fill={color}
      />
    </svg>
  );
};

export default DashboardIcon;
